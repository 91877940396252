import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrl: './confirmation-modal.component.scss'
})
export class ConfirmationModalComponent {
  @Output() onConfirm = new EventEmitter<boolean>();
  public showModal = false;
  @Input() message: string | undefined;

  openModal() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

  confirm() {
    this.onConfirm.emit(true);
    this.closeModal();
  }

  decline() {
    this.onConfirm.emit(false);
    this.closeModal();
  }
}
