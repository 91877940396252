import {
  ITableColumn,
  TableColumnType,
} from 'src/app/modules/models/table-column';

export const CANDIDATE_COLUMNS: ITableColumn[] = [
  {
    header: 'CANDIDATE NAME',
    field: 'name',
    type: TableColumnType.STRING,
  },
  {
    header: 'EMAIL',
    field: 'email',
    type: TableColumnType.STRING,
  },
  {
    header: 'LOCATION',
    field: 'location',
    type: TableColumnType.STRING,
  },
  {
    header: 'ROLE',
    field: 'title',
    type: TableColumnType.STRING,
  },
  {
    header: 'STATUS',
    field: 'status',
    type: TableColumnType.TEMPLATE,
  },
  {
    header: 'DATE ADDED',
    field: 'date',
    type: TableColumnType.STRING,
  },
];
