import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'blog-category',
  templateUrl: './blog-category.component.html',
  styleUrls: ['./blog-category.component.scss'],
})
export class BlogCategoryComponent {
  @Input() selected: string = 'All';
  @Input() category: string = '';

  @Output('select') selectCategory = new EventEmitter();

  select(category: string) {
    this.selectCategory.emit(category);
  }
}
