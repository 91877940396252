<div class="flex flex-col w-full h-full px-4 md:flex-row overflow-hidden">
    <div class="flex flex-col w-full overflow-y-scroll h-full">
      <!-- <div class="relative flex flex-col w-full h-full gap-2 bg-white border border-gray-300 rounded shadow-sm overflow-y-auto"> -->
        <!-- <div class="flex w-full bg-gray-50 p-2 gap-2">
          <span class="flex grow text-base font-semibold">
            Rankings
          </span>
          <span role="button" class="flex gap-1 items-center pr-4">
            <img src="/assets/edit-icon.svg" />
            <span class="text-sm font-semibold text-gray-400">Edit</span>
          </span>
        </div> -->
        <div class="flex w-full p-2">
          <div class="flex px-5 py-3 gap-2.5 bg-gray-50 items-center w-full">
            <img src="assets/info-icon.svg" />
            <span class="text-xs font-normal text-gray-400 grow"
              >Rank your requirements for the job to be used to
              provide you the best candidate match. Assessment
              rankings are private and you can adjust them to help
              you find the perfect match.
            </span>
            <!-- <span><img src="assets/close-icon.svg" /></span> -->
          </div>
        </div>
        <div class="flex w-full gap-4">
          <ng-container [formGroup]="jobSkillForm">

          <div class="flex w-1/2 flex-col px-2 py-4 gap-2">
            <div class="flex items-center w-full">
              <span class="text-base font-semibold flex grow">
                Soft skills
              </span>
              <!-- <span><img src="assets/magic-sparkles-blue.svg" /></span> -->
            </div>
            <span class="flex w-full text-xs font-normal text-gray-400">
              Our AI will detect specific words from the soft skills below to provide a better match.
            </span>
            <span class="flex bg-gray-200 w-full h-px"></span>
              <div class="w-full" formArrayName="softSkills">
                <div class="relative pb-2" *ngFor="let skill of softSkills.controls; let i = index" [formGroupName]="i">
                  <input
                    #softSkillsInput
                    id="skill"
                    name="skill"
                    formControlName="skill"
                    placeholder="Skill"
                    i18n-placeholder
                    ngDefaultControl
                    class="block w-full h-9 rounded-md border-0 pr-10 shadow-sm ring-1 text-osavus-content-primary font-semibold ring-inset ring-gray-200 bg-gray-50 placeholder:text-gray-400 placeholder:font-normal focus:ring-2 focus:ring-inset text-sm"/>                          
                  <div class="absolute inset-y-0 right-0 flex items-center w-8 pr-2 cursor-pointer" (click)="removeSoftSkill(i)">
                    <i class="w-6 h-6 text-gray-400 icon-close-white font-bold"></i>
                  </div>
                </div>
              </div>
            
              <div class="relative w-full" (click)="addSoftSkill()">
                <input placeholder="Add" class="block cursor-pointer w-full h-9 rounded-md border-0 pr-10 shadow-sm ring-1 text-osavus-content-primary font-semibold ring-inset ring-gray-200 bg-gray-50 placeholder:text-osavus-blue-50 placeholder:font-semibold focus:ring-2 focus:ring-inset text-sm" />
                <div class="absolute inset-y-0 right-0 flex items-center w-8 pr-2">
                  <i class="w-6 h-4 text-gray-400 icon icon-plus"></i>
                </div>
              </div>
            
                           
            <div class="flex flex-col px-2 py-4 gap-2 opacity-50 mt-6">
              <div class="flex items-center w-full">
                <span class="text-base font-semibold flex grow">
                  Education
                </span>
              </div>
              <span class="flex w-full text-xs font-normal text-gray-400">
                Set the parameters improve your matchmaking.
              </span>
              <span class="flex bg-gray-200 w-full h-px"></span>
              <div class="flex p-2">
                <span class="text-blue-500 gap-1 items-center flex">
                  <i class="icon icon-plus" style="font-size: 0.5rem"></i>
                  <span class="text-sm font-regular"> Add </span>
                </span>
              </div>
              <div class="flex items-center w-full mt-6">
                <span class="text-base font-semibold flex grow">
                  Other
                </span>
              </div>
              <span class="flex w-full text-xs font-normal text-gray-400">
                Set the parameters improve your matchmaking.
              </span>
              <span class="flex bg-gray-200 w-full h-px"></span>
              <div class="flex p-2">
                <span class="text-blue-500 gap-1 items-center flex">
                  <i class="icon icon-plus" style="font-size: 0.5rem"></i>
                  <span class="text-sm font-regular"> Add </span>
                </span>
              </div>
            </div>

          </div>
          <div class="flex w-1/2 flex-col px-2 py-4 gap-2">
            <div class="flex items-center w-full">
              <span class="text-base font-semibold flex grow">
                Hard skills
              </span>
              <!-- <span><img src="assets/magic-sparkles-blue.svg" /></span> -->
            </div>
            <span
              class="flex w-full text-xs font-normal text-gray-400"
            >
              Rank hard skills from 1 - 10. 1 being not as important and 10 being most important.
            </span>
            <span class="flex bg-gray-200 w-full h-px"></span>

            <div class="w-flex full" formArrayName="hardSkills">
              <div class="flex border-b border-gray-200 pb-2 mb-2" *ngFor="let skill of hardSkills.controls; let i = index" [formGroupName]="i">
                <div class="w-1/2 relative">
                  <input
                  #hardSkillsInput
                  id="skill"
                  name="skill"
                  formControlName="skill"
                  placeholder="Skill"
                  i18n-placeholder
                  ngDefaultControl
                  class="block w-full h-9 rounded-md border-0 pr-10 shadow-sm ring-1 text-osavus-content-primary font-semibold ring-inset ring-gray-200 bg-gray-50 placeholder:text-gray-400 placeholder:font-normal focus:ring-2 focus:ring-inset text-sm"/>
                  <div class="absolute inset-y-0 right-0 flex items-center w-8 pr-2 cursor-pointer" (click)="removeHardSkill(i)">
                    <i class="w-6 h-6 pt-1 text-gray-400 icon-close-white font-bold"></i>
                  </div>
                </div>
                <div class="flex w-1/2">
                  <div
                    *ngFor="let filled of getFullArray(skill.value.level); let idx = index"
                    class="skill-box w-1/10 h-8 mx-1"
                    [ngClass]="{
                      progress: idx < skill.value.level,
                      max: idx === skill.value.level - 1
                    }"
                    (click)="setHardSkillLevel(skill, idx + 1)"
                  >
                    <span class="text-white content-center mr-auto ml-auto text-xs" *ngIf="idx === skill.value.level - 1">{{ idx + 1 }}</span>
                  </div>
                </div>
              </div>
            </div>
          
            <div class="relative w-full" (click)="addHardSkill()">
              <input placeholder="Add" class="block cursor-pointer w-full h-9 rounded-md border-0 pr-10 shadow-sm ring-1 text-osavus-content-primary font-semibold ring-inset ring-gray-200 bg-gray-50 placeholder:text-osavus-blue-50 placeholder:font-semibold focus:ring-2 focus:ring-inset text-sm" />
              <div class="absolute inset-y-0 right-0 flex items-center w-8 pr-2">
                <i class="w-6 h-4 text-gray-400 icon icon-plus"></i>
              </div>
            </div>
            
          </div>
        </ng-container>

        </div>
      <!-- </div> -->
    </div>
</div>