<div class="relative isolate">
  <div class="overflow-hidden">
    <div class="relative px-6 pb-32 mx-auto pt-14 max-w-7xl lg:px-8">
      <img
        class="absolute inset-x-0 left-16 -top-16 -z-10 h-[64rem] w-full"
        src="assets/confetti.svg"
        aria-hidden="true"
      />
      <div
        class="max-w-2xl mx-auto gap-x-14 lg:gap-x-4 lg:mx-0 lg:flex lg:max-w-none lg:items-center"
      >
        <div class="relative w-full max-w-2xl lg:shrink-0">
          <h1
            i18n
            class="text-4xl font-bold leading-none tracking-tight text-osavus-primary-90 sm:text-5xl"
          >
            Human-centric, data-driven:
            <div
              class="text-4xl font-bold leading-none tracking-tight text-osavus-bg-red sm:text-5xl pt-2"
            >
              recruitment reimagined. ✨
            </div>
          </h1>
          <p
            i18n
            class="mt-6 text-xl leading-8 text-gray-500 sm:max-w-md lg:max-w-none"
          >
            Osavus transforms your candidate search by merging intuition with
            unparalleled data insights. Where technology meets talent, for a
            seamless and optimised journey from start to finish. 
          </p>
          <div class="flex items-center mt-10 gap-x-6">
            <app-button
              type="custom-red"
              i18n-label
              label="Secure early access"
              (onClick)="router.navigate(['/secure-early-access'])"
            ></app-button>
          </div>
        </div>
        <div
          class="justify-end hidden gap-8 md:flex mt-14 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0"
        >
          <div class="flex items-center ml-auto space-y-8 w-44 sm:ml-0">
            <div class="relative">
              <img
                src="assets/hero0.svg"
                alt=""
                class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
              />
              <div
                class="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10"
              ></div>
            </div>
          </div>
          <div
            class="flex-none mr-auto space-y-8 w-44 sm:mr-0 sm:pt-52 lg:pt-36"
          >
            <div class="relative">
              <img
                src="assets/hero1.svg"
                alt=""
                class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
              />
              <div
                class="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10"
              ></div>
            </div>
            <div class="relative">
              <img
                src="assets/hero2.svg"
                alt=""
                class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
              />
              <div
                class="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10"
              ></div>
            </div>
          </div>
          <div class="flex-none pt-32 space-y-8 w-44 sm:pt-0">
            <div class="relative">
              <img
                src="assets/hero3.svg"
                alt=""
                class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
              />
              <div
                class="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10"
              ></div>
            </div>
            <div class="relative">
              <img
                src="assets/hero4.svg"
                alt=""
                class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
              />
              <div
                class="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10"
              ></div>
            </div>
            <div class="relative">
              <img
                src="assets/hero6.svg"
                alt=""
                class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
              />
              <div
                class="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
