import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Candidate } from '../../models/candidate.model';
import { CandidatesService } from '../../candidates.service';
import { HelperService } from 'src/app/services/helper.service';
import { FileService } from 'src/app/shared/helper-services/file.service';

@Component({
  selector: 'app-candidate-meta-data',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './candidate-meta-data.component.html',
  styleUrls: ['./candidate-meta-data.component.scss'],
})
export class CandidateMetaDataComponent implements OnInit {
  @Input() data: Candidate | null;

  constructor(
    public candidatesService: CandidatesService,
    public helperService: HelperService,
    private fileService: FileService,
  ) {
    this.data = null;
  }

  ngOnInit() {}

  public openLinkedInPage(): void {
    if (this.data && this.data.contact.linkedIn) {
      window.open(this.data.contact.linkedIn, '_blank');
    }
  }

  public openGitHubPage(): void {
    if (this.data && this.data.contact.github) {
      window.open(this.data.contact.github, '_blank');
    }
  }

  public downloadPdf(): void {
    if(this.data){
      this.candidatesService.getCandidateFile(this.data.orgCandidateId).subscribe((response: Blob) => {
        this.fileService.downloadPdf(response, 'CV_' + this.data?.orgCandidateId);
      });
    }
  }

  public get name(): string {
    return this.data?.fullName || '';
  }
  
  public get position(): string {
    return this.data?.activeJobTitle || '';
  }
  
  public get company(): string {
    if (!this.data?.jobs || this.data.jobs.length === 0) {
      return '';
    }
  
    const firstJob = this.data.jobs[0];
    return firstJob.company || '';
  }
  
  public get status(): string {
    return this.data?.status || '';
  }
  
  public get skils(): string[] {
    return this.data?.skills?.slice(0, 4).map(skill => skill.skill) || [];
  }
  
  public get restSkillsNo(): number {
    if (!this.data?.skills) {
      return 0;
    }
  
    const rest = this.data.skills.length - 5;
    return rest > 0 ? rest : 0;
  }
}
