<app-label class="text-sm" [title]="label"></app-label>
<ng-select
  [id]="id"
  [formControlName]="formControlName"
  [placeholder]="placeholder"
  [multiple]="true"
  class="h-11 rounded-lg" 
  >
  <ng-option *ngFor="let option of options" [value]="option.name" >{{option.name}}</ng-option>
</ng-select>
