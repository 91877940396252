import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IJobData, IJobSkill, JobsService } from '../../services/jobs.service';

@Component({
  selector: 'job-preview',
  templateUrl: './job-preview.component.html',
  styleUrls: ['./job-preview.component.scss'],
})
export class JobPreviewComponent implements OnInit {
  @Input() jobData!: IJobData;
  @Output('onCLose') submitted = new EventEmitter();

  jobSkills!: IJobSkill[];

  constructor(private jobsService: JobsService) {}

  ngOnInit(): void {
    this.getJobSkillsWithId(this.jobData.id);
  }

  onSubmit() {
    this.submitted.emit();
  }

  salaryFormat(salary: string) {
    let num = +salary;
    let fixed = +(Math.abs(num) / 1000).toFixed(1);
    return Math.abs(num) > 999
      ? Math.sign(num) * fixed + 'k'
      : Math.sign(num) * Math.abs(num);
  }

  decorateForm(data: string[]) {
    let list = ' • ' + data.join('\r\n • ');
    return list;
  }

  getJobSkillsWithId(id: string): void {
    this.jobsService.getJobSkills(id).subscribe({
      next: (result) => {
        this.jobSkills = result.body;
      },
      error: (e) => {
        this.jobSkills = [];
      },
      complete: () => {},
    });
  }
}
