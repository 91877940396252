<div [ngClass]="onlyForm ? 'my-0' : 'my-24'">
  <div class="px-6 mx-auto max-w-7xl lg:px-8">
    <div
      [ngClass]="onlyForm ? 'bg-none' : 'bg-osavus-bg-light-blue py-24'"
      class="flex flex-col items-center justify-center w-full px-4 rounded-[48px]"
    >
      <div *ngIf="!onlyForm" class="flex flex-col gap-4 mb-10 text-center">
        <h2
          i18n
          class="text-4xl font-extrabold tracking-tight text-osavus-primary-90"
        >
          Join the Osavus community
        </h2>
        <p i18n class="text-xl font-normal text-gray-500">
          Join our community of forward-thinking professionals and never miss a
          beat in recruitment innovation.
        </p>
      </div>
      <form
        [formGroup]="communityForm"
        ngNativeValidate
        class="w-full sm:max-w-2xl"
      >
        <div
          class="flex flex-col items-start justify-center w-full gap-4 sm:flex-row"
        >
          <div class="w-60 sm:w-80">
            <app-input
              id="email"
              name="email"
              isIcon="assets/envelope.svg"
              label=""
              i18n-label
              formControlName="email"
              placeholder="Enter your email"
              i18n-placeholder
              ngDefaultControl
            >
            </app-input>
            <p i18n class="mt-2 text-sm text-gray-500">
              We will never share your email.
            </p>
            <p *ngIf="responseMessage" i18n class="mt-2 text-sm text-gray-500">
              {{ responseMessage }}
            </p>
          </div>
          <app-button
            type="custom-blue"
            [isLoading]="isLoading"
            i18n-label
            label="Subscribe now"
            (onClick)="onSubmit()"
            [disabled]="!communityForm.valid"
          ></app-button>
        </div>
      </form>
    </div>
  </div>
</div>
