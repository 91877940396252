import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent {
  changePassForm: FormGroup;
  isSubmittingPass: boolean = false;

  isToasting: boolean = true;
  toast_type: string = '';
  toast_message: string = '';

  constructor(
    private auth: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private routerActivate: ActivatedRoute,
  ) {
    this.changePassForm = this.formBuilder.group({
      password: ['', [
        Validators.required, 
        Validators.minLength(8), 
        this.auth.passwordValidator(),
      ]],
    });
  }

  ngOnInit(): void {
    if (this.auth.isLoggedIn()) {
      this.router.navigate(['hiring-portal']);
    }
  }

  get passwordErrors(): string | null {
    const passwordControl = this.changePassForm.get('password');
    if (passwordControl) {
      return AuthService.getPasswordValidationErrors(passwordControl);
    }
    return null;
  }

  onSubmit(): void {
    if (this.changePassForm.valid) {
      this.isSubmittingPass = true;
      let data = {
        email: this.routerActivate.snapshot.queryParams['email'],
        resetToken: this.routerActivate.snapshot.queryParams['token'].replace(
          /\s/g,
          '+',
        ),
        password: this.changePassForm.value.password,
      };
      this.auth.resetPass(data).subscribe({
        next: (result) => {
          this.isSubmittingPass = false;
          this.toast_type = 'success';
          this.toast_message = $localize`Password has changed successfully.`;
          this.isToasting = true;
          setInterval(() => {
            this.router.navigate(['/login']);
          }, 5000);
        },
        error: (e) => {
          this.isSubmittingPass = false;
          this.toast_type = 'error';
          this.toast_message = $localize`Something went wrong, try again later`;
          this.isToasting = true;
        },
        complete: () => {},
      });
    }
  }

  endMyToast(): void {
    this.isToasting = false;
    this.toast_type == 'success' ? this.router.navigate(['login']) : null;
  }
}
