<div class="relative flex justify-between mt-2 text-xs font-medium">
  <span (click)="onClear()" i18n class="text-black-500 cursor-pointer">Clear</span>
    <div i18n class="flex cursor-pointer regenerate-box text-osavus-blue-60">
      <span (click)="showOptions = true">
        Regenerate
      </span>
      <ng-container  *ngIf="isIcon">
        <img class="flex ml-1" src="assets/Ai-vector.svg"/>
      </ng-container>
      <ng-container  *ngIf="showOptions">

      <div class="absolute top-0 right-0 z-40 flex flex-col w-56 gap-1 regenerate-options">
        <div class="relative flex">
          <img src="assets/Ai-vector.svg" class="absolute top-3 left-3" alt="">
          <input
            id="regenrate"
            name="regenrate"
            autocomplete="autocomplete"
            placeholder="Ask OsavusAI to edit..."
            type="text"
            [(ngModel)]="prompt"
            class="block w-full h-8 rounded-md border-0 py-1.5 pl-8 shadow-sm ring-1 ring-inset ring-gray-300 bg-gray-50 placeholder:text-gray-500 placeholder:text-xs  placeholder:font-normal placeholder:pl-2 focus:ring-2 focus:ring-inset font-medium text-xs">
          <img src="assets/input-go.svg" (click)="regenerateSection()" class="absolute cursor-pointer regenerate-cta top-2 right-7" alt="">
          <div (click)="showOptions = false" class="absolute w-3.5 h-3.5 cursor-pointer right-2 regenerate-cta top-2.5">
            <svg fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#0883ec" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </div>
        </div>
        <div class="flex flex-col w-full gap-2 p-2 text-xs font-medium rounded-md shadow-sm ring-gray-300 bg-gray-50 ring-1 ring-inset">
          <ng-container *ngFor="let option of options | search:'title':prompt">
            <div (click)="prompt = option.title" class="flex flex-row gap-2 p-1 cursor-pointer hover:bg-gray-100">
              <img src="assets/{{option.icon}}"  alt="">
              <div class="">{{option.title}}</div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>

    </div>
</div>
