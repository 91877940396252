export const environment = {
  production: false,
  link: 'https://api.staging.osavus.com/api',
  cms: 'https://cms.staging.osavus.com',
  mailChimp:
    'https://osavus.us21.list-manage.com/subscribe/post-json?u=58d66d51167533a2d65778c7a&id=64e88b3df3&f_id=0036f0e6f0&',
  name: 'staging environment',
  googleSsoUrl:
    'https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?response_type=code&access_type=offline&client_id=26789044289-bc4u9nj2v65djlnkdbgnti91tvfqtvk4.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fwww.staging.osavus.com%2Fauth%2Fgoogle-sso-callback&state&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&include_granted_scopes=false&prompt=consent&service=lso&o2v=2&theme=glif&flowName=GeneralOAuthFlow',
  googleSsoRedirectUri:
    'https://www.staging.osavus.com/auth/google-sso-callback',
};
