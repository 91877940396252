<app-label [title]="label"></app-label>
<div class="flex flex-col w-full gap-2 mt-2">
  <input
    type="range"
    [min]="min"
    [max]="max"
    name="level"
    [value]="sliderValue"
    (change)="changed($event)"
    [disabled]="disabled"
    [id]="id"
    step="1"
    list="levels"
    [ngClass]="disabled ? 'cursor-not-allowed' : 'cursor-pointer'"
  />
  <datalist id="levels" class="flex w-full h-5 justify-between mt-1.5">
    <ng-container *ngFor="let level of levels">
      <option
        [value]="level"
        [label]="level"
        class="text-xs font-normal leading-6 text-gray text-center"
      ></option>
    </ng-container>
  </datalist>
</div>
