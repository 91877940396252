<app-loading *ngIf="isLoading" />
<div class="background">
  <div class="outer-container">
    <div class="inner-container">
      <div class="header">
        <div class="header__content-wrapper">
          <div class="header__content">
            <div class="header__content__title">{{ jobData?.jobTitle }}</div>
            <div class="header__content__description">
              <span class="header__content__description__job-link">{{
                jobData?.companyName
              }}</span>
              <ng-container *ngIf="jobData?.location">
                <span class="header__content__description__divider">|</span>
                <span class="header__content__description__label">{{
                  jobData?.location
                }}</span>
              </ng-container>
              <ng-container *ngIf="jobData?.jobType">
                <span class="header__content__description__divider">|</span>
                <span class="header__content__description__label">{{
                  jobData?.jobType
                }}</span>
              </ng-container>
              <ng-container *ngIf="jobData?.salary">
                <span class="header__content__description__divider">|</span>
                <span class="header__content__description__label">{{
                  jobData?.salary
                }}</span>
              </ng-container>
            </div>
          </div>

          <div class="header__button">
            <app-primary-button
              label="Apply now"
              (onClick)="scrollToForm()"
            ></app-primary-button>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content__section" *ngIf="jobData?.companyDescription">
          <div class="content__section__title">Company Overview</div>
          <div class="content__section__paragraph">
            {{ jobData?.companyDescription }}
          </div>
        </div>
        <div class="content__section" *ngIf="jobData?.jobSummary">
          <div class="content__section__title">Position Overview</div>
          <div class="content__section__paragraph">
            {{ jobData?.jobSummary }}
          </div>
        </div>
        <div
          class="content__section-small"
          *ngIf="jobData?.jobResponsibilities?.length"
        >
          <div class="content__section__title">Key Responsibilities</div>
          <ul class="content__section__list">
            <li
              *ngFor="let item of jobData?.jobResponsibilities"
              class="content__section__list__item"
            >
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="content__section-small" *ngIf="jobData?.skillTags?.length">
          <div class="content__section__title">Qualifying Skills</div>
          <ul class="content__section__list">
            <li
              *ngFor="let item of jobData?.skillTags"
              class="content__section__list__item content__section__list__skills-item"
            >
              {{ item }}
            </li>
          </ul>
        </div>
        <div
          class="content__section-small"
          *ngIf="jobData?.benefitsAndCompensation?.length"
        >
          <div class="content__section__title">
            Why {{ jobData?.companyName }}?
          </div>
          <ul class="content__section__list">
            <li
              *ngFor="let item of jobData?.benefitsAndCompensation"
              class="content__section__list__item"
            >
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="content__section" *ngIf="jobData?.languages">
          <div class="content__section__title">Language</div>
          <ul class="content__section__list">
            <li
              *ngFor="let item of jobData?.languages | keyvalue"
              class="content__section__list__item"
            >
              {{ item.key }}: {{ item.value }}
            </li>
          </ul>
        </div>
        <div class="form" id="applyForm">
          <ng-container *ngIf="!isFormSubmitted; else successMessage">
            <div class="form__header">
              <div class="form__header__title">Application Form</div>
              <span class="form__header__label">All fields are required.</span>
            </div>
            <div [formGroup]="formGroup" class="form__content">
              <app-input
                class="form__content__input"
                label="Email*"
                formControlName="email"
                i18n-label
                placeholder="email@example.com"
                i18n-placeholder
                ngDefaultControl
              >
              </app-input>
              <app-input
                class="form__content__input"
                label="Name*"
                formControlName="name"
                i18n-label
                placeholder="Your full name"
                i18n-placeholder
                ngDefaultControl
              >
              </app-input>
              <app-upload
                class="form__content__input"
                label="Upload your CV"
                fileTypesLabel="Drag your PDF, DOC or DOCX file here"
                accept=".doc,.docx,.pdf"
                (changed)="onFileChange($event)"
                (delete)="onFileDelete($event)"
              />
              <span>
                <span class="form__content__terms-of-use"
                  >By applying, you agree with
                </span>
                <a
                  class="form__content__terms-of-use__link"
                  href="https://www.osavus.com/privacy-policy"
                  target="_blank"
                  >Osavus Terms of Use
                </a>
              </span>
              <app-primary-button
                i18n-label
                label="Submit your application"
                (onClick)="onJobApply()"
                [disabled]="!formValid"
                [loading]="false"
              ></app-primary-button>
            </div>
          </ng-container>
          <ng-template #successMessage>
            <div class="form__success-message">
              <div class="form__success-message__inner">
                <label class="form__success-message__title">Thank you!</label>
                <label class="form__success-message__label"
                  >Your application has been submitted. Good luck!</label
                >
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="footer">
        <div class="footer__row-outer">
          <div class="footer__logo">
            <span class="footer__logo__label">POWERED BY</span>
            <img
              src="assets/full-Logo.svg"
              alt="logo_color"
              class="footer__logo__image"
              (click)="navigateHome()"
            />
          </div>
        </div>
        <div class="footer__row-outer">
          <span class="footer__copyright">
            <span class="footer__copyright__label"
              >Copyright &copy; {{ currentYear }} Osavus Ltd.</span
            >
            <a class="footer__copyright__link"> Terms of Use </a>
            <a class="footer__copyright__link"> Privacy Policy </a>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
