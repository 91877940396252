<div class="relative">
  <div class="relative flex items-center w-full">
    <div class="relative flex flex-row items-center w-full">
      <!-- <div class="flex items-center">
        <div
          class="block max-w-[200px] sm:max-w-[100px] xl:max-w-[200px] md:max-w-[150px] text-sm font-medium text-gray-900 truncate"
        >
          {{ jobSkill.skill }}
        </div>
        <div class="w-4 ml-2 centered">
          <div class="flex cursor-pointer has-tooltip">
            <img src="assets/info.svg" class="w-3 h-3" alt="" />
            <ng-container *ngIf="!!jobSkill.description">
              <div class="tooltip">{{ jobSkill.description }}</div>
            </ng-container>
          </div>
        </div>
      </div> -->
      <div class="skill-card-item">
        <div class="skill-card-item-label text-osavus-gray-800">
          <div i18n>{{ jobSkill.skill }}</div>
        </div>

        <div class="skill-card-item-value text-osavus-gray-800">
          <div i18n>{{ jobSkill.level }}</div>
        </div>
      </div>
    </div>

    <div (mouseleave)="isDropdown = false" class="flex items-center">
      <img
        (click)="isDropdown = !isDropdown"
        class="cursor-pointer"
        src="assets/dots.svg"
        alt=""
      />
      <ng-container *ngIf="isDropdown">
        <div class="absolute right-0 z-auto flex flex-col w-24 gap-1 top-4">
          <div
            class="flex flex-col w-full gap-1 p-2 text-xs font-medium rounded-md shadow-sm ring-gray-300 bg-gray-50 ring-1 ring-inset"
          >
            <div
              (click)="!isDeleting && deleteSkill()"
              i18n
              class="flex flex-row gap-2 p-1 cursor-pointer hover:bg-gray-100"
            >
              Delete
              <ng-container *ngIf="isDeleting">
                <svg
                  aria-hidden="true"
                  class="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-900"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </ng-container>
            </div>
            <div
              (click)="updateSkill()"
              i18n
              class="flex flex-row gap-2 p-1 cursor-pointer hover:bg-gray-100"
            >
              Update
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="w-full">
    <app-slider
      [disabled]="true"
      id="slider_tone"
      i18n-label
      label=""
      [value]="level"
      [min]="0"
      [max]="10"
      [levels]="skillLevels"
    >
    </app-slider>
  </div>
</div>
