<div
  class="absolute top-0 left-0 z-50 w-full h-full overflow-y-auto bg-gray-100"
>
  <div class="flex flex-col w-full">
    <div
      class="flex flex-row items-center justify-between w-full gap-2 p-4 md:p-8"
    ></div>
    <div class="relative flex w-full h-auto">
      <img
        src="assets/Image.png"
        class="absolute top-0 left-0 object-cover w-full"
        alt=""
      />
      <div
        class="flex flex-col w-full gap-4 px-2 my-32 lg:px-20 md:px-8 md:flex-row xl:p-28 2xl:p-36 md:p-8 sm:p-8"
      >
        <div
          class="flex flex-col relative w-full p-4 gap-4 bg-white border border-gray-100 rounded-md shadow-sm max-w-7xl"
        >
          <div class="flex items-center gap-4 p-4">
            <div class="flex h-full">
              <img
                class="w-20 h-20 object-fit"
                src="assets/Avatar.png"
                alt=""
              />
            </div>
            <div class="flex-col items-center justify-start flex-1 w-full">
              <div class="flex flex-col justify-start">
                <div class="text-base text-gray-500 font-semibold">
                  {{ jobData?.companyName }}
                </div>
                <div class="leading-none text-2xl font-extrabold text-gray-900">
                  {{ jobData?.jobTitle }}
                </div>
                <div class="text-base font-semibold text-gray-500">
                  {{ location }}
                </div>
              </div>
            </div>
            <app-primary-button
              *ngIf="!isFormSubmitted"
              i18n-label
              label="Apply now"
              (onClick)="scrollToForm()"
            ></app-primary-button>
          </div>

          <div class="list-box" *ngIf="jobData?.companyDescription">
            <div class="list-title">About the company</div>
            <div
              class="list-content"
              [ngClass]="{ container: !showMore, show: showMore }"
            >
              <span>
                {{ jobData?.companyDescription }}
              </span>
            </div>
            <span
              class="text-xs text-blue-500 cursor-pointer font-semibold select-none"
              (click)="toogleShowMore()"
              >{{ showMore ? "See less" : "See more" }}</span
            >
          </div>

          <div class="text-2xl font-semibold px-4 pb-6">Job Overview</div>
          <div class="pt-4 flex flex-col gap-2">
            <div class="list-box" *ngIf="jobData?.jobSummary">
              <div i18n class="list-title">Description</div>
              <div class="list-content">
                {{ jobData?.jobSummary }}
              </div>
            </div>
            <div class="list-box" *ngIf="jobData?.jobResponsibilities?.length">
              <div i18n class="list-title">Responsibilities</div>
              <ul class="list-content">
                <li
                  class="list-content-item"
                  *ngFor="let item of jobData?.jobResponsibilities"
                >
                  <span>
                    {{ item }}
                  </span>
                </li>
              </ul>
            </div>
            <div class="list-box" *ngIf="jobData?.skillTags?.length">
              <div i18n class="list-title">Qualifying Skills</div>
              <ul class="list-content">
                <li
                  class="list-content-item"
                  *ngFor="let item of jobData?.skillTags"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
            <div
              class="list-box"
              *ngIf="jobData?.benefitsAndCompensation?.length"
            >
              <div i18n class="list-title">Benefits</div>
              <ul class="list-content">
                <li
                  class="list-content-item"
                  *ngFor="let item of jobData?.benefitsAndCompensation"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
          <div class="p-4" id="cv_form" *ngIf="!isFormSubmitted; else message">
            <div class="p-4 text-2xl font-semibold gap-2">Application Form</div>
            <span class="px-4 font-regular text-sm text-gray-400"
              >*Required</span
            >
            <div [formGroup]="formGroup" class="flex flex-col gap-6 p-4 w-3/5">
              <app-input
                label="Email*"
                formControlName="email"
                i18n-label
                placeholder="email@example.com"
                i18n-placeholder
                ngDefaultControl
              >
              </app-input>
              <app-input
                label="Name*"
                formControlName="name"
                i18n-label
                placeholder="Your full name"
                i18n-placeholder
                ngDefaultControl
              >
              </app-input>
              <app-upload
                label="Upload your CV"
                fileTypesLabel="Drag your PDF, DOC or DOCX file here"
                accept=".doc,.docx,.pdf"
                (changed)="onFileChange($event)"
                (delete)="onFileDelete($event)"
              />
              <span>
                <span class="text-gray-500 text-sm"
                  >By applying, you agree with</span
                >
                <a
                  class="text-blue-500 text-sm font-semibold cursor-pointer"
                  href="https://www.osavus.com/privacy-policy"
                  target="_blank"
                >
                  Osavus Terms of Use
                </a>
              </span>
              <app-primary-button
                i18n-label
                label="Submit your application"
                (onClick)="onJobApply()"
                [disabled]="!formValid"
                [loading]="isSubmitting"
              ></app-primary-button>
            </div>
          </div>
          <ng-template #message>
            <span class="bg-blue-300 p-2 text-sm rounded"
              >Thank you! Your application has been submitted.
            </span>
          </ng-template>
          <div class="flex justify-between p-4">
            <div class="p-4">
              <span class="text-sm font-semibold text-gray-600"
                >Copyright &copy; {{ currentYear }} Osavus Ltd.</span
              >
            </div>
            <div class="p-4 flex items-center gap-2">
              <span class="text-xs font-semibold text-gray-400"
                >POWERED BY</span
              >
              <img
                src="assets/full-Logo.svg"
                alt="logo_color"
                class="cursor-pointer"
                (click)="navigateHome()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-loading *ngIf="isLoading"></app-loading>
</div>
