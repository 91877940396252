<main-box>
  <div class="flex flex-col w-full h-full gap-5 p-5 bg-gray-100">
    <div class="flex flex-col w-full gap-2 p-4">
      <div i18n class="text-2xl font-semibold text-osavus-black m-0">Jobs</div>
      <div i18n class="text-sm font-normal text-gray-500">
        Creating a job post is as simple as telling us what position you need filled.
      </div>
    </div>
    
    <ng-container *ngIf="hasInitialData; else emptyList">
      <div class="w-full h-full overflow-hidden">
        <app-table 
          [columns]="columns" 
          [endPoint]="'job'" 
          [tableConfig]="tableConfig"
          (listCountUpdated)="onListCountUpdated($event)">

          <ng-template tableCustomCell="jobTitle" let-item>
            <span class="cursor-pointer text-osavus-blue-50 hover:text-osavus-primary-70 font-semibold custom-width" (click)="redirectToJobPage(item.id)">{{ item.jobTitle }}</span>
          </ng-template>

          <ng-template tableCustomCell="jobLink" let-item >
            <div class="flex">
              <item-copy [value]="item.jobLink" [link]="item.jobLink" />
            </div>
          </ng-template>

          <ng-template tableCustomCell="createdByName" let-item>
            <item-initials-and-link 
              [initials]="item.createdByInitials"
              [value]="item.createdByName"/>
          </ng-template>

          <ng-template tableCustomCell="createdAt" let-item>
            <span class="text-gray-500 font-semibold">{{ item.createdAt | date: "MMM d y " }}</span>
          </ng-template>

          <ng-template #rowActions let-data>
            <button
              class="mr-3 actions-icon-button"
              title="Download original CV"
              (click)="downloadPdf(data.id)"
              mat-icon-button
              aria-label="PDF icon"
              [ngStyle]="{ 'background-color': '#0d1a2b' }"
            >
              <mat-icon svgIcon="file-pdf-outline"></mat-icon>
            </button>
          </ng-template>

          <ng-template #tableActions>
            <app-primary-button
              (click)="openCreateJobAdModal()"
              label="Create job"
              icon="icon-plus"
            ></app-primary-button>
          </ng-template>
        </app-table>
      </div>
    </ng-container>

    <ng-template #emptyList>
      <div *ngIf="!isLoading" class="flex flex-col items-center w-full gap-3">
        <img src="assets/create-job.svg" alt="empty_job_list" class="h-3/4" />
        <app-primary-button
          (click)="openCreateJobAdModal()"
          label="Create job"
          icon="icon-plus"
        ></app-primary-button>
      </div>
    </ng-template>
  </div>

  <app-loading *ngIf="isLoading"></app-loading>
</main-box>