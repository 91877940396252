import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateJobComponent } from './components/create-job/create-job.component';
import { JobsListComponent } from './components/jobs-list/jobs-list.component';
import { JobsComponent } from './components/jobs/jobs.component';
import { JobComponent } from './components/job/job.component';
import { JobAdPublicComponent } from './components/job-ad-public/job-ad-public.component';

const routes: Routes = [
  {
    path: '',
    component: JobsComponent,
    children: [
      { path: 'list', component: JobsListComponent },
      { path: 'create/:option', component: CreateJobComponent },
      { path: 'job/:jobId', component: JobComponent },
      { path: '', redirectTo: 'list', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class JobsRoutingModule {}
