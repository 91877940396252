<nav
  class="mx-auto flex w-full items-center justify-between p-6 lg:px-8"
  aria-label="Global"
>
  <div class="flex lg:flex-1">
    <a
      class="w-[170px] md:flex cursor-pointer"
      [routerLink]="['/']"
      (click)="scrollTo('top')"
      ><img src="assets/full-Logo.svg" alt="osavus"
    /></a>
  </div>
  <div class="flex lg:hidden">
    <button
      type="button"
      class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
      (click)="isMobileMenuVisible = true"
    >
      <span class="sr-only">Open main menu</span>
      <svg
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
        />
      </svg>
    </button>
  </div>
  <div class="hidden lg:flex lg:gap-x-12 text-osavus-primary-90">
    <a i18n class="header-nav" [routerLink]="['/']" (click)="scrollTo('top')"
      >Home</a
    >
    <a
      i18n
      class="header-nav"
      [routerLink]="['/']"
      (click)="scrollTo('features')"
      >Features</a
    >
    <a i18n class="header-nav" [routerLink]="['/']" (click)="scrollTo('faq')"
      >FAQ</a
    >
    <a
      i18n
      class="header-nav"
      [routerLink]="['./blog']"
      [routerLinkActive]="'text-osavus-bg-red'"
      >Blog</a
    >
  </div>
  <div class="hidden lg:flex lg:flex-1 lg:justify-end">
    <app-button
      type="custom-red"
      class="flex items-center"
      i18n-label
      label="Secure early access"
      (onClick)="router.navigate(['/secure-early-access'])"
    ></app-button>
  </div>
</nav>
<!-- Mobile menu, show/hide based on menu open state. -->
<div
  class="lg:hidden"
  role="dialog"
  aria-modal="true"
  id="mobile-menu"
  *ngIf="isMobileMenuVisible"
>
  <!-- Background backdrop, show/hide based on slide-over state. -->
  <div class="fixed inset-0 z-10"></div>
  <div
    class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
  >
    <div class="flex items-center justify-between">
      <a href="#" class="-m-1.5 p-1.5">
        <a class="w-[170px] md:flex cursor-pointer" href="/#top"
          ><img src="assets/full-Logo.svg" alt="osavus"
        /></a>
      </a>
      <button
        type="button"
        class="-m-2.5 rounded-md p-2.5 text-gray-700"
        (click)="isMobileMenuVisible = false"
      >
        <span class="sr-only">Close menu</span>
        <svg
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
    <div class="mt-6 flow-root">
      <div class="-my-6 divide-y divide-gray-500/10">
        <div class="space-y-2 py-6">
          <a
            i18n
            class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
            [routerLink]="['/']"
            (click)="scrollTo('top')"
            >Home</a
          >
          <a
            i18n
            class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
            [routerLink]="['/']"
            (click)="scrollTo('features')"
            >Features</a
          >
          <a
            i18n
            class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
            [routerLink]="['/']"
            (click)="scrollTo('faq')"
            >FAQ</a
          >
          <a
            i18n
            class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
            [routerLink]="['./blog']"
            [routerLinkActive]="'text-osavus-bg-red'"
            (click)="isMobileMenuVisible = false"
            >Blog</a
          >
        </div>
        <div class="py-6">
          <app-button
            type="custom-red"
            class="flex items-center"
            i18n-label
            label="Secure early access"
            (onClick)="router.navigate(['/secure-early-access'])"
            (click)="isMobileMenuVisible = false"
          ></app-button>
        </div>
      </div>
    </div>
  </div>
</div>
