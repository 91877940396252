import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { IJobCandidate, JobsService } from '../../services/jobs.service';
import { ActivatedRoute } from '@angular/router';
import { IMatColumn } from 'src/app/components/table/models/mat-column.interface';
import { ITableConfig } from 'src/app/components/table/models/table-config.model';
import { ISievePayload } from 'src/app/shared/models/sieve-payload.interface';
import { IListCount } from 'src/app/components/table/models/list-count.interface';
import { CandidatesService } from 'src/app/modules/hiring-portal/components/candidates/candidates.service';
import { FileService } from 'src/app/shared/helper-services/file.service';

@Component({
  selector: 'app-job-candidates',
  templateUrl: './job-candidates.component.html',
  styleUrl: './job-candidates.component.scss'
})
export class JobCandidatesComponent implements OnInit {

  public isLoading: boolean = true;
  public hasInitialData: boolean = true;

  public columns: IMatColumn[] = [
    {
      field: 'fullName',
      header: 'CANDIDATE NAME',
      sortable: true,
      searchKey: 'fullname',
    },
    { 
      field: 'email', 
      header: 'EMAIL', 
      sortable: true, 
      searchKey: 'email' 
    },
    {
      field: 'activeJobTitle',
      header: 'CURRENT ROLE',
      sortable: true,
      searchKey: 'activeJobTitle',
    },
    {
      field: 'location',
      header: 'LOCATION',
      sortable: true,
      searchKey: 'location',
    },
    {
      field: 'jobMatchScore',
      header: 'SCORE',
      sortable: true,
      searchKey: 'jobMatchScore',
    },
    // {
    //   field: 'activityStatus',
    //   header: 'STATUS',
    //   sortable: true,
    // },
    // {
    //   field: 'rating',
    //   header: 'RATING',
    //   sortable: true,
    // },
    { 
      field: 'createdAt', 
      header: 'DATE ADDED', 
      sortable: true 
    },
  ];


  public tableConfig: ITableConfig = {
    defaultPayload: {
      page: 1,
      pageSize: 25,
      sorts: '-createdAt',
    } as ISievePayload,
  };

  candidateId!: string;
  jobId: string = '';
  @Output() public openSidenav = new EventEmitter<string | null>();
  candidateEndpoint: string = '';
  sidenav: any;
  
  constructor(
    private jobsService: JobsService,
    private route: ActivatedRoute,
    private candidatesService: CandidatesService,
    private fileService: FileService,
  ){ }

  ngOnInit(): void {
    this.jobId = this.route.snapshot.params['jobId'];
    this.candidateEndpoint = `job/${this.jobId}/candidate`;
  }

  public onListCountUpdated(listCount: IListCount): void {
    this.hasInitialData = listCount.initialCount > 0;
    this.isLoading = false;
  }

  onItemSelect(id: string | null) {
    if (id) {
      this.candidateId = id;
      this.openSidenav.emit(id);
    }
  }

  public downloadPdf(id: string): void {
    this.candidatesService.getCandidateFile(id).subscribe((response: Blob) => {
      this.fileService.downloadPdf(response, 'CV_' + id);
    });
  }
}
