<div
  class="candidate-list-item"
  #candidateItem
  [ngClass]="{ 'candidate-list-item--selected': isSelected }"
  role="presentation"
  (click)="onCandidateClick()"
>
  <div class="candidate-list-item-bagde">
    <div
      class="candidate-initials"
      [ngClass]="{ 'candidate-initials--selected': isSelected }"
    >
      {{ item?.initials }}
    </div>
  </div>
  <div class="candidate-list-item-info">
    <div
      class="cadidate-name"
      [ngClass]="{ 'cadidate-name--selected': isSelected }"
    >
      {{ label }}
    </div>
    <div class="cadidate-position">{{ position }}</div>
  </div>
</div>
