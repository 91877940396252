export interface ITableColumn {
  header: string;
  field: string;
  type: TableColumnType;
}

export enum TableColumnType {
  STRING = 'string',
  NUMBER = 'number',
  DATE = 'date',
  TEMPLATE = 'template',
}
