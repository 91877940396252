import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  public downloadFilePdf(blob: Blob, fileName: string = ''): void {
    const url = URL.createObjectURL(blob);
    const file = document.createElement('a');
    try {
      file.href = url;
      file.download = `${fileName}.pdf`;
      file.style.display = 'none';
      document.body.appendChild(file);
      file.click();
    } finally {
      URL.revokeObjectURL(url);
      document.body.removeChild(file);
    }
  } 
}
