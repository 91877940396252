import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, switchMap, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  private _isRefreshingInProgress: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    this._isRefreshingInProgress = false;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const accessToken = this.authService.getToken();
    if (accessToken) {
      request = this.addToken(request, accessToken);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error.status === HttpStatusCode.InternalServerError &&
          this._isRefreshingInProgress
        ) {
          this._isRefreshingInProgress = false;
          this.authService.logout();
        }
        if (
          error.status === HttpStatusCode.Unauthorized &&
          accessToken &&
          !this._isRefreshingInProgress
        ) {
          this._isRefreshingInProgress = true;
          return this.handleTokenExpired(request, next);
        }
        if (error.status === HttpStatusCode.Forbidden && accessToken) {
          this.router.navigateByUrl('/unauthorized');
        }

        return throwError(() => error);
      }),
    );
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handleTokenExpired(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return this.authService.refreshAccessToken().pipe(
      switchMap(() => {
        const newAccessToken = this.authService.getToken() || '';
        return next.handle(this.addToken(request, newAccessToken));
      }),
      catchError((error: HttpErrorResponse) => {
        console.error('Error handling expired access token:', error);

        if (error.status === HttpStatusCode.InternalServerError) {
          this.authService.logout();
        }

        return throwError(() => error);
      }),
    );
  }
}

export const AuthInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};
