import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loginForm: FormGroup;
  isSubmitting: boolean = false;

  isToasting: boolean = false;
  toast_type: string = '';
  toast_message: string = '';

  loginFormSub!: Subscription;

  constructor(
    private auth: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  ngOnInit(): void {
    if (this.auth.isLoggedIn()) {
      this.router.navigate(['hiring-portal']);
    }

    this.loginFormSub = this.loginForm.valueChanges.subscribe((changes) => {
      this.isToasting = false;
    });
  }

  onSubmit(): void {
    if (this.loginForm.valid) {
      this.isSubmitting = true;
      this.auth.loginWithEmail(this.loginForm.value).subscribe({
        next: (result) => {
          this.isSubmitting = false;
          this.toast_type = 'success';
          this.toast_message = $localize`Successfully logged in.`;
          this.isToasting = true;
          this.auth.setToken(result.body.jwtToken);
          this.auth.setRefreshToken(result.body.refreshToken);

          this.router.navigate(['/hiring-portal']);
        },
        error: (error) => {
          this.isSubmitting = false;
          switch (error.status) {
            case 401:
              this.toast_message = $localize`Login Faild: Invalid Credentials`;
              break;
            default:
              this.toast_message = $localize`Something went wrong, try again later`;
              break;
          }
          this.toast_type = 'error';
          this.isToasting = true;
        },
        complete: () => {},
      });
    }
  }

  getAccessToken(): void {
    window.location.href = environment.googleSsoUrl;
  }

  endMyToast(): void {
    this.isToasting = false;
  }

  ngOnDestroy(): void {
    this.loginFormSub.unsubscribe();
  }
}
