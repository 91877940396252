import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ITableColumn } from 'src/app/modules/models/table-column';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatSort, Sort } from '@angular/material/sort';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { SelectionModel } from '@angular/cdk/collections';
import { CANDIDATE_COLUMNS } from './models/candidate-columns';
import {
  CandidatesExternalStatus,
  CandidatesService,
  ICandidateData,
  IGetCandidatePayload,
} from './candidates.service';
import { CandidatesUploadModalComponent } from './candidates-upload-modal/candidates-upload-modal.component';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { MatTooltip } from '@angular/material/tooltip';
import { FileService } from 'src/app/shared/helper-services/file.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.scss'],
})
export class CandidatesComponent implements OnInit, OnDestroy {
  @ViewChild(ConfirmationModalComponent)
  confirmationModal!: ConfirmationModalComponent;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('searchInput') searchInput!: ElementRef;

  public list: MatTableDataSource<ICandidateData> =
    new MatTableDataSource<ICandidateData>([]);
  public isInitialLoading: boolean = true;
  public isTableLoading: boolean = false;
  public selection = new SelectionModel<string>(true, []);

  private _totalRecords: number;
  private _currentSortState: Sort;
  public searchSubject = new Subject<string>();
  public searchTerm: string = '';

  private _selectedId: string | null = null;

  public showFilters: boolean = true;
  public currentPaginationState: PageEvent;
  public confirmationMessage: string | undefined;

  public hasInitialElements: boolean = false;

  private readonly BULK_ACTIONS: { label: string; value: string }[] = [
    { label: 'Bulk delete', value: 'bulkDelete' },
  ];
  private readonly ROW_ACTIONS: { label: string; value: string }[] = [
    { label: 'Delete', value: 'delete' },
    { label: 'View orginal CV', value: 'orginalCv' },
  ];
  private readonly COLUMN_DEFINITION: string[] = [
    'select-all',
    'fullName',
    'email',
    'location',
    'activeJobTitle',
    // 'externalStatus',
    'createdAt',
    'actions',
  ];

  private _defaultCandidatePayload: IGetCandidatePayload = {
    sorts: '-createdAt',
    page: 1,
    pageSize: 25,
  };

  private readonly SEARCH_DELAY = 600;
  private readonly SEARCH_FIELDS = [
    'fullname',
    'activejobtitle',
    'location',
    'email',
  ];

  constructor(
    private _candidatesService: CandidatesService,
    private _router: Router,
    private _breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private _fileService: FileService,
  ) {
    this._totalRecords = 0;

    this.currentPaginationState = {
      pageIndex: 1,
      pageSize: 25,
      length: 0,
    };
    this._currentSortState = {
      active: '-createdAt',
      direction: 'asc',
    };

    this.searchSubject
      .pipe(debounceTime(this.SEARCH_DELAY), distinctUntilChanged())
      .subscribe((term) => {
        this.handleSearch();
      });
  }

  ngOnDestroy(): void {
    this.searchSubject.unsubscribe();
  }

  ngOnInit(): void {
    this.isInitialLoading = true;
    this.getList(this._defaultCandidatePayload, () => {
      this.isInitialLoading = false;
    });
  }

  public downloadPdf(id: string): void {
    this._candidatesService.getCandidateFile(id).subscribe((response: Blob) => {
      this._fileService.downloadPdf(response, 'CV_' + id);
    });
  }

  public openPdf(id: string): void {
    this._candidatesService.getCandidateFile(id).subscribe((response: Blob) => {
      this._fileService.openPdfInNewTab(response);
    });
  }

  public redirectToPage(id: string): void {
    this._router.navigate(['hiring-portal/candidates/', id]);
  }

  public getList(payload: IGetCandidatePayload, callback?: () => void): any {
    this.isTableLoading = true;
    this._candidatesService.getCandidates(payload).subscribe({
      next: (result) => {
        this.list.data = result.body;

        try {
          this._totalRecords = parseInt(
            result.headers.get('X-Totalcount') as string,
          );
        } catch (e) {
          this._totalRecords = this.list.data.length;
        }
        if (!this.hasInitialElements) {
          this.hasInitialElements = this._totalRecords > 0;
        }

        this.isTableLoading = false;

        if (callback) {
          callback();
        }
      },
      error: (e) => {
        this.isTableLoading = false;
      },
    });
  }

  public showCandidateUploadModal() {
    const isMobile = this._breakpointObserver.isMatched('(max-width: 599px)');
    const dialogHeight = isMobile ? '90%' : '500px';
    const dialogwidth = isMobile ? '90%' : '500px';
    const dialogRef = this.dialog.open(CandidatesUploadModalComponent, {
      data: {},
      height: dialogHeight,
      width: dialogwidth,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._router.navigate(['hiring-portal/jobs/create', result]);
      }
    });
  }

  public get columns(): ITableColumn[] {
    return CANDIDATE_COLUMNS;
  }

  public get Status() {
    return CandidatesExternalStatus;
  }

  public get displayedColumns(): string[] {
    return this.COLUMN_DEFINITION;
  }

  public onSort(event: Sort): void {
    this._currentSortState = event;
    this.currentPaginationState.pageIndex = 1;

    this.getList(this.createCurrentPayload(), () => {
      this.paginator.firstPage();
    });
  }

  public onPageChange(event: PageEvent): void {
    const { pageSize, pageIndex } = event;
    this.currentPaginationState = {
      pageIndex: pageIndex + 1,
      pageSize,
      length: 0,
    };

    this.getList(this.createCurrentPayload());
  }

  public get bulkActions(): { label: string; value: string }[] {
    return this.BULK_ACTIONS;
  }

  public get rowActions(): { label: string; value: string }[] {
    return this.ROW_ACTIONS;
  }

  public openLink(url: string): void {
    window.open(url, '_blank');
  }

  public handleAction(action: { label: string; value: string }, id: any) {
    if (action.value === 'delete') {
      this.delete(id);
    } else if (action.value === 'changeStatus') {
      // this.changeJobStatus(job.id);
    }
  }

  public delete(id: string) {
    this._selectedId = id;

    this.confirmationMessage =
      'Are you sure you want to delete this candidate?';
    this.confirmationModal.openModal();
  }

  public handleDeleteResponse() {
    if (
      this._totalRecords === this.selection.selected.length ||
      (this.list.data.length === 1 && !this._selectedId)
    ) {
      this.searchTerm = '';
      this.hasInitialElements = false;
    }

    this.selection.clear();
    this._selectedId = null;
    this.getList(this.createCurrentPayload());
  }

  public onModalConfirm(result: boolean) {
    if (result) {
      const selectedIds = this.selection.selected;

      if (selectedIds.length === 1 || this._selectedId) {
        this._candidatesService
          .deleteOrgCandidate(this._selectedId ?? selectedIds[0])
          .subscribe({
            next: () => this.handleDeleteResponse(),
            error: (e) =>
              console.error('Delete organization candidate failed', e),
          });
      } else if (selectedIds.length > 1) {
        this._candidatesService
          .batchDeleteOrgCandidates(selectedIds)
          .subscribe({
            next: () => this.handleDeleteResponse(),
            error: (e) =>
              console.error('Batch delete organization candidates failed', e),
          });
      }
    } else {
      this._selectedId = null;
    }
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.list.data.length;
    return numSelected === numRows;
  }

  public masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.list.data.forEach((row) => this.selection.select(row.id));
  }

  public chooseOption(option: string) {
    // this.router.navigate(['hiring-portal/jobs/create', option]);
  }

  public onBulkAction(action: { label: string; value: string }) {
    if (action.value === 'bulkDelete') {
      this.confirmationMessage =
        'Are you sure you want to delete these candidates?';
      this.confirmationModal.openModal();
    }
  }

  public get totalRecords(): number {
    return this._totalRecords;
  }

  public createSorts(event: Sort): string | undefined {
    if (event.direction) {
      const activeSort = event.active;
      const sorts =
        event.direction === 'asc' ? `${activeSort}` : `-${activeSort}`;
      return sorts;
    }
    return undefined;
  }

  public onEmailCopy(tooltip: MatTooltip): void {
    tooltip.show();

    setTimeout(() => {
      tooltip.hide();
    }, 1500);
  }

  public showTable(): boolean {
    return this.list.data.length > 0 || this.hasInitialElements;
  }

  public onSearch() {
    this.searchSubject.next(this.searchTerm);
  }

  private getSearchFields(): string {
    return this.SEARCH_FIELDS.join('|');
  }

  private handleSearch(): void {
    this.currentPaginationState.pageIndex = 1;

    this.getList(this.createCurrentPayload(), () => {
      this.focusSearchInput();
      this.paginator.pageIndex = 0;
    });
  }

  private createCurrentPayload(): IGetCandidatePayload {
    return {
      sorts: this._currentSortState.active
        ? this.createSorts(this._currentSortState)
        : undefined,
      page: this.currentPaginationState.pageIndex,
      pageSize: this.currentPaginationState.pageSize,
      ...(this.searchTerm.length > 0
        ? { filters: `(${this.getSearchFields()})@=*${this.searchTerm}` }
        : undefined),
    } as IGetCandidatePayload;
  }

  private focusSearchInput(): void {
    if (this.searchInput && !this.isTableLoading) {
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      }, 0);
    }
  }
}
