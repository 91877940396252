<main-box>
  <div
    class="flex flex-col w-full h-full gap-5 p-4"
    [ngClass]="showTable() ? 'bg-gray-100' : 'bg-white'"
  >
    <div class="p-4">
      <p class="text-2xl font-semibold text-osavus-black m-0">Candidates</p>
    </div>
    <ng-container *ngIf="showTable(); else emptyList">
      <div
        class="flex flex-col items-start flex-auto w-full overflow-hidden rounded-md drop-shadow-sm border"
      >
        <div class="flex items-center justify-between w-full p-5 bg-white">
          <div class="flex">
            <label for="search" class="sr-only">Search</label>
            <div class="relative">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <i
                  class="w-5 h-5 text-gray-400 icon-search flex items-center"
                ></i>
              </div>
              <input
                #searchInput
                class="search-input"
                name="search"
                type="search"
                placeholder="Search"
                [disabled]="isTableLoading"
                [(ngModel)]="searchTerm"
                (ngModelChange)="onSearch()"
              />
            </div>
          </div>

          <div class="flex gap-4">
            <!-- <app-button
              i18n-label
              label="Export"
              type="passive"
              [isIcon]="false"
            >
              <img src="assets/world.svg" class="w-4" alt="check_mark" />
            </app-button> -->
            <app-dropdown-button
              label="Bulk Actions"
              [items]="bulkActions"
              (onItemClick)="onBulkAction($event)"
            ></app-dropdown-button>
            <app-primary-button
              (click)="showCandidateUploadModal()"
              label="New candidate"
              icon="icon-plus"
            ></app-primary-button>
            <!-- <app-dropdown-button
              label="Filter"
              [items]="[]"
              (click)="showFilters = !showFilters"
              chevronPosition="right"
              icon="icon-filter text-sm"
            ></app-dropdown-button> -->
          </div>
        </div>

        <div class="flex gap-4 px-5 pb-5 w-full" *ngIf="!showFilters">
          <input
            name="search"
            class="filter-input"
            placeholder="Filter option"
            type="search"
          />

          <input
            name="search"
            class="filter-input"
            placeholder="Filter option"
            type="search"
          />

          <input
            name="search"
            class="filter-input"
            placeholder="Filter option"
            type="search"
          />

          <input
            name="search"
            class="filter-input"
            placeholder="Filter option"
            type="search"
          />

          <input
            name="search"
            class="filter-input"
            placeholder="Filter option"
            type="search"
          />
        </div>

        <div class="table-responsive table-container">
          <table
            mat-table
            [dataSource]="list"
            matSort
            matSortDirection="asc"
            (matSortChange)="onSort($event)"
            class="mat-elevation-z8"
          >
            <ng-container matColumnDef="select-all">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let data">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(data.id) : null"
                  [checked]="selection.isSelected(data.id)"
                >
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="fullName">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                [disableClear]="true"
              >
                CANDIDATE NAME
              </th>
              <td mat-cell *matCellDef="let data">
                <span
                  class="flex gap-2 text-sm items-left font-semibold items-center"
                >
                  <span class="rounded-full p-2 bg-gray-100">{{
                    data.initials
                  }}</span>
                  <span
                    (click)="redirectToPage(data.id)"
                    class="cursor-pointer text-osavus-blue-50 hover:text-osavus-primary-70"
                    >{{ data.fullName }}</span
                  >
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="email">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                [disableClear]="true"
              >
                EMAIL
              </th>
              <td
                mat-cell
                *matCellDef="let data"
                class="text-right font-semibold"
              >
                <div class="flex email-td">
                  <span class="font-semibold">{{ data.email }}</span>
                  <img
                    class="pl-1 icon-copy"
                    src="assets/copy-outline.svg"
                    title="Copy email"
                    [cdkCopyToClipboard]="data.email"
                    (cdkCopyToClipboardCopied)="onEmailCopy(tooltip)"
                  />
                  <span
                    class="ml-2"
                    #tooltip="matTooltip"
                    matTooltip="Copied!"
                    matTooltipPosition="right"
                    matTooltipClass="mat-tooltip-success"
                  ></span>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="location">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                [disableClear]="true"
              >
                LOCATION
              </th>
              <td
                mat-cell
                *matCellDef="let data"
                class="text-right font-semibold"
              >
                <span class="text-gray-500 font-semibold">{{
                  data.location
                }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="activeJobTitle">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                [disableClear]="true"
              >
                CURRENT ROLE
              </th>
              <td mat-cell *matCellDef="let data">
                <!-- <img src="assets/Avatar.svg" alt="created by" /> -->
                <span class="text-gray-500 font-semibold">{{
                  data.activeJobTitle
                }}</span>
              </td>
            </ng-container>

            <!-- <ng-container matColumnDef="externalStatus">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                [disableClear]="true"
              >
                STATUS
              </th>
              <td mat-cell *matCellDef="let data">
                <span
                  class="text-osavus-gray-800 text-xs font-semibold me-2 px-2.5 py-0.5 rounded"
                  [ngClass]="{
                    'bg-lime-200 text-green-800':
                      Status.ACTIVE === data.externalStatus,
                    'bg-gray-200': Status.INACTIVE === data.externalStatus,
                    'bg-amber-200 text-yellow-700':
                      Status.NEEDS_UPDATE === data.externalStatus,
                    'bg-red-500 text-red-900':
                      Status.ERROR === data.externalStatus,
                    'bg-gray-100 text-slate-400':
                      Status.ARCHIVED === data.externalStatus
                  }"
                  >{{ data.externalStatus }}</span
                >
              </td>
            </ng-container> -->

            <ng-container matColumnDef="createdAt">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                [disableClear]="true"
              >
                DATE ADDED
              </th>
              <td mat-cell *matCellDef="let data">
                <span class="text-gray-500 font-semibold">{{
                  data.createdAt | date: "MMM d y "
                }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>ACTIONS</th>
              <td mat-cell *matCellDef="let data" class="overflow-cell">
                <span
                  class="flex w-full justify-center items-center overflow-visible"
                >
                  <button
                    class="mr-3 actions-icon-button"
                    title="Download orginal CV"
                    (click)="downloadPdf(data.id)"
                    mat-icon-button
                    aria-label="PDF icon"
                    [ngStyle]="{
                      'background-color': '#0d1a2b'
                    }"
                  >
                    <mat-icon svgIcon="file-pdf-outline" />
                  </button>
                  <button
                    class="actions-icon-button"
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    aria-label="Example icon-button with a menu"
                    [ngStyle]="{
                      'background-color': '#f5f5f5'
                    }"
                  >
                    <mat-icon>more_horiz</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="delete(data.id)">
                      <div class="flex">
                        <!-- <i class="w-6 h-6 pr-4 text-gray-400 shrink-0 icon-trash flex items-center justify-center"></i> -->
                        <svg
                          class="w-6 h-6 pt-1 text-red-700 flex items-center justify-center"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                          />
                        </svg>
                        <span
                          class="text-red-700 font-semibold text-sm py-1 pl-1"
                          >Delete</span
                        >
                      </div>
                    </button>
                    <button mat-menu-item (click)="openPdf(data.id)">
                      <div class="flex">
                        <i
                          class="w-6 h-6 pt-1 text-gray-400 shrink-0 icon-home1 flex items-center justify-center"
                        ></i>
                        <span class="font-semibold text-sm py-1 pl-1"
                          >View orginal CV</span
                        >
                      </div>
                    </button>
                  </mat-menu>
                </span>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" [colSpan]="displayedColumns.length">
                No results found.
              </td>
            </tr>
          </table>
          <app-loading class="mt-4" *ngIf="isTableLoading" />
        </div>
        <mat-paginator
          [disabled]="isTableLoading"
          [length]="totalRecords"
          [pageSize]="25"
          [pageSizeOptions]="[5, 10, 25, 100]"
          (page)="onPageChange($event)"
        >
        </mat-paginator>
      </div>
    </ng-container>

    <ng-template #emptyList>
      <ng-container *ngIf="!isInitialLoading">
        <div class="flex flex-col items-center justify-center w-full h-full">
          <!-- <div class="grid sm:grid-cols-3 gap-4 w-full">
              <div class="text-center p-8 rounded-lg bg-white cursor-pointer" (click)="chooseOption('generate')">
                <div class="flex justify-center items-center mb-2 m-0">
                  <i class="w-6 h-6 text-gray-400 shrink-0 icon-magic-bar m-2 text-xl"></i>
                </div>
                <p class="font-semibold m-0">AI Generate</p>
                <span class="text-sm text-gray-500 m-0">Add candidates manually and with smart integration.</span>
              </div>
              <div class="text-center p-8 rounded-lg bg-white cursor-pointer" (click)="chooseOption('upload')">
                <div class="flex justify-center items-center mb-2 m-0">
                  <i class="w-6 h-6 text-gray-400 shrink-0 icon-upload m-2 text-xl"></i>
                </div>
                <p class="font-semibold m-0">Upload CV/Resume</p>
                <span class="text-sm text-gray-500 m-0">Add candidates from existing pdfs or docs files.</span>
              </div>
              <div class="text-center p-8 rounded-lg bg-white cursor-pointer">
                <div class="flex justify-center items-center mb-2 m-0">
                  <i class="w-6 h-6 text-gray-400 shrink-0 icon-file-chart-bar m-2 text-xl"></i>
                </div>
                <p class="font-semibold m-0">Bulk Import JSON or CSV</p>
                <span class="text-sm text-gray-500 m-0">Add candidates for existing CVS, XLS, JSON files.</span>
              </div>
            </div>  -->
          <!-- <img
            src="assets/candidates.svg"
            alt="empty_candidate_list"
            class="h-2/4"
          /> -->
          <img
            src="assets/woman_collects_stars_in_the_sky.svg"
            class="h-2/4"
            alt="add_candidate_image"
          />
          <p class="my-8 add-candidates-text">Add your first candidate</p>
          <app-primary-button
            (click)="showCandidateUploadModal()"
            label="Add new candidate"
            icon="icon-plus"
          ></app-primary-button>
        </div>
      </ng-container>
    </ng-template>
  </div>
  <app-loading *ngIf="isInitialLoading"></app-loading>
</main-box>

<app-confirmation-modal
  [message]="confirmationMessage"
  (onConfirm)="onModalConfirm($event)"
></app-confirmation-modal>
