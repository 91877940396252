<div class="flex flex-col p-10 mx-auto max-w-4xl" *ngIf="blog">
  <div class="flex flex-col gap-4">
    <div class="text-5xl font-medium leading-tight text-osavus-primary-90">
      {{blog.title}}
    </div>
    <div class="flex items-center h-20 gap-8 gap">
      <img [src]="blog.authorPhoto" class="object-cover w-12 h-12 rounded-full" alt="">
      <div class="flex flex-col">
        <div i18n class="text-sm font-normal text-gray-400">
          Author
        </div>
        <div class="text-base font-normal text-osavus-primary-90">
          {{blog.authorName}}
        </div>
      </div>
      <div class="flex flex-col">
        <div i18n class="text-sm font-normal text-gray-400">
          Published
        </div>
        <div class="text-base font-normal text-osavus-primary-90">
          {{blog.publishDate}}
        </div>
      </div>


    </div>
    <div class="relative flex mytest">
      <div *ngIf="blog.category" class="absolute px-4 py-2 text-sm rounded right-3 top-3 bg-osavus-bg-light-red text-osavus-bg-red">{{blog.category}}</div>
      <div class="flex w-full h-[268px] md:h-[468px]">
        <img [src]="blog.cover" class="object-cover w-full h-full rounded" alt="">
      </div>
    </div>
    <div appMarked class="block text-gray-500 contentText" [innerHTML]="blog.post" ></div>
  </div>
</div>
