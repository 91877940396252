<div class="py-24 bg-white" id="features">
  <div class="px-6 mx-auto max-w-7xl lg:px-8">
    <div class="flex justify-center w-full text-center">
      <div class="flex flex-col items-center">
        <h2 i18n  class="text-5xl font-extrabold tracking-tight text-osavus-primary-90">
          Accelerate <span class="underline text-osavus-bg-red">your success</span> with smarter recruitment.
        </h2>
        <p i18n  class="mt-6 text-xl font-normal leading-8 text-gray-500">Experience Osavus, where cutting-edge technology meets expert-driven recruitment solutions.</p>
      </div>
    </div>
    <div class="flex justify-center max-w-2xl mx-auto mt-10 sm:mt-20 lg:mt-24 lg:max-w-none">
      <div class="grid max-w-xl grid-cols-1 gap-x-16 gap-y-12 lg:max-w-none lg:grid-cols-3">
        <div class="flex flex-col">
          <div class="flex flex-col items-center">
            <div class="feature-image">
              <img src="assets/feature1.svg" />
            </div>
            <span i18n class="feature-title">Verified Candidates</span>
          </div>
          <div class="feature-content">
            <p i18n>Each candidate undergoes our robust verification process, ensuring you can move forward with confidence.</p>
          </div>
        </div>

        <div class="flex flex-col">
          <div class="flex flex-col items-center">
            <div class="feature-image">
              <img src="assets/feature2.svg" />
            </div>
            <span i18n class="feature-title">Pre-Screened</span>
          </div>
          <div class="feature-content">
            <p i18n>Our advanced pre-screening tools are designed to pinpoint the perfect match, whether you're looking for specific technical abilities or the right cultural fit.</p>
          </div>
        </div>

        <div class="flex flex-col">
          <div class="flex flex-col items-center">
            <div class="feature-image">
              <img src="assets/feature3.svg" />
            </div>
            <span i18n class="feature-title">Real-time Matching</span>
          </div>
          <div class="feature-content">
            <p i18n>Leverage the power of efficient and effective AI-driven candidate matching to help you find your next team member with ease.</p>
          </div>
        </div>


      </div>

    </div>
  </div>
  <div class="slide-box">
    <div class="slide-image">
      <img  src="assets/feature1.png" alt="" />
    </div>
    <div class="slide-mini-box">
      <h1 i18n  class="slide-title">Intelligently curated candidates.</h1>
      <div i18n  class="slide-content">Experience seamless hiring with Osavus: AI-driven, precision candidate matching for efficient, intelligent, and effective recruitment solutions.</div>
    </div>
  </div>
  <div class="slide-box">
    <div class="slide-mini-box">
      <h1 i18n  class="slide-title">Insights at your fingertips.</h1>
      <div i18n  class="slide-content">Empower your recruitment decisions with our AI-driven analytics. Dive into deep insights and data trends to strategically navigate the hiring landscape and make well-informed choices.</div>
    </div>
    <div class="slide-image">
      <img  src="assets/feature2.png" alt="" />
    </div>
  </div>

</div>
