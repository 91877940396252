  <div class="flex justify-center px-6 py-4 mx-auto text-center max-w-7xl lg:px-8" id="faq" style="padding-top: 5rem">
    <div class="container max-w-4xl">
      <h2 i18n class="mb-16 text-4xl font-extrabold leading-10 tracking-tight text-osavus-primary-90">Frequently asked questions</h2>
      <ng-container  *ngFor="let item of faqs">
        <dl class="mt-6 space-y-6">
          <div>
              <dt>
                <button (click)="active_faq === item.Id ? active_faq = 0 : active_faq = item.Id" type="button" class="flex items-center justify-between w-full pb-5 text-gray-900 border-b border-gray-200 text-start ">
                  <div class="flex items-baseline gap-1">
                    <img  src="assets/question-mark.svg" alt="">
                    <span class="text-lg font-medium leading-7 text-gray-900">{{item.Question}}</span>
                  </div>

                  <span class="flex items-center ml-6 h-7">
                    <img *ngIf="active_faq === item.Id" src="assets/faq-arrow-up.svg" alt="collapse FAQ section">
                    <img *ngIf="active_faq !== item.Id" src="assets/faq-arrow-down.svg" alt="collapse FAQ section">
                  </span>
                </button>
              </dt>
              <dd class="pb-3 pr-12 mt-2 border-b border-gray-200" id="faq-0" [ngClass]="active_faq === item.Id ? 'flex' : 'hidden'">
                <p class="text-base leading-7 text-gray-500 text-start">{{item.Answer}}</p>

              </dd>
          </div>
        </dl>
      </ng-container>
    </div>
  </div>

