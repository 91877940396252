import { Component } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  IsJobsActive: boolean = false;
  IsOverviewActive: boolean = true;
  IsReportsActive: boolean = false;
  IsCandidatesActive: boolean = false;
}
