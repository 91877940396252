import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';

import { MaterialModule } from 'src/app/material.module';

import { CoreUIModule } from '../../../../core-ui/core-ui.module';
import { ComponentsModule } from '../../../../components/components.module';
import { CreateJobComponent } from './components/create-job/create-job.component';
import { JobsListComponent } from './components/jobs-list/jobs-list.component';
import { JobsComponent } from './components/jobs/jobs.component';
import { JobComponent } from './components/job/job.component';
import { JobPreviewComponent } from './components/job-preview/job-preview.component';
import { JobsRoutingModule } from './jobs-routing.module';
import { CreateJobModalComponent } from './components/create-job-modal/create-job-modal.component';
import { JobAdPublicComponent } from './components/job-ad-public/job-ad-public.component';
import { JobListItemComponent } from './components/job-list-item/job-list-item.component';
import { CandidateComponent } from '../../components/candidates/candidate/candidate.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { CriteriaComponent } from './components/criteria/criteria.component';
import { JobCandidatesComponent } from './components/job-candidates/job-candidates.component';
import { TrackCandidatesComponent } from './components/track-candidates';

@NgModule({
  declarations: [
    CreateJobComponent,
    JobsListComponent,
    JobsComponent,
    JobComponent,
    JobPreviewComponent,
    CreateJobModalComponent,
    JobAdPublicComponent,
    JobListItemComponent,
    CriteriaComponent,
    JobCandidatesComponent,
    TrackCandidatesComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    JobsRoutingModule,
    CoreUIModule,
    ComponentsModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    ClipboardModule,
    MaterialModule,
    CandidateComponent,
    DragDropModule,
    NgxSkeletonLoaderModule,
    UploaderModule,
  ],
  bootstrap: [JobsComponent],
})
export class JobsModule {}
