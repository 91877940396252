<div
  class="flex flex-col items-start flex-auto w-full h-full overflow-hidden rounded-md drop-shadow-sm border"
>
  <div
    *ngIf="enableSearch || enableSelection || tableActionsTemplate"
    class="w-full p-4 bg-white"
  >
    <div
      class="flex flex-wrap items-center"
      [ngClass]="enableSearch ? 'justify-between' : 'justify-end'"
    >
      <div class="flex m-1" *ngIf="enableSearch">
        <label for="search" class="sr-only">Search</label>
        <div class="relative">
          <div
            class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
          >
            <i class="w-5 h-5 text-gray-400 icon-search flex items-center"></i>
          </div>
          <input
            #searchInput
            class="search-input"
            name="search"
            type="search"
            placeholder="Search"
            [disabled]="isLoading"
            [(ngModel)]="searchTerm"
            (ngModelChange)="onSearch()"
          />
        </div>
      </div>
      <div class="flex flex-wrap gap-4 m-1">
        <ng-container *ngIf="enableSelection">
          <app-dropdown-button
            label="Bulk Actions"
            [items]="bulkActions"
            (onItemClick)="onBulkAction($event)"
          >
          </app-dropdown-button>
        </ng-container>
        <ng-container *ngIf="tableActionsTemplate">
          <ng-template [ngTemplateOutlet]="tableActionsTemplate"></ng-template>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="relative flex flex-col w-full h-full overflow-hidden">
    <app-loading *ngIf="isLoading" />
    <div class="flex-grow overflow-auto">
      <table
        mat-table
        [dataSource]="data"
        matSort
        (matSortChange)="onSort($event)"
      >
        <!-- Select -->
        <ng-container matColumnDef="select" *ngIf="enableSelection">
          <th mat-header-cell *matHeaderCellDef class="selection-column">
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [disabled]="isLoading"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row" class="selection-column">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row.id) : null"
              [checked]="selection.isSelected(row.id)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <!-- Other Columns -->
        <ng-container
          *ngFor="let column of columns"
          [matColumnDef]="column.field"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [disabled]="!column.sortable"
          >
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container
              *ngTemplateOutlet="
                getCustomTemplate(column.field) || defaultCell;
                context: { $implicit: element, column: column }
              "
            ></ng-container>
          </td>
        </ng-container>
        <!-- Actions -->
        <ng-container matColumnDef="actions" *ngIf="enableRowActions">
          <th mat-header-cell *matHeaderCellDef class="actions-column">
            ACTIONS
          </th>
          <td
            mat-cell
            *matCellDef="let data"
            class="overflow-cell actions-column"
          >
            <span
              class="flex w-full justify-center items-center overflow-visible"
            >
              <ng-container *ngIf="rowActionsTemplate">
                <ng-template
                  [ngTemplateOutlet]="rowActionsTemplate"
                  [ngTemplateOutletContext]="{ $implicit: data }"
                >
                </ng-template>
              </ng-container>
              <button
                class="actions-icon-button"
                mat-icon-button
                [matMenuTriggerFor]="menu"
                aria-label="Icon-button with a menu"
                [ngStyle]="{
                  'background-color': '#f5f5f5'
                }"
              >
                <mat-icon>more_horiz</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <ng-container *ngIf="rowActionsMenuTemplate">
                  <ng-template
                    [ngTemplateOutlet]="rowActionsMenuTemplate"
                    [ngTemplateOutletContext]="{ $implicit: data }"
                  ></ng-template>
                </ng-container>
                <button mat-menu-item (click)="onDelete(data.id)">
                  <div class="flex">
                    <svg
                      class="w-6 h-6 pt-1 text-red-700 flex items-center justify-center"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                      />
                    </svg>
                    <span class="text-red-700 font-semibold text-sm py-1 pl-1"
                      >Delete</span
                    >
                  </div>
                </button>
              </mat-menu>
            </span>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [colSpan]="displayedColumns.length">
            <span class="text-osavus-black font-bold">No results found. </span>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <mat-paginator
    [length]="listCount.filteredCount"
    [pageSize]="defaultPayload.pageSize"
    [pageSizeOptions]="pageSizeOptions"
    [disabled]="isLoading"
    (page)="onPageChange()"
  >
  </mat-paginator>
</div>

<ng-template #defaultCell let-element let-column="column">
  <span class="text-gray-500 font-semibold">
    {{ element[column.field] }}
  </span>
</ng-template>

<app-confirmation-modal
  [message]="confirmationMessage"
  (onConfirm)="onModalConfirm($event)"
></app-confirmation-modal>
