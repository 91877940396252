import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreUIModule } from 'src/app/core-ui/core-ui.module';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { GoogleSsoComponent } from './google-sso/google-sso.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SignupComponent } from './signup/signup.component';
import { AuthRoutingModule } from './auth-routing.module';

const COMPONENTS = [
  ChangePasswordComponent,
  ForgetPasswordComponent,
  GoogleSsoComponent,
  LoginComponent,
  NotFoundComponent,
  SignupComponent
];
@NgModule({
  imports: [CommonModule, ReactiveFormsModule, CoreUIModule, AuthRoutingModule],
  declarations: [COMPONENTS],
  exports: [COMPONENTS],
})
export class AuthModule {}
