import { Component, OnInit } from '@angular/core';
import { IJobCandidate, JobCandidateStatus, JobsService } from '../../services/jobs.service';
import { firstValueFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { CandidatesService } from 'src/app/modules/hiring-portal/components/candidates/candidates.service';

@Component({
  selector: 'app-job-candidates',
  templateUrl: './job-candidates.component.html',
  styleUrl: './job-candidates.component.scss'
})
export class JobCandidatesComponent implements OnInit {

  candidateId!: string;
  private _jobCandidates: IJobCandidate[];
  jobId: string = '';

  private _appliedCandidates: IJobCandidate[] = [];
  private _goodMatchCandidates: IJobCandidate[] = [];
  private _forReviewCandidates: IJobCandidate[] = [];
  private _preScreeningCandidates: IJobCandidate[] = [];
  private _hiredCandidates: IJobCandidate[] = [];
  private _unqualifiedCandidates: IJobCandidate[] = [];
  
  constructor(
    private jobsService: JobsService,
    private route: ActivatedRoute,
    private candidatesService: CandidatesService,
  ){
    this._jobCandidates = [];

  }

  ngOnInit(): void {
    this.jobId = this.route.snapshot.params['jobId'];
    this.getCandidateData();
  }

  onItemSelect(id: string | null) {
    if (id) {
      this.candidateId = id;
      // this.sidenav.open();
    }
  }

  async getCandidateData() {
    const ids = await this.getJobCandidates();
    await this.getJobCandidateDetails(ids);
  }

  async getJobCandidates() {
    const candidatesObservable = this.jobsService.getCandidates(this.jobId);
    const response = await firstValueFrom(candidatesObservable);
    this._jobCandidates = response;
    this._appliedCandidates = response.filter(
      (item) => item.externalStatus === JobCandidateStatus.APPLIED,
    );
    this._goodMatchCandidates = response.filter(
      (item) => item.externalStatus === JobCandidateStatus.GOOD_MATCH,
    );
    this._forReviewCandidates = response.filter(
      (item) => item.externalStatus === JobCandidateStatus.FOR_REVIEW,
    );
    this._preScreeningCandidates = response.filter(
      (item) => item.externalStatus === JobCandidateStatus.PRE_SCREENING,
    );
    this._hiredCandidates = response.filter(
      (item) => item.externalStatus === JobCandidateStatus.HIRED,
    );
    this._unqualifiedCandidates = response.filter(
      (item) => item.externalStatus === JobCandidateStatus.UNQUALIFIED,
    );
    return response.map((item) => item.orgCandidateId);
  }

  async getJobCandidateDetails(ids: string[]) {
    const profiles = [];

    for (let id of ids) {
      const candidateRequest = this.candidatesService.getCandidateById(id);
      const candidateProfile = await firstValueFrom(candidateRequest);
      profiles.push(candidateProfile);
    }
  }

  public get appliedCadidates(): IJobCandidate[] {
    return this._appliedCandidates;
  }

  public get forReviewCadidates(): IJobCandidate[] {
    return this._forReviewCandidates;
  }

  public get goodMatchCadidates(): IJobCandidate[] {
    return this._goodMatchCandidates;
  }

  public get hiredCadidates(): IJobCandidate[] {
    return this._hiredCandidates;
  }

  public get prescreeningCadidates(): IJobCandidate[] {
    return this._preScreeningCandidates;
  }

  public get unqualifiedCadidates(): IJobCandidate[] {
    return this._unqualifiedCandidates;
  }
}
