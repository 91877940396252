import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent {
  @ViewChild('file') fileInput!: ElementRef;
  @Input() id: string = '';
  @Input() label: string = 'File upload';
  @Input() fileTypesLabel: string = 'Drag your PDF, DOC or DOCX file here';
  @Input() accept: string = '';
  @Output('changed') changedFile = new EventEmitter();
  @Output('delete') onDeleteFile = new EventEmitter();

  fileName: string = '';
  isGenerateToasting: boolean = true;
  toast_type: string = '';
  toast_message: string = '';
  uploadProgress: number = 0;
  isUploading: boolean = false;

  constructor(private http: HttpClient) {}

  onFileUpload(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const allowedFileTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ];

      if (allowedFileTypes.includes(file.type)) {
        if (file.size < 10485760) {
          // 10MB in bytes
          this.fileInput.nativeElement.value = '';
          this.fileName = file.name;
          this.uploadFile(file);
        } else {
          this.toast_message = $localize`File is more than 10MB`;
          this.isGenerateToasting = true;
          this.toast_type = 'error';
          this.fileInput.nativeElement.value = '';
        }
      } else {
        this.toast_message = $localize`Invalid file type. Only PDF, DOC, and DOCX files are allowed.`;
        this.isGenerateToasting = true;
        this.toast_type = 'error';
        this.fileInput.nativeElement.value = '';
      }
    }
  }

  uploadFile(file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    const uploadReq = new HttpRequest(
      'POST',
      'YOUR_UPLOAD_ENDPOINT',
      formData,
      {
        reportProgress: true,
        headers: headers,
      },
    );

    this.isUploading = true;
    this.http.request(uploadReq).subscribe({
      next: (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.uploadProgress = Math.round(
            100 * (event.loaded / (event.total ?? 1)),
          );
          if (this.uploadProgress === 100) {
            this.toast_message = $localize`File is uploaded successfully`;
            this.isGenerateToasting = true;
            this.toast_type = 'success';
            this.changedFile.emit(file);
          }
        }
      },
    });
  }

  deleteFile(): void {
    this.fileName = '';
    this.fileInput.nativeElement.value = '';
    this.onDeleteFile.emit();
  }

  endMyToast(): void {
    this.isGenerateToasting = false;
  }
}
