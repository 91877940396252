import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IBlog } from 'src/app/services/blog.service';

@Component({
  selector: 'osavus-blog',
  templateUrl: './osavus-blog.component.html',
  styleUrls: ['./osavus-blog.component.scss'],
})
export class OsavusBlogComponent {
  @Input() blog!: IBlog;
  @Output('navigate') navigateToBlog = new EventEmitter<any>();
  monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  ngOnInit(): void {
    const d = new Date(this.blog.publishDate);
    this.blog.publishDate =
      this.monthNames[d.getMonth()] + ' ' + d.getFullYear();
  }

  navigate(blogSlug: string) {
    this.navigateToBlog.emit(blogSlug);
  }
}
