<div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"></div>

<div class="fixed inset-0 z-10 w-screen overflow-y-auto">
  <div
    class="flex items-end justify-center min-h-full p-6 text-center sm:items-center sm:p-0"
  >
    <div class="w-96">
      <osavus-box>
        <div class="flex flex-col justify-center gap-6">
          <div class="flex justify-between w-full">
            <span class="text-sm text-osavus-blue-80">{{ title }}</span>
            <span (click)="close()" class="cursor-pointer hover:bg-slate-100">
              <svg
                class="w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </span>
          </div>
          <ng-content></ng-content>
        </div>
      </osavus-box>
    </div>
  </div>
</div>
