import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authGuard } from './guards/auth.guard';
import { LandingComponent } from './components/landing/landing.component';
import { WaitListComponent } from './components/wait-list/wait-list.component';
import { BlogsComponent } from './components/blogs/blogs.component';
import { BlogComponent } from './components/blog/blog.component';
import { FaqSectionComponent } from './components/website/micro-components/faq-section/faq-section.component';
import { BaseLayoutComponent } from './components/base-layout/base-layout.component';
import { GoogleSsoComponent } from './modules/auth/google-sso/google-sso.component';
import { NotFoundComponent } from './modules/auth/not-found/not-found.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';

import { JobAdPublicComponent } from './modules/hiring-portal/modules/jobs/components/job-ad-public/job-ad-public.component';

const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      { path: '', component: LandingComponent },
      { path: 'faq', component: FaqSectionComponent },
      { path: 'secure-early-access', component: WaitListComponent },
      { path: 'blog', component: BlogsComponent },
      { path: 'blog/:blogSlug', component: BlogComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'cookie-policy', component: CookiePolicyComponent },
      {
        path: 'auth',
        component: GoogleSsoComponent,
        children: [
          {
            path: 'google-sso-callback',
            component: GoogleSsoComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'login-portal',
    loadChildren: () =>
      import('./modules/auth/auth-routing.module').then(
        (m) => m.AuthRoutingModule,
      ),
  },
  {
    path: 'hiring-portal',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/hiring-portal/hiring-portal-routing.module').then(
        (m) => m.HiringPortalRoutingModule,
      ),
  },
  { path: ':jobId', component: JobAdPublicComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
