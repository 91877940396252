<div class="candidates-upload-modal">
  <div class="candidates-upload-modal-header flex">
    <span class="font-medium text-lg grow">Create a candidate</span>
    <img
      alt="close_icon"
      src="assets/close-icon.svg"
      (click)="closeModal()"
      class="w-3 h-3 flex cursor-pointer"
    />
  </div>
  <div class="candidates-upload-modal-content">
    <div class="flex-col flex">
      <div class="flex gap-2 pb-2">
        <span class="flex text-sm font-semibold leading-6 text-gray-900"
          >Upload your CV</span
        >
        <img src="assets/info.svg" alt="info_icon" />
      </div>
      <div #dropArea class="relative">
        <div class="absolute top-6 text-center flex flex-col w-full">
          <img
            src="assets/upload-icon-outline.svg"
            alt="upload_icon_outline"
            class="w-4 h-4 flex self-center"
          />
          <div class="font-semibold text-sm">
            Drag your PDF, DOC or DOCX file here
          </div>
          <div
            class="underline text-sm text-blue-400 cursor-pointer"
            role="presentation"
            (click)="openUploadDialog()"
          >
            or browse for files
          </div>
        </div>
        <ejs-uploader
          #templateupload
          id="templatefileupload"
          [asyncSettings]="path"
          [dropArea]="dropArea"
          class="cv-drop-area"
          [allowedExtensions]="'.pdf,.doc,.docx'"
          [maxFileSize]="10485760"
          (uploading)="onUploading($event)"
          [htmlAttributes]="{ name: 'file' }"
        >
        </ejs-uploader>
      </div>
    </div>
  </div>
</div>
