<div class="flex flex-col min-h-screen" id="top">
    <header class="sticky fixed z-50 bg-white-300 top-0 bg-white w-full">
        <app-website-header></app-website-header>
    </header>
    <div class="flex-grow scroll:smooth">
        <router-outlet></router-outlet>
    </div>
    <footer class="z-50 bg-white-300 bottom-0">
        <app-website-footer></app-website-footer>
    </footer>
</div>