<div class="absolute top-0 left-0 z-50 w-full h-full overflow-y-auto bg-gray-100">
  <div class="flex flex-col w-full">
    <div class="flex flex-row items-center justify-between w-full gap-2 p-4 md:p-8">
      <div class="flex">
        <div class="flex h-full">
          <img class="w-16 h-16 object-fit" src="assets/Avatar.png" alt="" />
        </div>
        <div class="flex-col items-center justify-start flex-1 w-full py-1 my-1 pl-4">
          <div class="flex items-baseline gap-2">
            <div
              class="block max-w-[100px] sm:max-w-[200px] lg:max-w-[300px] text-sm md:text-2xl font-medium text-gray-900 truncate ...">
              {{ jobData.jobTitle }}
            </div>
            <div
              class="w-12 h-4 gap-1 px-1 py-2 text-[8px] sm:text-xs bg-gray-100 border border-gray-300 rounded-full md:w-16 md:h-6 centered">
              <div class="w-2 h-2 bg-gray-500 rounded-full md:w-3 md:h-3"></div>
              <div i18n>{{ jobData.externalStatus }}</div>
            </div>
          </div>
          <div>
            <a class="text-sm block max-w-[150px] sm:max-w-[250px] lg:max-w-[350px] text-gray-400 font-normal truncate ..."
              href="">{{ jobData.companyName }}</a>
          </div>
        </div>
      </div>
      <app-outline-button (onClick)="onSubmit()" label="Close preview" icon="icon-close-white"></app-outline-button>
    </div>
    <div class="relative flex w-full h-auto">
      <img src="assets/Image.png" class="absolute top-0 left-0 object-cover w-full" alt="" />
      <div class="flex flex-col w-full gap-4 px-2 my-32 lg:px-20 md:px-8 md:flex-row">
        <div
          class="flex flex-col md:flex-[70%] relative w-full py-10 px-6 gap-4 bg-white border border-gray-100 rounded-md shadow-sm">
          <div
            class="block max-w-[300px] sm:max-w-[300px] md:max-w-[400px] lg:max-w-[500px] xl:max-w-[600px] text-sm sm:text-2xl font-medium text-gray-900 truncate ...">
            {{ jobData.jobTitle }}
          </div>
          <div class="flex flex-col w-full gap-4 md:flex-row">
            <div class="head-box">
              <div i18n class="head-title">Location:</div>
              <div class="head-content">
                <span class="max-w-3/4 truncate ...">
                  {{ jobData.location }}
                </span>
              </div>
            </div>
            <div class="head-box">
              <div i18n class="head-title">Type:</div>
              <div class="head-content">
                <span>
                  {{ jobData.jobType }}
                </span>
              </div>
            </div>
            <div class="head-box">
              <div i18n class="head-title">Salary:</div>
              <div class="head-content">
                <span>
                  ${{ salaryFormat(jobData.salaryMin) }} - ${{
                  salaryFormat(jobData.salaryMax)
                  }}
                </span>
              </div>
            </div>
          </div>

          <div class="list-box">
            <div i18n class="list-title">Job Description:</div>
            <div class="list-content">
              {{ jobData.jobSummary }}
            </div>
          </div>

          <div class="list-box">
            <div i18n class="list-title">Responsibilities:</div>
            <div class="list-content">
              {{ decorateForm(jobData.jobResponsibilities) }}
            </div>
          </div>

          <div class="list-box">
            <div i18n class="list-title">Benifits:</div>
            <div class="list-content">
              {{ decorateForm(jobData.benefitsAndCompensation) }}
            </div>
          </div>

          <div class="list-box">
            <div i18n class="list-title">Application process:</div>
            <div class="list-content">
              {{ decorateForm(jobData.applicationProcess) }}
            </div>
          </div>
        </div>

        <div class="flex flex-col md:flex-[30%] relative w-full gap-4">
          <div class="skills-card">
            <div class="skills-card-title">Skills</div>
            <ng-container *ngFor="let skill of jobSkills">
              <div class="skill-card-item">
                <div class="skill-card-item-label text-osavus-gray-800">
                  <div i18n>{{ skill.skill }}</div>
                </div>

                <div class="skill-card-item-value text-osavus-gray-800">
                  <div i18n>{{ skill.level }}</div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="contact-card">
            <div class="contact-card-title">Contact</div>
            <div class="flex flex-col">
              <div class="text-base font-semibold text-gray-500">Email:</div>
              <div class="text-base font-normal text-gray-500">
                {{ jobData.companyContact }}
              </div>
            </div>
            <div class="flex flex-col">
              <div class="text-base font-semibold text-gray-500">Website</div>
              <div class="text-base font-normal text-osavus-blue-50 cursor-pointer">
                {{ jobData.companyContact }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>