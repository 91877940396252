import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent {
  emailForm: FormGroup;
  isSendingEmail: boolean = false;

  isToasting: boolean = true;
  toast_type: string = '';
  toast_message: string = '';

  constructor(
    private auth: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
    this.emailForm = this.formBuilder.group({
      email: [
        '',
        [Validators.required, Validators.email, Validators.nullValidator],
      ],
    });
  }

  ngOnInit(): void {
    if (this.auth.isLoggedIn()) {
      this.router.navigate(['hiring-portal']);
    }
  }

  onSubmit(): void {
    if (this.emailForm.valid) {
      this.isSendingEmail = true;

      this.auth.forgetPass(this.emailForm.value).subscribe({
        next: (result) => {
          this.isSendingEmail = false;
          this.toast_type = 'success';
          this.toast_message = $localize`Reset password email is successfully sent.`;
          this.isToasting = true;
        },
        error: (e) => {
          this.isSendingEmail = false;
          this.toast_message = $localize`User is not found`;
          this.toast_type = 'error';
          this.isToasting = true;
        },
        complete: () => {},
      });
    }
  }

  endMyToast(): void {
    this.isToasting = false;
    this.toast_type == 'success' ? this.router.navigate(['login']) : null;
  }
}
