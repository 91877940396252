import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent {
  @Input() label: string = '';
  @Input() disabled: boolean = false;
  @Input() id: string = '';
  @Input() min: number = 1;
  @Input() max: number = 5;
  @Input() value: number = 2;
  @Input() levels: string[] = [];
  @Output('changed') selectedLevel = new EventEmitter();

  sliderValue: number = 1;

  constructor() {}

  ngOnInit(): void {
    this.sliderValue = this.value;
  }

  changed(event: any) {
    this.sliderValue = event.target.value;
    this.selectedLevel.emit(event.target.value);
  }
}
