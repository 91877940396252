import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogService, IBlog } from 'src/app/services/blog.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BlogComponent {
  blog!: IBlog;
  blogSlug: string = '';
  monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  constructor(
    private blogService: BlogService,
    private route: ActivatedRoute,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.blogSlug = this.route.snapshot.params['blogSlug'];
    this.getBlogBySlug(this.blogSlug);
  }

  getBlogBySlug(slug: string) {
    this.blogService.populateMainBlog(slug).subscribe({
      next: (result) => {
        let data = result.body.data[0].attributes;
        let d = new Date(data.PublishDate);

        this.blog = {
          id: result.body.data[0].id,
          title: data.Title,
          cover: `${environment.cms}${data.CoverImage.data.attributes.url}`,
          category: data.categories.data[0].attributes.Name ?? '',
          headLine: data.headline,
          slug: data.slug,
          post: data.Post,
          publishDate:
            this.monthNames[d.getMonth()] +
            ' ' +
            d.getDate() +
            ', ' +
            d.getFullYear(),
          authorName: data.AuthorName ?? ' ',
          authorPhoto: data.AuthorPhoto.data
            ? `${environment.cms}${data.AuthorPhoto.data.attributes.url}`
            : '',
        };
      },
      error: (e) => {
        this.router.navigate(['blogs']);
      },
      complete: () => {},
    });
  }
}
