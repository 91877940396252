import {
  ITableColumn,
  TableColumnType,
} from 'src/app/modules/models/table-column';

export const JOB_COLUMNS: ITableColumn[] = [
  {
    header: 'JOB POSITION',
    field: 'jobTitle',
    type: TableColumnType.STRING,
  },
  {
    header: 'APPLIED',
    field: 'title',
    type: TableColumnType.STRING,
  },
  {
    header: 'HEADCOUNT',
    field: 'title',
    type: TableColumnType.STRING,
  },
  {
    header: 'JOB LINK',
    field: 'title',
    type: TableColumnType.STRING,
  },
  {
    header: 'JOB STATUS',
    field: 'status',
    type: TableColumnType.TEMPLATE,
  },
  {
    header: 'CREATED BY',
    field: 'createdBy',
    type: TableColumnType.TEMPLATE,
  },
  {
    header: 'JOB CREATED',
    field: 'createdOn',
    type: TableColumnType.STRING,
  },
];
