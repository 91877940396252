
<div class="relative isolate">
  <div class="py-24 sm:py-32 lg:pb-40">
    <div class="px-6 md:container md:mx-auto lg:px-8">
      <div class=" mx-auto text-center">
        <h1 i18n class="text-4xl font-bold tracking-tight text-osavus-primary sm:text-6xl">Magical Recruiting</h1>
        <p i18n class="mt-6 text-lg leading-8 text-gray-600">Osavus transforms your candidate search into an intuitive, seamless quest for talent, delivering top-tier prospects to your company's doorstep with the wave of a wand. Where technology meets talent, let the magic begin</p>
        <div class="flex items-center justify-center mt-10 gap-x-6">
          <a href="#" class="rounded-md bg-osavus-primary px-3.5 py-2.5 text-base font-medium text-white shadow-sm hover:bg-osavus-blue-60 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">Free trial for 30 days</a>
          <a href="#" class="text-base rounded-md font-medium px-3.5 py-2.5  flex justify-center items-center gap-2 leading-6 text-osavus-primary border-osavus-primary border">Learn more <img src="assets/arrow-down.svg" width="16" height="16" alt=""> </a>
        </div>
      </div>
      <div class="flow-root mt-16 sm:mt-24">
        <div class="p-2 -m-2 rounded-xl lg:-m-4 lg:rounded-2xl lg:p-4">
          <img src="assets/hero2.png" alt="App screenshot" >
        </div>
      </div>
    </div>
  </div>
</div>
