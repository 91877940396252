<div i18n class="flex text-sm font-semibold leading-6 text-gray-900 pb-2">
  {{ label }}
  <div class="flex items-center justify-center w-5 h-5 pt-1 cursor-pointer">
    <img src="assets/info.svg" alt="" />
  </div>
</div>
<div>
  <div class="flex flex-col items-center justify-center w-full gap-1">
    <label
      for="file"
      class="relative flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg bg-gray-50"
    >
      <div class="flex flex-row items-center justify-center p-6 gap-3">
        <div class="top-6 text-center flex flex-col w-full ">
          <img
            src="assets/upload-icon-outline.svg"
            alt="upload_icon_outline"
            class="w-4 h-4 flex self-center"
          />
          <span i18n class="font-semibold text-sm">
            {{ fileTypesLabel }}
          </span>
          <span i18n class="underline text-sm text-blue-400 cursor-pointer"
            >or browse for files</span
          >
        </div>
      </div>
      <input
        #file
        id="file"
        name="file"
        type="file"
        class="absolute w-full h-full opacity-0 cursor-pointer"
        (change)="onFileUpload($event)"
        accept=".pdf, .doc, .docx"
      />
    </label>
    <ng-container *ngIf="!!fileName">
      <div class="flex flex-col w-full py-2">
        <div class="flex items-center justify-between w-full">
          <p i18n class="text-sm font-semibold m-0">{{ fileName }}</p>
          <div class="p-1 rounded-full cursor-pointer hover:bg-slate-200">
            <img src="assets/x-circle.svg" (click)="deleteFile()" alt="" />
          </div>
        </div>
        <ng-container *ngIf="isUploading">
          <div class="w-full">
            <div class="flex justify-between w-full">
              <p class="text-xs text-gray-500">Uploading</p>
              <p class="text-xs text-gray-500">{{ uploadProgress }} %</p>
            </div>
            <div class="h-1 bg-gray-200 rounded">
              <div class="h-full bg-blue-500 rounded" [style.width.%]="uploadProgress"></div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>

<app-toast
  class="fixed z-50 top-6 right-6"
  *ngIf="isGenerateToasting"
  [type]="toast_type"
  [message]="toast_message"
  (closing)="endMyToast()"
></app-toast>
