import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() isLoading: boolean = false;
  @Input() isIcon: boolean = false;
  @Input() disabled: boolean = false;
  @Input() label: string = '';
  @Input() type: string = 'active';
  @Output() onClick = new EventEmitter();

  onButtonClick() {
    this.onClick.emit();
  }
}
