<div class="inline-flex flex-col items-center justify-start w-full gap-4 pt-24">
  <div class="flex flex-col justify-center gap-4">
      <div i18n class="text-4xl font-extrabold text-center text-gray-900">Hear from our satisfied clients.</div>
      <div i18n class="text-xl font-normal text-center text-gray-500">Real feedback from those who've experienced Osavus's innovation.</div>
  </div>
  <div class="flex flex-col items-center justify-center pt-8">
      <div class="flex flex-col items-center justify-center w-8/12">
          <div class="text-2xl italic font-normal leading-10 text-center text-gray-900">"Osavus has revolutionized our hiring process. Its intuitive interface and smart matching features saved us time and improved our recruitment quality significantly. Highly recommend for any growing business!"</div>
      </div>
      <div class="inline-flex items-center self-stretch justify-center gap-2 pt-5">
          <img src="assets/Avatar.svg"/>
          <div class="flex items-center justify-center gap-2">
              <div class="text-base font-medium leading-5 text-gray-900">Jacob Turner</div>
              <div class="text-base font-medium leading-5 text-gray-900">/</div>
              <div class="text-sm font-normal leading-4 text-gray-500">HR Director &#64;TurnerTech</div>
          </div>
      </div>
  </div>
</div>
