import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { COUNTRIES } from 'src/app/shared/store/country-data-store';
import { Country } from 'src/app/shared/models/Country.model';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent {
  signupForm: FormGroup;
  verifyForm: FormGroup;

  isRegistering: boolean = false;
  isVerifying: boolean = false;
  isResending: boolean = false;
  showModal: boolean = false;
  isResendable: boolean = false;
  isSsoHandling: boolean = false;

  countries: Country[] = COUNTRIES;
  timeLeft: number = 60;
  interval: any;

  isRegisterToasting: boolean = true;
  isVerifyToasting: boolean = true;
  toast_type: string = '';
  toast_message: string = '';

  signupFormSub!: Subscription;
  verifyFormSub!: Subscription;

  constructor(
    private auth: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
    this.signupForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.nullValidator]],
      fullName: ['', [Validators.required, Validators.nullValidator]],
      country: ['', [Validators.required, Validators.nullValidator]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          this.auth.passwordValidator(),
          Validators.nullValidator,
        ],
      ],
      isAgreeToTcAndPrivacyPolicy: [
        false,
        [Validators.required, Validators.nullValidator],
      ],
      isAllowSendPromotionalEmails: [true, [Validators.nullValidator]],
    });

    this.verifyForm = this.formBuilder.group({
      verificationCode: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.nullValidator,
        ],
      ],
    });
  }

  get passwordErrors(): string | null {
    const passwordControl = this.signupForm.get('password');
    if (passwordControl) {
      return AuthService.getPasswordValidationErrors(passwordControl);
    }
    return null;
  }

  getAccessToken(): void {
    window.location.href = environment.googleSsoUrl;
  }

  ngOnInit(): void {
    if (this.auth.isLoggedIn()) {
      this.router.navigate(['hiring-portal']);
    }

    this.signupFormSub = this.signupForm.valueChanges.subscribe((changes) => {
      this.isRegisterToasting = false;
    });

    this.verifyFormSub = this.verifyForm.valueChanges.subscribe((changes) => {
      this.isVerifyToasting = false;
    });
  }

  verifyAccount(): void {
    if (this.verifyForm.valid) {
      this.isVerifying = true;
      let verifyData = {
        email: this.signupForm.value.email,
        verificationCode: this.verifyForm.value.verificationCode,
      };
      this.auth.verifyEmail(verifyData).subscribe({
        next: (result) => {
          this.auth.setToken(result.body.jwtToken);
          this.router.navigate(['/hiring-portal']);
        },
        error: (e) => {
          this.toast_message = $localize`Verify Email Faild: Code is wrong`;
          this.toast_type = 'error';
          this.isVerifyToasting = true;
          this.isVerifying = false;
        },
        complete: () => {},
      });
    }
  }

  onSubmit(): void {
    if (this.signupForm.valid) {
      this.isRegistering = true;
      this.auth.registerWithEmail(this.signupForm.value).subscribe({
        next: (result) => {
          this.showModal = true;
          this.isRegistering = false;
          this.startTimer();
        },
        error: (e) => {
          switch (e.error.osavusStatusCode) {
            case 'CustomerAlreadyExists':
              this.toast_message = $localize`Signup Faild: User Already Exist`;
              break;
            default:
              this.toast_message = $localize`Something went wrong, try again later`;
              break;
          }
          this.isRegistering = false;
          this.toast_type = 'error';
          this.isRegisterToasting = true;
        },
        complete: () => {},
      });
    }
  }

  changeEmail() {
    this.showModal = false;
    this.pauseTimer();
    this.isResendable = false;
    this.verifyForm.reset();
  }

  resendVerifyCode(): void {
    this.isResending = true;
    this.pauseTimer();
    let data = {
      email: this.signupForm.value.email,
    };
    this.auth.resendCode(data).subscribe({
      next: (result) => {
        this.isResending = false;
        this.startTimer();
      },
      error: (e) => {
        this.isResending = false;
        alert(e.message);
      },
      complete: () => {},
    });
  }

  startTimer() {
    this.isResendable = false;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.isResendable = true;
        this.pauseTimer();
      }
    }, 1000);
  }

  pauseTimer() {
    this.timeLeft = 60;
    clearInterval(this.interval);
  }

  endMyToast(): void {
    this.isRegisterToasting = this.isVerifyToasting = false;
  }

  ngOnDestroy(): void {
    this.signupFormSub.unsubscribe();
    this.verifyFormSub.unsubscribe();
  }
}
