import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-job-modal',
  templateUrl: './create-job-modal.component.html',
  styleUrl: './create-job-modal.component.scss'
})
export class CreateJobModalComponent {

  constructor(private dialogRef: MatDialogRef<CreateJobModalComponent>){}

  public closeModal(): void {
    this.dialogRef.close();
  }
  
  handleIsGeneratingEvent(isGenerating: boolean): void {
    if (isGenerating) {
      this.closeModal();
    }
  }
}
