import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IJobSkill } from 'src/app/modules/hiring-portal/modules/jobs/services/jobs.service';

@Component({
  selector: 'osavus-skill',
  templateUrl: './osavus-skill.component.html',
  styleUrls: ['./osavus-skill.component.scss'],
})
export class OsavusSkillComponent {
  @Input() jobSkill!: IJobSkill;
  @Input() isDeleting: boolean = false;

  @Output('delete') delete = new EventEmitter();
  @Output('update') update = new EventEmitter();

  skillLevels: string[] = ['Low', '•', 'Neutral', '•', 'High'];
  isDropdown: boolean = false;
  level: number = 1;

  constructor() {}

  ngOnInit(): void {
    this.level = !!this.jobSkill.level ? this.jobSkill.level : 1;
  }

  deleteSkill() {
    this.delete.emit();
  }

  updateSkill() {
    this.update.emit(this.jobSkill);
  }
}
