import {
  HttpClientJsonpModule,
  HttpClientModule,
  provideHttpClient,
  withJsonpSupport,
} from '@angular/common/http';
import * as Sentry from "@sentry/angular";
import {APP_INITIALIZER, ErrorHandler, NgModule, importProvidersFrom} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from 'ngx-cookieconsent';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthModule} from './modules/auth/auth.module';
import {HiringPortalModule} from './modules/hiring-portal/hiring-portal.module';
import {AuthInterceptorProvider} from './services/http-interceptor.service';
import {MaterialModule} from './material.module';
import {UploaderModule} from '@syncfusion/ej2-angular-inputs';
import {IconRegistryService} from './shared/helper-services/icon-registry.service';
import {Router} from "@angular/router";

const cookieConfig: NgcCookieConsentConfig = {
  theme: 'edgeless',
  position: 'bottom-right',
  type: 'opt-out',
  content: {
    message:
      'We use cookies, including third party cookies, for operational purposes, statistical analyses, to personalize your experience, provide you with targeted content tailored to your interests and to analyze the performance of our advertising campaigns.\n To find out more about the different types of cookies, as well as who sends them on our website, please visit our dedicated guide',
    deny: 'Reject all',
    allow: 'Accept all',
    link: 'to managing cookies',
    href: '/cookie-policy',
  },
};

function cookieConfigFactory(config: NgcCookieConsentConfig) {
  return () => {
    const hostName = window.location.hostname;
    if (config.cookie) {
      config.cookie.domain = hostName;
    }
  };
}

@NgModule({
  declarations: [AppComponent],
  exports: [MaterialModule, UploaderModule],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HiringPortalModule,
    AuthModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    MaterialModule,
    UploaderModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    IconRegistryService,
    importProvidersFrom(HttpClientModule, HttpClientJsonpModule),
    AuthInterceptorProvider,
    provideAnimationsAsync(),
    {
      provide: APP_INITIALIZER,
      useFactory: cookieConfigFactory,
      deps: [Sentry.TraceService, NgcCookieConsentConfig],
      multi: true,
    },
    provideHttpClient(withJsonpSupport()),
  ],
})
export class AppModule {
}
