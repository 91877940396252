import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IJobSkill } from 'src/app/modules/hiring-portal/modules/jobs/services/jobs.service';

@Component({
  selector: 'skill-form',
  templateUrl: './skill-form.component.html',
  styleUrls: ['./skill-form.component.scss'],
})
export class SkillFormComponent {
  @Input() isSubmitting: boolean = false;
  @Input() isUpdate: boolean = false;
  @Input() jobId: string = '';
  @Input() skillData?: IJobSkill;
  @Output('addSkill') add = new EventEmitter();
  @Output('updateSkill') Update = new EventEmitter();

  jobSkillForm: FormGroup;

  skillLevels: string[] = ['0', '•', '5', '•', '10'];

  constructor(private formBuilder: FormBuilder) {
    this.jobSkillForm = this.formBuilder.group({
      skill: ['', [Validators.required, Validators.nullValidator]],
      description: ['', [Validators.required, Validators.nullValidator]],
      level: [1, [Validators.required, Validators.nullValidator]],
    });
  }

  ngOnInit(): void {
    if (this.isUpdate) {
      this.patchSkillData();
    }
  }

  onSubmit() {
    let data = this.jobSkillForm.value;
    data.jobId = this.jobId;
    if (this.isUpdate) {
      data.id = this.skillData?.id;
    }
    this.isUpdate ? this.Update.emit(data) : this.add.emit(data);
  }

  levelChanged(event: any) {
    this.jobSkillForm.patchValue({ level: +event });
  }

  patchSkillData() {
    this.jobSkillForm.patchValue({
      level: this.skillData?.level ?? 1,
      skill: this.skillData?.skill,
      description: this.skillData?.description,
    });
  }

  public get submitLabel(): string {
    return this.isUpdate ? 'Update job skill' : 'Add job skill';
  }
}
