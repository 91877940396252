import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  public transform(value: any[], key: string, selected: string) {
    if (selected === 'All') return value;
    return (value || []).filter((item) => item[key] === selected);
  }
}
