<mat-accordion class="custom-accordion" [togglePosition]="'before'">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header class="applied">
        <mat-panel-title>Applied</mat-panel-title>
      </mat-expansion-panel-header>

      <ng-container *ngFor="let candidate of appliedCadidates">
        <app-job-list-item
          [item]="candidate"
          (selectItem)="onItemSelect($event)"
        ></app-job-list-item>
      </ng-container>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header class="good-match">
        <mat-panel-title>Good match</mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngFor="let candidate of goodMatchCadidates">
        <app-job-list-item
          [item]="candidate"
          (selectItem)="onItemSelect($event)"
        ></app-job-list-item>
      </ng-container>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header class="for-review">
        <mat-panel-title>For Review</mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngFor="let candidate of forReviewCadidates">
        <app-job-list-item
          [item]="candidate"
          (selectItem)="onItemSelect($event)"
        ></app-job-list-item>
      </ng-container>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header class="pre-screening">
        <mat-panel-title>Pre-Screening</mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container
        *ngFor="let candidate of prescreeningCadidates"
      >
        <app-job-list-item
          [item]="candidate"
          (selectItem)="onItemSelect($event)"
        ></app-job-list-item>
      </ng-container>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header class="hired">
        <mat-panel-title>Hired</mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngFor="let candidate of hiredCadidates">
        <app-job-list-item
          [item]="candidate"
          (selectItem)="onItemSelect($event)"
        ></app-job-list-item>
      </ng-container>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header class="unqualified">
        <mat-panel-title>Unqualified</mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container
        *ngFor="let candidate of unqualifiedCadidates"
      >
        <app-job-list-item
          [item]="candidate"
          (selectItem)="onItemSelect($event)"
        ></app-job-list-item>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>