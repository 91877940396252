import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skill-level-indicator',
  templateUrl: './skill-level-indicator.component.html',
  styleUrls: ['./skill-level-indicator.component.scss'],
})
export class SkillLevelIndicatorComponent implements OnInit {
  @Input() showLevel: boolean;
  @Input() level: number;

  private _skills: Array<string>;

  hardSkills = [
    {
      skill: 'C#, .NET (Core), Entity Framework',
      level: 8,
      description: null,
      skillType: 'Hard',
      cvId: '431f2cb6-5069-4c73-97cc-748718fb39fe',
      id: '5c9521fd-f601-4082-86ed-52cdc51f67ba',
      createdBy: '00000000-0000-0000-0000-000000000000',
      modifiedBy: '00000000-0000-0000-0000-000000000000',
      deletedBy: null,
      createdAt: '2024-05-30T12:06:35.69498+00:00',
      modifiedAt: '2024-05-30T12:06:35.694981+00:00',
      deletedAt: null,
    }
  ];

  constructor() {
    this.showLevel = false;
    this.level = 0;
    this._skills = new Array<string>(10).fill('');
  }

  ngOnInit() {}

  public get skills(): Array<string> {
    return this._skills;
  }

    getFullArray(level: number): boolean[] {
      return Array.from({ length: 10 }, (_, i) => i < level);
    }
}
