/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { registerLicense } from '@syncfusion/ej2-base';
import * as Sentry from "@sentry/angular";
import { environment } from './environments/environment.prod';
import { enableProdMode } from '@angular/core';

// Registering Syncfusion license key
registerLicense('ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Wd0RjUH9YcHxSRGhd;MzI2OTA1OEAzMjM1MmUzMDJlMzBkNnpoK3ROUU5aMFBLMlliTGd3dXUrQlJTOCtDaUliTVJDNFpENE1mdUF3PQ==');

Sentry.init({
  dsn: "https://90915c139f11105111780073f0fb905d@o4507870517329920.ingest.de.sentry.io/4507871020843088",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api\.staging\.osavus\.com/, /^https:\/\/api\.osavus\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// if (environment.production) {
//   enableProdMode();
// }

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
