<div class="candidate-page-content-left-bar-header">
  <!-- <div class="flex">
    <i class="icon icon-filter" aria-hidden="true"></i>
    <span>Filter</span>
    <i class="icon-chevron transform rotate-90" aria-hidden="true"></i>
  </div> -->
  <div class="flex grow justify-end items-center">
    <img src="assets/arrows.svg" class="h-4 pr-1">
    <!-- <app-dropdown-button
      label="Newest"
      [items]="bulkActions"
      icon="icon-filter text-sm"
      chevronPosition="right"
      [removeBorder]="true"
      [textColor]="false" 
    ></app-dropdown-button> -->
    <!-- <span>Newest</span>
    <i class="icon-chevron transform rotate-90" aria-hidden="true"></i> -->
    <div
      class="flex text-sm cursor-pointer"
      [matMenuTriggerFor]="menu"
      aria-label="Example icon-button with a menu">
      <span class="font-semibold">{{ getSortLabel() }}</span>
      <img src="assets/faq-arrow-down.svg" class="pl-2">
    </div>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="sortCandidates('-createdAt')">
        <div class="flex items-end">
          <span class="font-semibold text-sm">Newest</span>
          <img src="assets/des-icon.svg" class="h-4 ml-auto">
        </div>
      </button>
      <button mat-menu-item (click)="sortCandidates('createdAt')">
        <div class="flex items-end">
          <span class="font-semibold text-sm">Oldest</span>
          <img src="assets/asc-icon.svg" class="h-4 ml-auto">
        </div>
      </button>
      <button mat-menu-item (click)="sortCandidates('fullName')">
        <div class="flex items-end">
          <span class="font-semibold text-sm">Name (A - Z)</span>
          <img src="assets/des-icon.svg" class="h-4 ml-auto">
        </div>
      </button>
      <button mat-menu-item (click)="sortCandidates('-fullName')">
        <div class="flex items-end">
          <span class="font-semibold text-sm">Name (Z - A)</span>
          <img src="assets/asc-icon.svg" class="h-4 ml-auto">
        </div>
      </button>
    </mat-menu>
  
  </div>
</div>
<div style="height: 100%; overflow-y: auto">
  <div *ngFor="let item of candidates">
    <app-candidate-list-item
      [item]="item"
      (select)="onCandidateSelect($event)"
      [isSelected]="selectedCandidateId === item.id"
    ></app-candidate-list-item>
  </div>
  <div style="padding-bottom: 36px;">
    <cdk-virtual-scroll-viewport itemSize="64" style="height: calc(100% - 64px);">
      <app-candidate-list-item
        *cdkVirtualFor="let item of candidates"
        [item]="item"
        (select)="onCandidateSelect($event)"
        [isSelected]="selectedCandidateId === item.id"
      ></app-candidate-list-item>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
