import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HiringPortalRoutingModule } from './hiring-portal-routing.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { OverviewComponent } from './components/overview/overview.component';
import { CandidatesComponent } from './components/candidates/candidates.component';
import { MessagesComponent } from './components/messages/messages.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { HelpsComponent } from './components/helps/helps.component';
import { DocsComponent } from './components/docs/docs.component';
import { HiringHeaderComponent } from './components/hiring-header/hiring-header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { JobsModule } from './modules/jobs/jobs.module';
import { CoreUIModule } from 'src/app/core-ui/core-ui.module';
import { ComponentsModule } from 'src/app/components/components.module';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MaterialModule } from 'src/app/material.module';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { CandidatesUploadModalComponent } from './components/candidates/candidates-upload-modal/candidates-upload-modal.component';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from 'src/app/directives/directives.module';
@NgModule({
  declarations: [
    DashboardComponent,
    OverviewComponent,
    CandidatesComponent,
    MessagesComponent,
    AnalyticsComponent,
    HelpsComponent,
    DocsComponent,
    HiringHeaderComponent,
    SidebarComponent,
    CandidatesUploadModalComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    HiringPortalRoutingModule,
    JobsModule,
    CoreUIModule,
    ComponentsModule,
    ClipboardModule,
    MaterialModule,
    UploaderModule,
    FormsModule,
    DirectivesModule
  ],
})
export class HiringPortalModule {}
