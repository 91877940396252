<div class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center" *ngIf="showModal">
    <div class="bg-white p-4 rounded-lg shadow-lg">
        <h2 class="text-lg font-semibold">Confirm Action</h2>
        <p class="my-4">{{ message }}</p>
        <div class="flex justify-end">
            <button type="button"
                class="flex items-center justify-center w-full p-2 text-sm font-medium leading-6 text-left rounded-md text-gray-500 bg-gray-100 m-2"
                (click)="decline()">No
            </button>
            <button type="button"
                class="flex items-center justify-center w-full p-2 text-sm font-medium leading-6 text-left rounded-md text-white bg-red-500 hover:bg-red-700 m-2"
                (click)="confirm()">Yes
            </button>
        </div>
    </div>
</div>


