<!-- <div class="skill-level-indicator">
  <div
    *ngFor="let item of skills; let index = index"
    class="skill-level-indicator-element"
  >
    <div class="flex items-center justify-center">
      <div
        [ngClass]="{
          indicator: true,
          'bg-green-400': index < level - 1,
          active: index === level - 1,
          inactive: index > level - 1
        }"
        style="font-size: 0.5rem"
      >
        {{ showLevel && index === level - 1 ? level : "" }}
      </div>
    </div>
  </div>
</div> -->

<div class="w-full">
  <div *ngFor="let item of hardSkills" class="flex">
    <div *ngFor="let filled of getFullArray(item.level); let idx = index"
          class="skill-box w-1/10 h-8 mx-1" [ngClass]="{
          'bg-green-500': filled,
          'bg-gray-200': !filled,
          progress: idx < item.level,
          max: idx === item.level - 1
        }" >
      </div>
  </div>
</div>
