import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FilterPipe } from './filter/filter.pipe';
import { SearchPipe } from './search/search.pipe';

const PIPES = [FilterPipe, SearchPipe];

@NgModule({
  imports: [CommonModule],
  declarations: [PIPES],
  exports: [PIPES],
})
export class PipesModule {}
