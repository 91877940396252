import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IJobData, JobsService } from '../../services/jobs.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-job-ad',
  templateUrl: './job-ad-public.component.html',
  styleUrls: ['./job-ad-public.component.scss'],
})
export class JobAdPublicComponent implements OnInit {
  private _jobId: string;
  private _jobData: IJobData | undefined;
  private _formGroup: FormGroup = new FormGroup({});
  private _file: Blob | null = null;
  private _isFormSubmitted: boolean = false;
  private _token: string | null = null;

  public isLoading: boolean = true;

  constructor(
    private _jobsService: JobsService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _formBuilder: FormBuilder,
    private elementRef: ElementRef,
    private _authService: AuthService,
  ) {
    this._jobId = this._route.snapshot.params['jobId'];
  }

  ngOnInit() {
    this._token = this._authService.getToken();

    this._getJobDetails();
    this.initForm();
  }

  private _getJobDetails() {
    this.isLoading = true;

    this._jobsService.getJobById(this._jobId, this._token).subscribe({
      next: (result) => {
        if (result.body) {
          this._jobData = result.body;
        } else {
          this._router.navigate(['/404']);
        }
      },
      error: (e) => {
        this.isLoading = false;
        this._router.navigate(['/404']);
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  private initForm(): void {
    this._formGroup.addControl(
      'email',
      this._formBuilder.control('', [Validators.required, Validators.email]),
    );
    this._formGroup.addControl(
      'name',
      this._formBuilder.control('', [Validators.required]),
    );
  }

  public scrollToForm(): void {
    const cvFormElement = this.elementRef?.nativeElement as HTMLDivElement;
    cvFormElement
      ?.querySelector('#applyForm')
      ?.scrollIntoView({ behavior: 'smooth' });
  }

  public onFileChange(file: any) {
    this._file = file;
  }

  public onFileDelete(event: any) {
    this._file = null;
  }

  public onJobApply(): void {
    if (!this.formValid || !this._file) {
      return;
    }

    const data = new FormData();
    const applicantDetails: { name: string; email: string } =
      this._formGroup.value;

    data.append('CvFile', this._file);
    data.append('Name', applicantDetails.name);
    data.append('Email', applicantDetails.email);

    this._jobsService.applyJob(this._jobId, data).subscribe(
      (data) => {
        this._isFormSubmitted = true;
      },
      (error) => {
        this._isFormSubmitted = false;
      },
    );
  }

  public navigateHome(): void {
    const url = this._router.serializeUrl(this._router.createUrlTree(['/']));
    window.open(url, '_blank');
  }

  // GETTERS
  public get jobData(): IJobData | undefined {
    return this._jobData;
  }

  public get formGroup(): FormGroup {
    return this._formGroup;
  }

  public get currentYear(): number {
    return new Date().getUTCFullYear();
  }

  public get formValid(): boolean {
    return this._formGroup.valid && this._file !== null;
  }

  public get isFormSubmitted(): boolean {
    return this._isFormSubmitted;
  }
}
