import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IJobData, JobsService } from '../../services/jobs.service';

@Component({
  selector: 'app-job-ad',
  templateUrl: './job-ad-public.component.html',
  styleUrls: ['./job-ad-public.component.scss'],
})
export class JobAdPublicComponent implements OnInit {
  private _jobId: string;
  private _jobData: IJobData | undefined;
  private _formGroup: FormGroup;
  private _file: Blob | null;

  private _isSubmitting: boolean;
  private _isFormSubmitted: boolean;
  private _isLoadingData: boolean;
  private _showMore: boolean;

  constructor(
    private _jobsService: JobsService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _formBuilder: FormBuilder,
    private elementRef: ElementRef,
  ) {
    this._jobId = this._route.snapshot.params['jobId'];
    this._formGroup = new FormGroup({});
    this._file = null;

    this._isSubmitting = false;
    this._isFormSubmitted = false;
    this._isLoadingData = false;
    this._showMore = false;
  }

  ngOnInit() {
    this._initForm();
    this._getJobDetails();
  }

  private _getJobDetails() {
    this._isLoadingData = true;
    this._jobsService.getJobById(this._jobId).subscribe({
      next: (result) => {
        this._jobData = result.body;
      },
      error: (e) => {},
      complete: () => {
        this._isLoadingData = false;
      },
    });
  }

  private _initForm(): void {
    this._formGroup.addControl(
      'email',
      this._formBuilder.control('', [Validators.required, Validators.email]),
    );
    this._formGroup.addControl(
      'name',
      this._formBuilder.control('', [Validators.required]),
    );
  }

  public scrollToForm(): void {
    const cvFormElement = this.elementRef?.nativeElement as HTMLDivElement;
    cvFormElement
      ?.querySelector('#cv_form')
      ?.scrollIntoView({ behavior: 'smooth' });
  }

  public onFileChange(file: any) {
    this._file = file;
  }

  public onFileDelete(event: any) {
    this._file = null;
  }

  public onJobApply(): void {
    if (this._file) {
      const data = new FormData();
      const applicantDetails: { name: string; email: string } =
        this._formGroup.value;

      data.append('cvFile', this._file);
      data.append('name', applicantDetails.name);
      data.append('email', applicantDetails.email);

      this._isSubmitting = true;

      this._jobsService.applyJob(this._jobId, data).subscribe(
        (data) => {
          // handle success response
          this._isSubmitting = false;
          this._isFormSubmitted = true;
        },
        (err) => {
          // handle error response
          this._isSubmitting = false;
          this._isFormSubmitted = false;
        },
      );
    }
  }

  public navigateHome(): void {
    const url = this._router.serializeUrl(this._router.createUrlTree(['/']));
    window.open(url, '_blank');
  }

  public toogleShowMore(): void {
    this._showMore = !this._showMore;
  }

  // GETTERS
  public get jobData(): IJobData | undefined {
    return this._jobData;
  }

  public get formGroup(): FormGroup {
    return this._formGroup;
  }

  public get currentYear(): number {
    return new Date().getUTCFullYear();
  }

  public get formValid(): boolean {
    return this._formGroup.valid && this._file !== null;
  }

  public get isSubmitting(): boolean {
    return this._isSubmitting;
  }

  public get isFormSubmitted(): boolean {
    return this._isFormSubmitted;
    // Thank you! Your application has been submitted
  }

  public get isLoading(): boolean {
    return this._isLoadingData;
  }

  public get location(): string {
    const company = this._jobData?.companyName;
    if (company) {
      const location = this._jobData?.location?.replace(company, '') as string;
      return location;
    }
    return '';
  }

  public get showMore(): boolean {
    return this._showMore;
  }
}
