import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalUploadModalComponent } from 'src/app/components/global-upload-modal/global-upload-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent {

  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private router: Router,
  ) {}

  openUploadModal(type: 'job' | 'candidate') {
    const isMobile = this.breakpointObserver.isMatched('(max-width: 599px)');
    const config = { job: {
        uploadUrl: `${environment.link}/job/upload`,
        modalTitle: 'Upload Job File',
        modalSubText: 'Upload a job-related file.',
        redirectOnClose: false,
      },
      candidate: {
        uploadUrl: `${environment.link}/cv/upload`,
        modalTitle: 'Upload Candidate File',
        modalSubText: 'Upload a candidate-related file.',
        redirectOnClose: true,
      },
    };
    const dialogRef = this.dialog.open(GlobalUploadModalComponent, {
      height: isMobile ? '90%' : 'auto',
      maxHeight: isMobile ? '90%' : '690px',
      width: isMobile ? '90%' : '750px',
      data: {
        uploadUrl: config[type].uploadUrl,
        modalTitle: config[type].modalTitle,
        modalSubText: config[type].modalSubText,
        allowedExtensions: '.pdf,.doc,.docx',
        maxFileSize: 10485760,
        modalType: type
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      dialogRef.componentRef?.destroy();
      if (config[type].redirectOnClose && result) {
        this.router.navigate(['hiring-portal/jobs/create', result]);
      }
    });
  }
}
