import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';
import * as moment from 'moment';
import { ComponentsModule } from 'src/app/components/components.module';
import { CandidateListComponent } from './candidate-list/candidate-list.component';
import { CoreUIModule } from 'src/app/core-ui/core-ui.module';
import { CandidateListItem } from './candidate-list/models';
import { CandidateMetaDataComponent } from './candidate-meta-data';
import { CandidatesService } from '../candidates.service';
import { Candidate, Skill } from '../models/candidate.model';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { Observable } from 'rxjs';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-candidate',
  standalone: true,
  imports: [
    CommonModule,
    ComponentsModule,
    CandidateListComponent,
    CoreUIModule,
    CandidateMetaDataComponent,
    MatExpansionModule,
    ClipboardModule,
    MatTooltip
  ],
  templateUrl: './candidate.component.html',
  styleUrl: './candidate.component.scss',
})
export class CandidateComponent implements OnInit, OnChanges {
  candidateId: string = '';
  candidateData: Candidate | null = null;
  selectedIndex: number = -1;
  @Input() public candidateFromJob?: string;
  @Output() closeEvent = new EventEmitter<any>();
  @ViewChild('candidateContentMain', { static: false }) candidateContentMain: | ElementRef | undefined;

  constructor(
    private candidatesService: CandidatesService,
    private _router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.candidateId = params.get('candidateId') || '';
      if (this.candidateId) {
        this._loadCandidateOrganizationData(this.candidateId);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['candidateFromJob'] && changes['candidateFromJob'].currentValue) {
      this.candidateId = changes['candidateFromJob'].currentValue;
      this._loadCandidateJobData(this.candidateId);
    }
  }

  public onBack(): void {
    this._router.navigateByUrl('/hiring-portal/candidates');
  }

  public onAddNewCandidate(): void {}

  public onCandidateSelect(candidate: CandidateListItem): void {
    this._loadCandidateOrganizationData(candidate.id);
  }

  getFullArray(level: any): boolean[] {
    return Array.from({ length: 10 }, (_, i) => i < level);
  }

  choose(skill: Skill, index: any): void {
    skill.level = index + 1;
  }

  sidenavClose() {
    this.closeEvent.emit(true);
  }

  private _handleCandidateData(result: any) {
    try {
      this.candidateData = result.body || this.candidateData;
      if (this.candidateData) {
        this.candidateData.jobs = this.candidateData.jobs.map((job) =>
        this._calculateJobExperience(job)
      );
        if (this.candidateContentMain) {
          this.candidateContentMain.nativeElement.scrollTop = 0;
        }
      }
    } catch (error) {
      this.candidateData = null;  
    }
  }
  
  private _loadCandidateData(fetchCandidate: (id: string) => Observable<any>, id: string) {
    fetchCandidate(id).subscribe({
      next: (result) => this._handleCandidateData(result),
      error: (e) => {
        console.error('Error loading candidate data:', e);
      },
      complete: () => {},
    });
  }

  private _loadCandidateOrganizationData(id: string) {
    this._loadCandidateData(this.candidatesService.getCandidateById.bind(this.candidatesService), id);
  }

  private _loadCandidateJobData(id: string) {
    this._loadCandidateData(this.candidatesService.getJobCandidateById.bind(this.candidatesService), id);
  }

  private _calculateJobExperience(job: any): any {
    try {
      const end = job.endDate ? moment(job.endDate) : moment();
      const start = moment(job.startDate);

      let experience = '';
      const years = end.diff(start, 'years');
      const months = end.diff(start, 'months') % 12;

      if (years > 0) {
        experience = `${years} ${years > 1 ? 'Years' : 'Year'}`;
        if (months > 0) {
          experience += ` ${months} ${months > 1 ? 'Months' : 'Month'}`;
        }
      } else if (months > 0) {
        experience = `${months} ${months > 1 ? 'Months' : 'Month'}`;
      } else {
        experience = 'Present';
      }

      return {
        ...job,
        experience,
      };
    } catch (error) {
      console.error('Error calculating job experience:', error);
      return job; 
    }
  }

  public get projects(): { title: string; content: string }[] {
    if (!this.candidateData || !this.candidateData.projects) {
      return [];
    }

    const { projects } = this.candidateData;
    const projectNames = Object.keys(projects);
    const returnValue: Array<{ title: string; content: string }> = [];

    projectNames.forEach((projectName) => {
      returnValue.push({ title: projectName, content: projects[projectName] });
    });

    return returnValue;
  }

  public get languages(): string[] {
    if (!this.candidateData || !this.candidateData.languages) {
      return [];
    }

    const { languages: languagesData } = this.candidateData;
    const languages = Object.keys(languagesData);
    const returnValue: Array<string> = [];
    languages.forEach((language) => {
      returnValue.push(`${language} - ${languagesData[language]}`);
    });

    return returnValue;
  }

  public get references(): { title: string; content: string }[] {
    if (!this.candidateData || !this.candidateData.references) {
      return [];
    }

    const { references } = this.candidateData;
    const referencesNames = Object.keys(references);
    const returnValue: Array<{ title: string; content: string }> = [];

    referencesNames.forEach((projectName) => {
      returnValue.push({ title: projectName, content: references[projectName] });
    });

    return returnValue;
  }

  public get awardsAndHonors(): { title: string; content: string }[] {
    if (!this.candidateData || !this.candidateData.awardsAndHonors) {
      return [];
    }

    const { awardsAndHonors } = this.candidateData;
    const awardsAndHonorsNames = Object.keys(awardsAndHonors);
    const returnValue: Array<{ title: string; content: string }> = [];

    awardsAndHonorsNames.forEach((awardsAndHonorsNames) => {
      returnValue.push({ title: awardsAndHonorsNames, content: awardsAndHonors[awardsAndHonorsNames] });
    });

    return returnValue;
  }

  public get affiliations(): { title: string; content: string }[] {
    if (!this.candidateData || !this.candidateData.affiliations) {
      return [];
    }

    const { affiliations } = this.candidateData;
    const affiliationsNames = Object.keys(affiliations);
    const returnValue: Array<{ title: string; content: string }> = [];

    affiliationsNames.forEach((affiliationsNames) => {
      returnValue.push({ title: affiliationsNames, content: affiliations[affiliationsNames] });
    });

    return returnValue;
  }

  public get hobbies(): { title: string; content: string }[] {
    if (!this.candidateData || !this.candidateData.hobbies) {
      return [];
    }

    const { hobbies } = this.candidateData;
    const hobbiesNames = Object.keys(hobbies);
    const returnValue: Array<{ title: string; content: string }> = [];

    hobbiesNames.forEach((hobbiesNames) => {
      returnValue.push({ title: hobbiesNames, content: hobbies[hobbiesNames] });
    });

    return returnValue;
  }

  public onEmailCopy(tooltip: MatTooltip): void {
    tooltip.show();

    setTimeout(() => {
      tooltip.hide();
    }, 1500);
  }
}
