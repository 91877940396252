<div
  class="w-full h-full flex gap-2 px-4"
  cdkDropList
  cdkDropListOrientation="horizontal"
  (cdkDropListDropped)="dropGrid($event)"
>
  <div
    class="kanban-lane w-1/6"
    cdkDropList
    id="applied"
    [cdkDropListConnectedTo]="[
      'goodMatch',
      'forReview',
      'preScreening',
      'hired',
      'unqualified'
    ]"
    [cdkDropListData]="appliedCadidates"
    (cdkDropListDropped)="drop($event)"
  >
    <div class="kanban-lane-header applied">
      <div class="kanban-lane-header-content">
        Applied({{ appliedCadidates.length }})
      </div>
      <div class="kanban-lane-header-action-bar">
        <img src="assets/rotate-icon.svg" />
        <img src="assets/upload-small.svg" />
        <img src="assets/add-user-icon.svg" />
      </div>
    </div>
    <div class="kanban-lane-content">
      <div class="card" *ngFor="let item of appliedCadidates" cdkDrag>
        <div class="card-name">
          <span class="card-name-text">{{ item.fullName }}</span>
          <span class="card-name-company">{{ item.activeJobTitle }}</span>
          <span class="card-name-recent-company">{{ item.location }}</span>
        </div>
        <div class="card-badge">
          <span class="card-badge-text">Applied</span>
        </div>
      </div>
    </div>
  </div>
  <div
    class="kanban-lane w-1/6"
    cdkDropList
    id="goodMatch"
    [cdkDropListConnectedTo]="[
      'goodMatch',
      'forReview',
      'preScreening',
      'hired',
      'unqualified'
    ]"
    [cdkDropListData]="goodMatchCadidates"
    (cdkDropListDropped)="drop($event)"
  >
    <div class="kanban-lane-header match">
      <div class="kanban-lane-header-content">
        Good match ({{ goodMatchCadidates.length }})
      </div>
      <div class="kanban-lane-header-action-bar">
        <img src="assets/rotate-icon.svg" />
      </div>
    </div>
    <div class="kanban-lane-content">
      <div class="card" *ngFor="let item of goodMatchCadidates" cdkDrag>
        <div class="flex items-start">
          <div class="card-name">
            <span class="card-name-text">{{ item.fullName }}</span>
            <span class="card-name-company">{{ item.activeJobTitle }}</span>
            <span class="card-name-recent-company">{{ item.location }}</span>
          </div>
          <div class="flex items-start gap-1">
            <img
              src="assets/pdf-outline.svg"
              *ngIf="item.originalFileDownloadLink"
              role="button"
              (click)="openPdf(item.originalFileDownloadLink)"
            />
            <img
              src="assets/bagde-linked-in.svg"
              *ngIf="item.linkedInLink"
              role="button"
              (click)="openLinkedInProfile(item.linkedInLink)"
            />
            <img
              src="assets/badge-git.svg"
              *ngIf="item.githubLink"
              role="button"
              (click)="openGithubProfile(item.githubLink)"
            />
          </div>
        </div>
        <div class="card-badge blue">
          <span class="card-badge-text">Good match</span>
        </div>
        <app-skill-level-indicator
          [level]="getJobMatchScore(item.jobMatchScore)"
          [showLevel]="true"
        ></app-skill-level-indicator>
      </div>
    </div>
  </div>
  <div
    class="kanban-lane w-1/6"
    cdkDropList
    id="forReview"
    [cdkDropListConnectedTo]="[
      'goodMatch',
      'forReview',
      'preScreening',
      'hired',
      'unqualified'
    ]"
    [cdkDropListData]="forReviewCadidates"
    (cdkDropListDropped)="drop($event)"
  >
    <div class="kanban-lane-header review">
      <div class="kanban-lane-header-content">For review</div>
      <div class="kanban-lane-header-action-bar">
        <img src="assets/share-icon.svg" />
      </div>
    </div>
    <div class="kanban-lane-content">
      <div class="card" *ngFor="let item of forReviewCadidates" cdkDrag>
        <div class="card-name">
          <span class="card-name-text">{{ item.fullName }}</span>
          <span class="card-name-company">{{ item.activeJobTitle }}</span>
          <span class="card-name-recent-company">{{ item.location }}</span>
        </div>
        <div class="card-badge yellow">
          <span class="card-badge-text">For review</span>
        </div>
      </div>
    </div>
  </div>
  <div
    class="kanban-lane w-1/6"
    cdkDropList
    id="preScreening"
    [cdkDropListConnectedTo]="[
      'goodMatch',
      'forReview',
      'preScreening',
      'hired',
      'unqualified'
    ]"
    [cdkDropListData]="prescreeningCadidates"
    (cdkDropListDropped)="drop($event)"
  >
    <div class="kanban-lane-header screening">
      <div class="kanban-lane-header-content">Pre-screening</div>
      <div class="kanban-lane-header-action-bar">
        <img src="assets/video-cam.svg" />
      </div>
    </div>
    <div class="kanban-lane-content">
      <div class="card" *ngFor="let item of prescreeningCadidates" cdkDrag>
        <div class="card-name">
          <span class="card-name-text">{{ item.fullName }}</span>
          <span class="card-name-company">{{ item.activeJobTitle }}</span>
          <span class="card-name-recent-company">{{ item.location }}</span>
        </div>
        <div class="card-badge orange">
          <span class="card-badge-text">Pre-screening</span>
        </div>
      </div>
    </div>
  </div>
  <div
    class="kanban-lane w-1/6"
    cdkDropList
    id="hired"
    [cdkDropListConnectedTo]="[
      'goodMatch',
      'forReview',
      'preScreening',
      'hired',
      'unqualified'
    ]"
    [cdkDropListData]="hiredCadidates"
    (cdkDropListDropped)="drop($event)"
  >
    <div class="kanban-lane-header hired">
      <div class="kanban-lane-header-content">Hired</div>
      <div class="kanban-lane-header-action-bar">
        <img src="assets/verified.svg" />
      </div>
    </div>
    <div class="kanban-lane-content">
      <div class="card" *ngFor="let item of hiredCadidates" cdkDrag>
        <div class="card-name">
          <span class="card-name-text">{{ item.fullName }}</span>
          <span class="card-name-company">{{ item.activeJobTitle }}</span>
          <span class="card-name-recent-company">{{ item.location }}</span>
        </div>
        <div class="card-badge green">
          <span class="card-badge-text">Hired</span>
        </div>
      </div>
    </div>
  </div>
  <div
    class="kanban-lane w-1/6"
    cdkDropList
    id="unqualified"
    [cdkDropListConnectedTo]="[
      'goodMatch',
      'forReview',
      'preScreening',
      'hired',
      'unqualified'
    ]"
    [cdkDropListData]="unqualifiedCadidates"
    (cdkDropListDropped)="drop($event)"
  >
    <div class="kanban-lane-header unqualified">
      <div class="kanban-lane-header-content">Unqualified</div>
      <div class="kanban-lane-header-action-bar">
        <img src="assets/verified.svg" />
      </div>
    </div>
    <div class="kanban-lane-content">
      <div class="card" *ngFor="let item of unqualifiedCadidates" cdkDrag>
        <div class="card-name">
          <span class="card-name-text">{{ item.fullName }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
