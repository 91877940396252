import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { GlobalUploadModalComponent } from 'src/app/components/global-upload-modal/global-upload-modal.component';
import { environment } from 'src/environments/environment';
import { IMatColumn } from 'src/app/components/table/models/mat-column.interface';
import { ITableConfig } from 'src/app/components/table/models/table-config.model';
import { ISievePayload } from 'src/app/shared/models/sieve-payload.interface';
import { IListCount } from 'src/app/components/table/models/list-count.interface';
import { JobsService } from '../../services/jobs.service';
import { FileService } from 'src/app/shared/helper-services/file.service';

@Component({
  selector: 'app-jobs-list',
  templateUrl: './jobs-list.component.html',
  styleUrls: ['./jobs-list.component.scss'],
})
export class JobsListComponent implements OnInit {
  public isLoading: boolean = true;
  public hasInitialData: boolean = true;

  public columns: IMatColumn[] = [
    {
      field: 'jobTitle',
      header: 'JOB POSITION',
      sortable: true,
      searchKey: 'jobtitle',
    },
    {
      field: 'appliedCount',
      header: 'APPLIED',
      sortable: false,
    },
    {
      field: 'jobLink',
      header: 'JOB LINK',
      sortable: false,
    },
    {
      field: 'externalStatus',
      header: 'JOB STATUS',
      sortable: false,
    },
    {
      field: 'createdByName',
      header: 'CREATED BY',
      sortable: true,
    },
    {
      field: 'createdAt',
      header: 'JOB CREATED',
      sortable: true,
    },
  ];


  public tableConfig: ITableConfig = {
    defaultPayload: {
      page: 1,
      pageSize: 25,
      sorts: '-createdAt',
    } as ISievePayload,
  };

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private jobService: JobsService,
    private fileService: FileService
  ) { }

  ngOnInit(): void { }

  redirectToJobPage(jobId: string): void {
    this.router.navigate(['hiring-portal/jobs/job', jobId]);
  }

  openCreateJobAdModal() {
    const isMobile = this.breakpointObserver.isMatched('(max-width: 599px)');
    const dialogRef = this.dialog.open(GlobalUploadModalComponent, {
      height: isMobile ? '90%' : 'auto',
      maxHeight: isMobile ? '90%' : '690px',
      width: isMobile ? '90%' : '750px',
      data: {
        uploadUrl: `${environment.link}/job/upload`,
        modalTitle: 'Upload Job File',
        modalSubText: 'Upload a job-related file.',
        allowedExtensions: '.pdf,.doc,.docx',
        maxFileSize: 10485760,
        modalType: 'job'
      },
      disableClose: true,
    });
  
    dialogRef.afterClosed().subscribe((result) => {
      dialogRef.componentRef?.destroy();
    });
  }

  public onListCountUpdated(listCount: IListCount): void {
    this.hasInitialData = listCount.initialCount > 0;
    this.isLoading = false;
  }

  public downloadPdf(id: string): void {
    this.jobService.getJobFile(id).subscribe((response: Blob) => {
      this.fileService.downloadPdf(response, 'CV_' + id);
    });
  }
}
