<div class="mb-24 mt-44 md:mb-0">
  <div
    class="relative flex flex-col px-6 mx-auto max-w-7xl lg:px-8 md:min-h-[560px] lg:min-h-[520px]"
  >
    <div class="flex flex-col w-full gap-4 md:w-2/3">
      <div i18n class="w-full text-5xl font-extrabold text-osavus-primary-90">
        Ready to transform your hiring experience with us?
      </div>
      <div i18n class="text-xl font-normal text-gray-500">
        Join those at the forefront of recruitment innovation. Osavus offers you
        the tools, insights and support to recruit smarter, ensuring you find
        the right talent when you need it.
      </div>
      <div class="flex items-center">
        <app-button
          type="custom-red"
          i18n-label
          label="Secure early access"
          (onClick)="router.navigate(['/secure-early-access'])"
        ></app-button>
      </div>
    </div>

    <div class="absolute bottom-0 hidden right-10 md:flex">
      <img src="assets/footer-hero.svg" alt="" />
    </div>
  </div>
</div>
