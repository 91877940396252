<div class="upload-modal">
  <div class="upload-modal-header">
    <div class="flex items-center">
      <span class="upload-modal-header-text">Upload a file</span>
      <img
        alt="close_icon"
        src="assets/close-icon.svg"
        (click)="closeModal()"
        class="w-3 h-3 flex cursor-pointer"
      />
    </div>
    <span class="text-sm font-normal text-gray-500">
      Subtext to help user with instructions, if needed.
    </span>
  </div>
  <div class="upload-modal-content">
    <div class="flex gap-2 items-center">
      <span class="text-base font-semibold">File upload</span>
      <img src="assets/info.svg" alt="" class="w-3 h-3 flex" />
    </div>
    <form [formGroup]="createJobForm" ngNativeValidate>
      <div class="mb-4">
        <div #dropArea class="relative">
          <div class="absolute top-6 text-center flex flex-col w-full">
            <img
              src="assets/upload-icon-outline.svg"
              alt="upload_icon_outline"
              class="w-4 h-4 flex self-center"
            />
            <div class="font-semibold text-sm">
              Drag your PDF or DOC file here
            </div>
            <div
              class="underline text-sm text-blue-400 cursor-pointer"
              role="presentation"
              (click)="openUploadDialog()"
            >
              or browse for files
            </div>
          </div>
          <ejs-uploader
            #templateupload
            id="templatefileupload"
            [asyncSettings]="path"
            [dropArea]="dropArea"
            class="cv-drop-area"
            [allowedExtensions]="'.pdf,.doc,.docx'"
            [maxFileSize]="10485760"
            (uploading)="onUploading($event)"
            [htmlAttributes]="{ name: 'file' }"
            [autoUpload]="false"
            (actionComplete)="onCompleteUpload()"
            (selected)="onFileSelected($event)"
          >
          </ejs-uploader>
        </div>
      </div>
      <app-button
        class="upload-button"
        i18n-label
        label="Upload"
        [isLoading]="isGenerating"
        [disabled]="disableUpload"
        [isIcon]="createOption !== 'generate' ? false : true"
        (onClick)="onSubmit()"
      ></app-button>
    </form>
  </div>
</div>
