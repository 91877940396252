<header class="header">
  <div class="w-full px-4 mx-auto">
    <div class="relative flex justify-between">
      <div class="flex md:inset-y-0 ">
        <div class="flex items-center flex-shrink-0">
          <a routerLink="./jobs">
            <img class="w-auto h-8" src="/assets/favicon/new-logo.png" alt="Osavus" />
          </a>
        </div>
        <div class="flex-1 min-w-0 lg:px-0 sm:col-span-3">
          <div class="flex items-center px-6 py-2 pr-8">
            <div class="w-full ">
              <div class="flex relative">
                <!-- <div class="nav-border">
                  <a routerLink="./overview" [routerLinkActive]="'active-navigation-item'" class="navigation-button group-hover-child">
                    <i class="text-xl text-gray-200 shrink-0 icon-home1 flex items-center justify-center font-light"></i>
                  </a>
                </div> -->
                <div class="nav-border">
                  <a [routerLink]="['./jobs']" [routerLinkActive]="'active-navigation-item'" class="navigation-button group-hover-child">
                    <i class="text-xl text-gray-200 shrink-0 flex items-center justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-7"><path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" /></svg>
                    </i>
                    <span i18n class="text-gray-200 content-center font-semibold">Jobs</span>
                  </a>
                </div>
                <div class="nav-border">
                  <a [routerLink]="['./candidates']" [routerLinkActive]="'active-navigation-item'" class="navigation-button group-hover-child">
                    <i class="text-xl text-gray-200 shrink-0 icon-users flex items-center justify-center"></i>
                    <span i18n class="text-gray-200 content-center font-semibold">Candidates</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-center justify-end col-span-4">
        <!-- <button
          type="button"
          class="relative flex-shrink-0 p-1 ml-5 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span class="absolute -inset-1.5"></span>
          <span class="sr-only">View notifications</span>
          <i
            class="w-6 h-6 text-gray-500 shrink-0 icon-bell flex items-center"
          ></i>
        </button> -->

        <a class="flex cursor-pointer py-2 px-2 text-sm font-normal leading-6 text-gray-700 group gap-x-3">
          <span i18n class="text-gray-200 content-center font-semibold">Help</span>
          <i class="text-xl text-gray-200 shrink-0 flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 0 1 1.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.559.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.894.149c-.424.07-.764.383-.929.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 0 1-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.398.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 0 1-.12-1.45l.527-.737c.25-.35.272-.806.108-1.204-.165-.397-.506-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.108-1.204l-.526-.738a1.125 1.125 0 0 1 .12-1.45l.773-.773a1.125 1.125 0 0 1 1.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894Z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            </svg>
          </i>
        </a>

        <!-- Profile dropdown -->
        <div class="relative flex-shrink-0 ml-5">
          <div>
            <button
              (click)="toggleMenu()"
              type="button"
              class="relative flex bg-blue-dark-200 rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-8 h-8 border border-gray-700  justify-center items-center"
              id="user-menu-button"
              aria-expanded="false"
              aria-haspopup="true"
            >
              <span class="absolute -inset-1.5"></span>
              <span class="sr-only">Open user menu</span>
              <span class="text-white text-sm">{{ userInitials }}</span>
            </button>
          </div>

          <div
            (mouseleave)="toggleMenu()"
            class="absolute right-0 z-10 w-48 mt-2 transition duration-200 ease-in-out origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
            tabindex="-1"
            [hidden]="!IsMenuActive"
          >
            <a
              (click)="signout()"
              class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
              role="menuitem"
              tabindex="-1"
              id="user-menu-item-2"
              >Sign out</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
