import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-outline-button',
  templateUrl: './outline-button.component.html',
  styleUrls: ['./outline-button.component.scss'],
})
export class OutlineButtonComponent implements OnInit {
  @Input()
  public label: string;

  @Input()
  public icon: string;

  @Input()
  public disabled: boolean;

  @Output()
  public onClick: EventEmitter<void>;

  constructor() {
    this.label = '';
    this.icon = '';
    this.disabled = false;

    this.onClick = new EventEmitter<void>();
  }

  ngOnInit() {}
}
