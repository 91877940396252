import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import ICmsResponse from '../shared/models/cms/CmsResponse.model';
import { IFaq } from '../shared/models/cms/Faq.model';

@Injectable({
  providedIn: 'root',
})
export class FaqService {
  endpoint: string = `${environment.cms}/api`;
  constructor(private http: HttpClient) {}
  getFaqList = () =>
    this.http.get<ICmsResponse<IFaq>>(
      `${this.endpoint}/faqs?sort=weight&populate=*`,
      { responseType: 'json', observe: 'response' },
    );
}
