import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  endpoint: string = `${environment.cms}/api`;

  constructor(private http: HttpClient) {}

  populateMainBlog(slug: string) {
    return this.http
      .get<any>(
        `${this.endpoint}/blogs/?filters[Slug][$eq]=${slug}&populate=*`,
        { responseType: 'json', observe: 'response' },
      )
      .pipe(take(1));
  }

  getBlogsList() {
    return this.http
      .get<any>(`${this.endpoint}/blogs?sort=PublishDate:DESC&populate=*`, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  getBlogCategories() {
    return this.http
      .get<any>(`${this.endpoint}/categories`, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }
}

export interface IBlog {
  id: string;
  title: string;
  slug: string;
  cover: string;
  category: string;
  headLine: string;
  post: string;
  publishDate: string;
  authorName: string;
  authorPhoto: string;
}
