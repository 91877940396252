<main-box>
  <form [formGroup]="createJobForm" ngNativeValidate>
    <div class="mb-4">
      <div #dropArea class="relative">
        <div class="absolute top-6 text-center flex flex-col w-full">
          <img
            src="assets/upload-icon-outline.svg"
            alt="upload_icon_outline"
            class="w-4 h-4 flex self-center"
          />
          <div class="font-semibold text-sm">
            Drag your PDF or DOC file here
          </div>
          <div
            class="underline text-sm text-blue-400 cursor-pointer"
            role="presentation"
            (click)="openUploadDialog()"
          >
            or browse for files
          </div>
        </div>
        <ejs-uploader
          #templateupload
          id="templatefileupload"
          [asyncSettings]="path"
          [dropArea]="dropArea"
          class="cv-drop-area"
          [allowedExtensions]="'.pdf,.doc,.docx'"
          [maxFileSize]="10485760"
          (uploading)="onUploading($event)"
          [htmlAttributes]="{ name: 'file' }"
          [autoUpload]="false"
          (actionComplete)="onCompleteUpload()"
          (selected)="onFileSelected($event)"
        >
        </ejs-uploader>
      </div>
    </div>
    <app-button
      class="upload-button"
      i18n-label
      label="Upload"
      [isLoading]="isGenerating"
      [disabled]="disableUpload"
      [isIcon]="createOption !== 'generate' ? false : true"
      (onClick)="onSubmit()"
    ></app-button>
  </form>
</main-box>
