import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BlogService, IBlog } from 'src/app/services/blog.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss'],
})
export class BlogsComponent {
  selectedCategory: string = 'All';
  mainBlog: IBlog | undefined;
  categories: string[] = [];
  blogList: IBlog[] = [];
  loading: boolean | undefined;

  constructor(
    private blogService: BlogService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.loading = true;
    window.scrollTo(0, 0);
    this.getBlogList();
  }

  getCategories() {
    this.blogService.getBlogCategories().subscribe({
      next: (result) => {
        let cat = result.body.data;
        this.categories.push('All');
        for (let i = 0; i < cat.length; i++) {
          this.categories.push(cat[i].attributes.Name);
        }
        this.getBlogList();
      },
      error: (e) => {},
      complete: () => {},
    });
  }

  getBlogList() {
    this.blogService.getBlogsList().subscribe({
      next: (result) => {
        let data = result.body.data;
        for (let i = 0; i < data.length; i++) {
          let blo = data[i].attributes;
          let blog = {
            id: data[i].id,
            title: blo.Title,
            cover: `${environment.cms}${blo.CoverImage.data.attributes.url}`,
            category: blo.categories.data[0]?.attributes.Name ?? '',
            headLine: blo.headline,
            post: blo.Post,
            slug: blo.Slug,
            publishDate: blo.PublishDate,
            authorName: data.AuthorName ?? ' ',
            authorPhoto:
              `${environment.cms}${data.AuthorPhoto?.data.attributes.url}` ??
              '',
          };
          this.blogList.push(blog);
          if (i == 0) {
            this.mainBlog = blog;
          }
        }
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
      },
      complete: () => {},
    });
  }

  onSelect(category: string) {
    this.selectedCategory = category;
  }

  redirectToJobPage(blogSlug: any): void {
    this.router.navigate(['blog', blogSlug]);
  }
}
