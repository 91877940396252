import { MatDialogRef } from '@angular/material/dialog';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { EmitType } from '@syncfusion/ej2-base';
import {
  UploaderComponent,
  RemovingEventArgs,
} from '@syncfusion/ej2-angular-inputs';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-candidates-upload-modal',
  templateUrl: './candidates-upload-modal.component.html',
  styleUrl: './candidates-upload-modal.component.scss',
})
export class CandidatesUploadModalComponent implements AfterViewInit {
  @ViewChild('defaultupload')
  public uploadObj!: UploaderComponent;

  private _uploadButton: HTMLButtonElement | undefined;

  public path: Object = {
    saveUrl: `${environment.link}/cv/upload`,
  };

  public changeHandler: EmitType<Object> = () => {
    this.uploadObj.clearAll();
  };

  public changedHandler: EmitType<Object> = () => {
    this.uploadObj.clearAll();
  };

  public onFileRemove(args: RemovingEventArgs): void {
    args.postRawFile = false;
  }

  constructor(
    private dialogRef: MatDialogRef<CandidatesUploadModalComponent>,
    private elementRef: ElementRef,
    private authService: AuthService,
  ) {}

  ngAfterViewInit(): void {
    this._uploadButton =
      this.elementRef.nativeElement.querySelector('.e-css.e-btn');
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public openUploadDialog(): void {
    this._uploadButton?.click();
  }

  public onUploading(args: any) {
    const token = this.authService.getToken();
    args.currentRequest.setRequestHeader('Authorization', `Bearer ${token}`);
  }
}
