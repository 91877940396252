import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-hero',
  templateUrl: './footer-hero.component.html',
  styleUrls: ['./footer-hero.component.scss'],
})
export class FooterHeroComponent {
  constructor(public router: Router) {}
}
