import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LiteService {
  endpoint: string = `${environment.link}`;

  constructor(private http: HttpClient) {}

  registerToWaitList(data: WaitListForm) {
    return this.http
      .post<any>(`${this.endpoint}/early-access`, data, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }
}

export interface WaitListForm {
  fullName: string;
  country: string;
  countryData: any;
  email: string;
  IsJoinNewsletter: boolean;
}
