<button
  [ngClass]="{
    'primary-button-disabled': disabled || loading
  }"
  class="primary-button primary-button-spacing primary-button-alignment"
  (click)="onClick.emit()"
>
  <i
    *ngIf="iconPosition === 'left'"
    aria-hidden="true"
    [class]="icon"
    [ngClass]="{ 'font-semibold': true, 'text-sm': true, 'leading-3': true }"
  ></i>
  <ng-container *ngIf="iconPosition === 'left'">
    <ng-content></ng-content
  ></ng-container>

  <span
    i18n
    class="font-semibold text-sm leading-3 "
    >{{ label }}</span
  >
  <i
    *ngIf="iconPosition === 'right'"
    aria-hidden="true"
    [class]="icon"
    [ngClass]="{ 'font-semibold': true, 'text-sm': true, 'leading-3': true }"
  ></i>
  <ng-container *ngIf="iconPosition === 'right'">
    <ng-content *ngIf="!loading"></ng-content>
  </ng-container>
</button>
