<div class="py-24 bg-white sm:py-32">
  <div class="px-6 mx-auto max-w-7xl lg:px-8">
    <div class="max-w-4xl mx-auto text-center">
      <p i18n class="mt-2 text-4xl font-bold tracking-tight text-osavus-primary">Transparent pricing, clear value.</p>
    </div>
    <p i18n  class="max-w-2xl mx-auto mt-6 text-xl text-center text-gray-500">Select a plan tailored to your needs, offering straightforward, competitive pricing without any hidden fees or surprises.</p>
    <div class="flex justify-center mt-6">
      <div class="flex items-center gap-4">
        <span class="text-base" id="annual-billing-label">
          <span i18n class="font-medium text-gray-500" [ngClass]="priceFrequent == 'monthly' ?  'font-extrabold text-gray-900' : ''">Monthly</span>
        </span>
        <button (click)="toggleFrequent()"  type="button" class="relative inline-flex flex-shrink-0 w-10 h-5 transition-colors duration-200 ease-in-out bg-gray-200 border-2 border-transparent rounded-full cursor-pointer focus:outline-none focus:ring-2 focus:ring-osavus-blue-80 focus:ring-offset-2" role="switch" aria-checked="false" aria-labelledby="annual-billing-label">
          <span aria-hidden="true" [ngClass]="priceFrequent == 'yearly' ?  'translate-x-5' : 'translate-x-0'"  class="inline-block w-4 h-4 transition duration-200 ease-in-out transform translate-x-0 bg-white rounded-full shadow pointer-events-none ring-0"></span>
        </button>
        <span class="text-base" id="annual-billing-label">
          <span i18n  class="font-medium text-gray-500" [ngClass]="priceFrequent == 'yearly' ?  'font-extrabold text-gray-900' : ''">Yearly</span>
        </span>
      </div>
    </div>
    <div class="grid items-center max-w-md grid-cols-1 gap-8 mx-auto mt-10 isolate lg:mx-0 lg:max-w-none lg:grid-cols-3">
      <ng-container  *ngFor="let item of pricing.plans; index as i;">
        <div class="flex flex-col items-center gap-3 p-8 border rounded-3xl" [ngClass]="i === 1 ? 'border-osavus-primary border-[6px]' : 'border-gray-200'" >
          <div i18n *ngIf="i === 1" class="flex justify-center p-1 text-sm font-medium text-center rounded-lg w-28 text-osavus-primary bg-osavus-light"> Most popular </div>
          <div class="w-full text-2xl font-bold leading-6 text-center text-gray-900">{{ item.title }} </div>
          <div class="flex justify-center w-full">
            <ng-container *ngIf="i === 2">
              <span class="text-5xl font-extrabold text-gray-900">{{item.priceAlt}} </span>
            </ng-container>
            <ng-container *ngIf="i !== 2">
              <div>
                <span class="text-5xl font-extrabold text-gray-900">$ {{  priceFrequent === 'yearly' ? item.yearlyPrice : item.monthlyPrice }}</span>
                /
                <span class="text-xl font-normal leading-5 text-gray-900"> {{ priceFrequent === 'yearly' ?  'yr' : 'mo' }}</span>
              </div>
            </ng-container>
          </div>

          <div class="w-full text-base font-normal leading-6 text-center text-gray-600"> {{ i === 2 ? item.description : '$'+item.yearlyPrice + pricing.annualDescription }} </div>

          <div class="inline-flex items-center justify-center w-full gap-2">
            <div class="text-base font-medium leading-4 text-center text-osavus-blue-80">{{i === 2 ? item.annulaAction : pricing.annulaAction}}</div>
            <img src="assets/arrow-right-outline.svg" alt="">
          </div>

          <a href="#" aria-describedby="tier-freelancer" [ngClass]="i === 1 ? 'text-white bg-osavus-primary' : ''" class="flex justify-center w-full px-3 py-2 mt-6 text-sm font-semibold leading-6 text-gray-900 rounded-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ring-1 ring-inset ring-gray-200 hover:ring-indigo-300">
            {{ i === 2 ? item.button : pricing.button}}
          </a>
          <div class="flex w-full">
            <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
              <li class="flex gap-x-3" *ngFor="let feature of item.features">
                <div class="flex w-full gap-2" [ngClass]="feature.active ? '' : 'opacity-50'">
                  <img [src]="'assets/'+feature.item.icon" alt="">
                  <span [ngClass]="feature.active ? '' : 'line-through'">{{feature.item.name}}</span>
                </div>


              </li>
            </ul>
          </div>

        </div>
      </ng-container>
    </div>
  </div>
</div>
