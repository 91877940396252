<app-label class="mb-1 text-sm" [title]="label"></app-label>
<div class="relative">
  <div class="absolute flex items-center justify-center w-8 h-full pl-4">
    <img *ngIf="isIcon" [src]="isIcon" alt="" />
  </div>
  <input
    [id]="id"
    [name]="name"
    [autocomplete]="autocomplete"
    [formControlName]="formControlName"
    [placeholder]="placeholder"
    type="text"
    [ngClass]="{
      'pl-10': isIcon,
      'error border-red-500 bg-red-100 placeholder:text-red-700': hasError
    }"
    class="block w-full h-11 rounded-md border-0 pr-10 shadow-sm ring-1 text-osavus-content-primary font-semibold ring-inset ring-gray-200 bg-gray-50 placeholder:pl-1 placeholder:text-gray-400 placeholder:font-semibold focus:ring-2 focus:ring-inset text-sm"
  />
</div>

<div *ngIf="hasError" class="validator text-xs font-medium mt-1">
  {{ errorMessage }}
</div>