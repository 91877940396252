<main-box>
  <div class="flex flex-col w-full h-full gap-5 p-5 bg-gray-100">
    <div class="flex flex-col w-full gap-2 pb-5 border-b border-gray-300">
      <div i18n class="text-2xl font-semibold text-osavus-black">Jobs</div>
      <div i18n class="text-sm font-normal text-gray-500">
        Creating a job post is as simple as telling us what position you need
        filled.
      </div>
    </div>

    <ng-container *ngIf="!isLoading && jobList.length; else emptyList">
      <div
        class="flex flex-col items-start flex-auto w-full overflow-hidden bg-white rounded-md drop-shadow-md"
      >
        <div class="flex items-center justify-between w-full p-5">
          <div class="flex">
            <label for="search" class="sr-only">Search jobs...</label>
            <div class="relative">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <i
                  class="w-5 h-5 text-gray-400 icon-search flex items-center"
                ></i>
              </div>
              <input
                name="search"
                class="search-input"
                placeholder="Search jobs..."
                type="search"
              />
            </div>
          </div>

          <div class="flex gap-4">
            <app-primary-button
              (click)="openCreateJobAdModal()"
              label="Create job"
              icon="icon-plus"
            ></app-primary-button>
            <app-dropdown-button
              label="Actions"
              [items]="bulkActions"
              (onItemClick)="onBulkAction($event)"
            ></app-dropdown-button>
            <app-dropdown-button
              label="Filter"
              [items]="[]"
              chevronPosition="right"
              icon="icon-filter text-sm"
            ></app-dropdown-button>
          </div>
        </div>

        <div class="table-responsive">
          <table
            mat-table
            [dataSource]="jobList"
            matSort
            matSortDirection="asc"
            (matSortChange)="onSort($event)"
            class="mat-elevation-z8"
          >
            <ng-container matColumnDef="select-all">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && allSelected"
                  [indeterminate]="selection.hasValue() && !allSelected"
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let job" style="height: inherit">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(job.id) : null"
                  [checked]="selection.isSelected(job.id)"
                >
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="jobTitle">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                [disableClear]="true"
              >
                JOB POSITION
              </th>
              <td mat-cell *matCellDef="let job" style="height: inherit">
                <span
                  *ngIf="job.hasAllData"
                  class="flex gap-2 text-sm items-left font-semibold items-center"
                >
                  <!-- <img
                    src="assets/Avatar.png"
                    class="flex w-8 h-8 rounded"
                    alt="job title"
                  /> -->
                  <span
                    (click)="redirectToJobPage(job.id)"
                    class="cursor-pointer text-osavus-blue-50 hover:text-osavus-primary-70"
                  >
                    {{ job.jobTitle }}
                  </span>
                </span>
                <ngx-skeleton-loader
                  count="1"
                  *ngIf="!job.hasAllData"
                  [theme]="{ margin: 0, height: '8px' }"
                ></ngx-skeleton-loader>
              </td>
            </ng-container>

            <ng-container matColumnDef="applied">
              <th mat-header-cell *matHeaderCellDef>APPLIED</th>
              <td
                mat-cell
                *matCellDef="let job"
                class="text-right font-semibold"
                style="height: inherit"
              >
                <span class="block text-right" *ngIf="job.hasAllData">{{
                  job.applied
                }}</span>
                <ngx-skeleton-loader
                  count="1"
                  *ngIf="!job.hasAllData"
                  [theme]="{ margin: 0, height: '8px' }"
                ></ngx-skeleton-loader>
              </td>
            </ng-container>

            <ng-container matColumnDef="jobLink">
              <th mat-header-cell *matHeaderCellDef>JOB LINK</th>
              <td mat-cell *matCellDef="let job" style="height: inherit">
                <span
                  *ngIf="job.hasAllData"
                  class="flex gap-1 cursor-pointer justify-left font-normal items-center"
                >
                  <img
                    src="assets/navigate.svg"
                    (click)="openLink(job.jobLink)"
                  />
                  <span
                    class="text-osavus-blue-50 text-ellipsis overflow-hidden w-40 whitespace-nowrap"
                    (click)="openLink(job.jobLink)"
                  >
                    {{ job.jobLink }}
                  </span>
                  <img
                    src="assets/copy-outline.svg"
                    [cdkCopyToClipboard]="job.jobLink"
                  />
                </span>
                <ngx-skeleton-loader
                  count="1"
                  *ngIf="!job.hasAllData"
                  [theme]="{ margin: 0, height: '8px' }"
                ></ngx-skeleton-loader>
              </td>
            </ng-container>

            <ng-container matColumnDef="externalStatus">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                [disableClear]="true"
              >
                JOB STATUS
              </th>
              <td mat-cell *matCellDef="let job" style="height: inherit">
                <span
                  *ngIf="job.hasAllData"
                  class="text-osavus-gray-800 text-xs font-semibold me-2 px-2.5 py-0.5 rounded"
                  [ngClass]="{
                    'bg-gray-100': JobStatus.DRAFT === job.externalStatus,
                    'bg-osavus-light':
                      JobStatus.PUBLISHED === job.externalStatus,
                    'bg-pink-100': JobStatus.ARCHIVED === job.externalStatus,
                    'text-pink-800': JobStatus.ARCHIVED === job.externalStatus
                  }"
                >
                  {{ job.externalStatus }}
                </span>
                <ngx-skeleton-loader
                  count="1"
                  *ngIf="!job.hasAllData"
                  [theme]="{ margin: 0, height: '8px' }"
                ></ngx-skeleton-loader>
              </td>
            </ng-container>

            <ng-container matColumnDef="createdBy">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                [disableClear]="true"
              >
                CREATED BY
              </th>
              <td mat-cell *matCellDef="let job" style="height: inherit">
                <span
                  class="flex justify-left gap-2 font-normal items-center"
                  *ngIf="job.hasAllData"
                >
                  <span class="rounded-full p-2 bg-gray-100">{{
                    getInitials(job.createdByName)
                  }}</span>
                  <span>
                    {{ job.createdByName }}
                  </span>
                </span>
                <ngx-skeleton-loader
                  count="1"
                  *ngIf="!job.hasAllData"
                  [theme]="{ margin: 0, height: '8px' }"
                ></ngx-skeleton-loader>
              </td>
            </ng-container>

            <ng-container matColumnDef="createdAt">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                [disableClear]="true"
              >
                JOB CREATED
              </th>
              <td mat-cell *matCellDef="let job" style="height: inherit">
                <span
                  *ngIf="job.hasAllData"
                  class="flex items-center font-semibold text-gray-500"
                  >{{ job.createdAt | date: "MMM d y " }}</span
                >
                <ngx-skeleton-loader
                  count="1"
                  *ngIf="!job.hasAllData"
                  [theme]="{ margin: 0, height: '8px' }"
                ></ngx-skeleton-loader>
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>ACTIONS</th>
              <td
                mat-cell
                *matCellDef="let job"
                class="overflow-cell"
                style="height: inherit"
              >
                <span
                  class="flex w-full justify-center overflow-visible"
                  *ngIf="job.hasAllData"
                >
                  <button
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    aria-label="Example icon-button with a menu"
                  >
                    <mat-icon class="dots">more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button
                      mat-menu-item
                      (click)="handleAction('changeStatus', job)"
                    >
                      <div class="flex">
                        <!-- <i class="w-6 h-6 text-gray-400 shrink-0 icon-home1 flex items-center justify-center"></i> -->
                        <span class="font-semibold text-sm py-1 pl-1"
                          >Change Status</span
                        >
                      </div>
                    </button>
                    <div class="border-t border-gray-300"></div>
                    <button mat-menu-item (click)="handleAction('delete', job)">
                      <div class="flex">
                        <!-- <i class="w-6 h-6 pr-4 text-gray-400 shrink-0 icon-trash flex items-center justify-center"></i> -->
                        <svg
                          class="w-6 h-6 pt-1 text-red-700 flex items-center justify-cente"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                          />
                        </svg>
                        <span
                          class="text-red-700 font-semibold text-sm py-1 pl-1"
                        >
                          Delete
                        </span>
                      </div>
                    </button>
                  </mat-menu>
                </span>
                <ngx-skeleton-loader
                  count="1"
                  *ngIf="!job.hasAllData"
                  [theme]="{ margin: 0, height: '8px' }"
                ></ngx-skeleton-loader>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
        <mat-paginator
          [length]="totalRecords"
          [pageSize]="10"
          [pageSizeOptions]="[5, 10, 25, 100]"
          (page)="onPageChange($event)"
        >
        </mat-paginator>
      </div>
    </ng-container>

    <ng-template #emptyList>
      <div *ngIf="!isLoading" class="flex flex-col items-center w-full gap-3">
        <img src="assets/create-job.svg" alt="empty_job_list" class="h-3/4" />
        <app-primary-button
          (click)="openCreateJobAdModal()"
          label="Create job"
          icon="icon-plus"
        ></app-primary-button>
      </div>
    </ng-template>
  </div>

  <app-loading *ngIf="isLoading"></app-loading>
</main-box>

<app-confirmation-modal
  [message]="confirmationMessage"
  (onConfirm)="onModalConfirm($event)"
></app-confirmation-modal>
