import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor() {}

  public downloadPdf(blob: Blob, fileName: string = ''): void {
    const pdf = new Blob([blob], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(pdf);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName + '.pdf';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  openPdfInNewTab(blob: Blob): void {
    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
    setTimeout(() => window.URL.revokeObjectURL(url), 100);
  }
}
