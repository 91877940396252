<app-label class="text-sm" [title]="label"></app-label>
<div class="mt-1">
  <textarea
    type="text"
    [rows]="rows"
    [id]="id"
    [name]="name"
    [autocomplete]="autocomplete"
    [formControlName]="formControlName"
    [placeholder]="placeholder"
    class="resize-none block w-full rounded-lg border-0 p-4 shadow-sm text-osavus-content-primary font-semibold ring-1 ring-inset ring-gray-200 bg-gray-50 placeholder:text-gray-300 placeholder:font-semibold focus:ring-2 focus:ring-inset font-regular text-sm"
  >
  </textarea>
</div>
