import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IJobData, JobCandidateStatus, JobsService } from '../../services/jobs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, debounceTime, distinctUntilChanged, firstValueFrom } from 'rxjs';
import { languages } from 'src/app/shared/store/languages';
import { JOB_TYPES } from 'src/app/shared/store/job-types';
import { IDropdownItem } from 'src/app/core-ui/models';
import { CandidatesService } from 'src/app/modules/hiring-portal/components/candidates/candidates.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class JobComponent implements OnInit, OnDestroy {
  jobDataForm: FormGroup;
  jobFormSub!: Subscription;
  jobData!: IJobData;
  jobId: string = '';
  languageList: any[];
  isJobData: boolean = false;
  isUpdatingJob: boolean = false;
  isLoadingJob: boolean = true;
  hasJobChanged: boolean = false;
  showPreview: boolean = false;
  isGenerateToasting: boolean = true;
  toast_type: string = '';
  toast_message: string = '';
  jobTypes: IDropdownItem[] = [];
  textAreasData: { id: string; value: string[];}[] = [
    { id: 'jobResponsibilities', value: [] },
    { id: 'benefitsAndCompensation', value: [] },
    { id: 'applicationProcess', value: [] },
  ];
  disableAutoSave: boolean = true;

  @ViewChild('sidenav') sidenav!: MatSidenav;
  @ViewChild('targetForm') elementRef!: ElementRef;

  reason = '';

  close(reason: string) {
    this.reason = reason;
    this.sidenav.close();
  }

  shouldRun = /(^|.)(stackblitz|webcontainer).(io|com)$/.test(window.location.host,);
  candidateId!: string;

  private _formValuesChangeSubscription: Subscription | null;
  private readonly _kanbaneStatusMap = new Map<string, JobCandidateStatus>();

  @ViewChildren('responsibilityInput') responsibilityInputs!: QueryList<ElementRef>;
  @ViewChildren('benefitInput') benefitInputs!: QueryList<ElementRef>;
  @ViewChildren('applicationProcessInput') applicationProcessInputs!: QueryList<ElementRef>;
  showSaved = false;
  showSaving = false;
  initialLoad = true;
  initialLoadStatus = true;
  externalStatus: string = '';

  private autosaveSubscription: Subscription | undefined;
  private autosaveTimer: any;

  onInitialLoadStatusChanged(status: boolean): void {
    this.initialLoadStatus = status;
  }
  onShowSavingChanged(status: boolean): void {
    this.showSaving = status;
  }
  onShowSavedChanged(status: boolean): void {
    this.showSaved = status;
  }

  constructor(
    private formBuilder: FormBuilder,
    private jobsService: JobsService,
    private route: ActivatedRoute,
    private router: Router,
    private candidatesService: CandidatesService,
    public dialog: MatDialog,
  ) {
    this.languageList = languages;
    this.jobTypes = JOB_TYPES;

    this.jobDataForm = this.formBuilder.group({
      jobTitle: ['', [Validators.required, Validators.nullValidator]],
      location: [''],
      jobType: [''],
      jobSummary: ['', [Validators.required, Validators.nullValidator]],
      jobResponsibilities: this.formBuilder.array([]),
      benefitsAndCompensation: this.formBuilder.array([]),
      applicationProcess: this.formBuilder.array([]),
      companyName: [''],
      companyContact: [''],
      companyWebsite: [null],
      companyEmail: [null],
      companyDescription: [''],
      contactInformation: [''],
      salary: ['', ],
      languages: [[]],
      externalStatus: [this.externalStatus],
    });

    this._formValuesChangeSubscription = null;
  }

  ngOnInit(): void {
    this.jobId = this.route.snapshot.params['jobId'];
    this.getJobDetails();
    // this.getCandidateData();
    this.jobDataForm.markAsPristine();
    this.setupAutosave();
  }

  setupAutosave(): void {
    if (this.autosaveSubscription) {
      this.autosaveSubscription.unsubscribe();
    }
    this.autosaveSubscription = this.jobDataForm.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
      this.validateRequiredFields(); 
      if (this.jobDataForm.dirty && this.hasJobChanged && !this.disableAutoSave) { 
        this.initialLoadStatus = false;
        this.showSaving = true;
        this.showSaved = false;
        clearTimeout(this.autosaveTimer);
        this.autosaveTimer = setTimeout(() => {
          if (this.jobDataForm.dirty && this.hasJobChanged) {
            this.updatingJob();
          }
        }, 4000);
      }
    });
  }

  validateRequiredFields(): void {
    if (this.jobDataForm.get('jobTitle')?.invalid || this.jobDataForm.get('jobSummary')?.invalid) {
      this.disableAutoSave = true;
      this.startErorrToast($localize`Job title and Job Description fields are mandatory`);
      this.showSaving = false;
        this.showSaved = false;
    } else {
      this.disableAutoSave = false; 
    }
  }

  onExternalStatusChange(newStatus: string): void {
    this.externalStatus = newStatus;
    this.initialLoadStatus = false;
    this.showSaving = true;
    this.hasJobChanged = true;
    this.initialLoad = true;
    this.updatingJob();
  }

  closeSidenav($event: any) {
    this.sidenav.close();
  }

  onItemSelect(id: string | null) {
    if (id) {
      this.candidateId = id;
      this.sidenav.open();
    }
  }

  getJobDetails() {
    this.jobsService.getJobById(this.jobId).subscribe({
      next: (result) => {
        this.jobData = result.body;
        this.fillJobForm(result.body);
      },
      error: (e) => {},
      complete: () => {},
    });
  }

  getJobTypeLabel(value: string): string {
    const jobType = this.jobTypes.find((type) => type.value === value);
    return jobType ? jobType.label : value;
  }

  get jobResponsibilities(): FormArray {
    return this.jobDataForm.get('jobResponsibilities') as FormArray;
  }

  get benefitsAndCompensation(): FormArray {
    return this.jobDataForm.get('benefitsAndCompensation') as FormArray;
  }

  get applicationProcess(): FormArray {
    return this.jobDataForm.get('applicationProcess') as FormArray;
  }

  addResponsibility(responsibility: string = ''): void {
    this.jobResponsibilities.push(
      this.formBuilder.group({
        responsibility: [responsibility, Validators.required],
      }),
    );
    setTimeout(() => {
      const inputs = this.responsibilityInputs.toArray();
      const lastInput = inputs[inputs.length - 1];
      if (lastInput) {
        lastInput.nativeElement.focus();
      }
    });
  }

  addBenefit(benefit: string = ''): void {
    this.benefitsAndCompensation.push(
      this.formBuilder.group({
        benefit: [benefit, Validators.required],
      }),
    );
    setTimeout(() => {
      const inputs = this.benefitInputs.toArray();
      const lastInput = inputs[inputs.length - 1];
      if (lastInput) {
        lastInput.nativeElement.focus();
      }
    });
  }

  addApplicationStep(step: string = ''): void {
    this.applicationProcess.push(
      this.formBuilder.group({
        step: [step, Validators.required],
      }),
    );
    setTimeout(() => {
      const inputs = this.applicationProcessInputs.toArray();
      const lastInput = inputs[inputs.length - 1];
      if (lastInput) {
        lastInput.nativeElement.focus();
      }
    });
  }
  
  removeResponsibility(index: number): void {
    this.removeItem('jobResponsibilities', index);
  }
  
  removeBenefit(index: number): void {
    this.removeItem('benefitsAndCompensation', index);
  }
  
  removeApplicationStep(index: number): void {
    this.removeItem('applicationProcess', index);
  }
  
  removeItem(arrayName: string, index: number): void {
    const formArray = this.jobDataForm.get(arrayName) as FormArray;
    formArray.removeAt(index);
    this.initialLoadStatus = false;
    this.updatingJob();
  }

  // async getCandidateData() {
  //   const ids = await this.getJobCandidates();
  //   await this.getJobCandidateDetails(ids);
  // }

  // async getJobCandidates() {
  //   const candidatesObservable = this.jobsService.getCandidates(this.jobId);
  //   const response = await firstValueFrom(candidatesObservable);
  //   return response.map((item) => item.orgCandidateId);
  // }

  // async getJobCandidateDetails(ids: string[]) {
  //   const profiles = [];

  //   for (let id of ids) {
  //     const candidateRequest = this.candidatesService.getCandidateById(id);
  //     const candidateProfile = await firstValueFrom(candidateRequest);
  //     profiles.push(candidateProfile);
  //   }
  // }

  fillJobForm(data: any): void {
    const defaultString = (value: any) =>
      typeof value === 'string' ? value : '';
    const jobResponsibilities = Array.isArray(data.jobResponsibilities)
      ? data.jobResponsibilities
      : [];
    const benefitsAndCompensation = Array.isArray(data.benefitsAndCompensation)
      ? data.benefitsAndCompensation
      : [];
    const applicationProcess = Array.isArray(data.applicationProcess)
      ? data.applicationProcess
      : [];

    this.jobDataForm.patchValue({
      jobTitle: data.jobTitle,
      location: data.location,
      jobType: data.jobType,
      jobSummary: this._trimNewLines(data.jobSummary),
      companyName: data.companyName,
      companyContact: data.companyContact,
      companyWebsite: data.companyWebsite || null,
      companyEmail: data.companyEmail || null,
      companyDescription: this._trimNewLines(data.companyDescription),
      contactInformation: data.contactInformation,
      salary: data.salary,
      languages: this.decoratelanguages(data.languages),
      externalStatus: data.externalStatus,
    });

    this.jobDataForm.setControl(
      'jobResponsibilities',
      this.formBuilder.array(
        jobResponsibilities.map((item: any) =>
          this.formBuilder.group({
            responsibility: [defaultString(item).trim(), Validators.required],
          }),
        ),
      ),
    );
    this.jobDataForm.setControl(
      'benefitsAndCompensation',
      this.formBuilder.array(
        benefitsAndCompensation.map((item: any) =>
          this.formBuilder.group({
            benefit: [defaultString(item).trim(), Validators.required],
          }),
        ),
      ),
    );
    this.jobDataForm.setControl(
      'applicationProcess',
      this.formBuilder.array(
        applicationProcess.map((item: any) =>
          this.formBuilder.group({
            step: [defaultString(item).trim(), Validators.required],
          }),
        ),
      ),
    );
    this.externalStatus = data.externalStatus;
    this.manipulateTextAreas();
    setTimeout(() => {
      this.isJobData = true;
      this.jobFormSub = this.jobDataForm.valueChanges.subscribe((changes) => {
        if (changes) {
          this.hasJobChanged = true;
        }
      });
    }, 2000);
  }

  manipulateTextAreas(): void {
    for (let i = 0; i < this.textAreasData.length; i++) {
      const textAreaData = this.textAreasData[i];
      const formValue = this.jobDataForm.value[textAreaData.id];
      if (typeof formValue === 'string') {
        textAreaData.value = formValue.split(/\n/g);
      } else {
        textAreaData.value = [];
      }

      const child = (this.elementRef.nativeElement as any).querySelector(
        `textarea#${textAreaData.id}`,
      );

      if (child) {
        child.value = textAreaData.value.join('\n');
        child.addEventListener('input', () => {
          const value = child.value;
          const arrayValues = value.split(/\n/g);
          textAreaData.value = arrayValues.filter((ele: string) => ele !== '');
        });
      }
    }
  }

  regenerate(event: any) {
    this.isLoadingJob = true;
    let data = {
      jobId: this.jobId,
      prompt: event.prompt,
      generatorTone: this.jobData.generatorTone,
      section: event.section,
    };

    this.jobsService.reGenerateSection(data).subscribe({
      next: (result) => {
        this.getJobDetails();
        this.startSuccessToast($localize`Section has been regenerated successfully`,);
      },
      error: (e) => {
        this.startErorrToast($localize`Something went wrong, try again later`);
      },
      complete: () => {},
    });
  }

  updatingJob(redirect?: boolean): void {
    const jobTitleControl = this.jobDataForm.get('jobTitle');
    const jobSummaryControl = this.jobDataForm.get('jobSummary');
  
    if (!jobTitleControl?.value || !jobSummaryControl?.value) {
      return; 
    }
  
    if (this.hasJobChanged) {
      this.isUpdatingJob = true;
      this.showSaving = true;
  
      let data = { ...this.jobDataForm.value };
      data.datePosted = this.jobData.datePosted;
      data.endDate = this.jobData.endDate;
      data.disclaimer = this.jobData.disclaimer;
      data.id = this.jobId;
  
      data.jobResponsibilities = this.jobResponsibilities.controls.map((control) => control.value.responsibility)
        .filter((responsibility) => responsibility && responsibility.trim() !== '');
  
      data.benefitsAndCompensation = this.benefitsAndCompensation.controls.map((control) => control.value.benefit)
        .filter((benefit) => benefit && benefit.trim() !== '');
  
      data.applicationProcess = this.applicationProcess.controls.map((control) => control.value.step)
        .filter((step) => step && step.trim() !== '');
  
      for (let i = 0; i < this.textAreasData.length; i++) {
        if (
          this.textAreasData[i].id !== 'jobResponsibilities' &&
          this.textAreasData[i].id !== 'benefitsAndCompensation' &&
          this.textAreasData[i].id !== 'applicationProcess'
        ) {
          data[this.textAreasData[i].id] = this.textAreasData[i].value;
        }
      }
  
      let languages: { [key: string]: string } = {};
      this.jobDataForm.value.languages.forEach((language: string) => {
        languages[language] = '';
      });
      data.languages = languages;
      data.externalStatus = this.externalStatus;
  
      this.jobsService.updateJobAd(data).subscribe({
        next: (result) => {
          this.jobData.jobTitle = data.jobTitle;
          this.jobData.location = data.location;
          this.jobData.salary = data.salary;
          this.jobData.jobType = data.jobType;
          this.showSaved = true;
          this.showSaving = false;
          if (redirect) {
            this.router.navigate(['/hiring-portal/jobs/list']);
          }
        },
        error: (e) => {
          this.startErorrToast($localize`Something went wrong, try again later`);
        },
        complete: () => {},
      });
    } else {
      if (redirect) {
        this.router.navigate(['/hiring-portal/jobs/list']);
      }
    }
  }

  onClear(section: string) {
    this.jobDataForm.get(section)?.reset();
  }

  decoratelanguages(data: any) {
    let languages: string[] = [];
    for (var key in data) {
      languages.push(key);
    }
    return languages;
  }

  hideToast(): void {
    this.isGenerateToasting = false;
  }

  startErorrToast(message: string): void {
    this.toast_message = message;
    this.isUpdatingJob = false;
    this.toast_type = 'error';
    this.isGenerateToasting = true;
  }

  startSuccessToast(message: string): void {
    this.toast_message = message;
    this.isUpdatingJob = false;
    this.toast_type = 'success';
    this.isGenerateToasting = true;
  }

  public onBack(): void {
    this.router.navigate(['hiring-portal/jobs/list']);
  }

  public getJobMatchScore(score: number): number {
    return Math.round(score / 10.0);
  }

  private _trimNewLines(input: string): string {
    return input?.replace(/(\r\n|\n|\r)/gm, '') || '';
  }

  private resizedTextareas: Set<HTMLTextAreaElement> = new Set();

  ngAfterViewChecked(): void {
    setTimeout(() => {
      this.resizeElements(this.responsibilityInputs);
      this.resizeElements(this.benefitInputs);
      this.resizeElements(this.applicationProcessInputs);
    }, 0);
  }
  
  private resizeElements(inputs: QueryList<ElementRef<HTMLTextAreaElement>>): void {
    inputs.forEach((inputRef: ElementRef<HTMLTextAreaElement>) => {
      const textarea = inputRef.nativeElement;
      if (!this.resizedTextareas.has(textarea)) {
        this.autoResize(textarea);
        this.resizedTextareas.add(textarea);
      }
    });
  }

  public autoResize(textarea: HTMLTextAreaElement): void {
    textarea.style.height = '44px'; 
    const newHeight = textarea.scrollHeight + 'px';
    if (textarea.style.height !== newHeight) {
      textarea.style.height = newHeight;  
    }
  }
  
  ngOnDestroy(): void {
    if (this.autosaveSubscription) {
      this.autosaveSubscription.unsubscribe();
      this.updatingJob();
    }
    if (this.autosaveSubscription) {
      this.autosaveSubscription.unsubscribe();
    }
    if (this.autosaveTimer) {
      clearTimeout(this.autosaveTimer);
    }
  }
}
