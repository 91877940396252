<button
  [ngClass]="{
    'cursor-not-allowed': disabled,
    'bg-gray-200': disabled,
    'text-gray-500': disabled,
    'button-style-spacing': true,
    'button-style-font': true,
    'button-style-border': true,
    'button-style-effects': true,
    'cursor-pointer': true
  }"
  (click)="onClick.emit()"
>
  <span i18n class="font-semibold text-xs leading-3">{{ label }}</span>
  <i
    *ngIf="icon"
    aria-hidden="true"
    [class]="icon"
    [ngClass]="{ 'font-semibold': true, 'text-xs': true, 'leading-3': true }"
  ></i>
</button>
