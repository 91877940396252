<div class="job-upload-modal">
  <div class="job-upload-modal-header">
    <div class="flex items-center">
      <span class="job-upload-modal-header-text"
        >Upload an existing job ad</span
      >
      <img
        alt="close_icon"
        src="assets/close-icon.svg"
        (click)="closeModal()"
        class="w-3 h-3 flex cursor-pointer"
      />
    </div>
    <span class="text-sm font-normal text-gray-500"
      >Already have an existing job ad? Upload a PDF, DOC or DOCX file to start
      a new job ad.</span
    >
  </div>
  <div class="job-upload-modal-content">
    <div class="flex gap-2 items-center">
      <span class="text-base font-semibold">File upload</span>
      <img src="assets/info.svg" alt="" class="w-3 h-3 flex" />
    </div>
    <app-create-job
      (isGeneratingEvent)="handleIsGeneratingEvent($event)"
    ></app-create-job>
  </div>
</div>
