<div
  class="flex-col hidden h-full p-4 overflow-y-auto bg-white border-r border-gray-200 sm:flex grow gap-y-5"
>
  <nav class="flex flex-col flex-1">
    <ul role="list" class="flex flex-col flex-1 gap-y-7">
      <li>
        <ul role="list" class="-mx-2 space-y-1 w-44">
          <li>
            <a
              routerLink="./overview"
              [routerLinkActive]="'active-navigation-item'"
              class="flex cursor-pointer p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md hover:bg-gray-50 focus:bg-gray-100 group gap-x-3"
            >
              <i
                class="w-6 h-6 text-gray-400 shrink-0 icon-home1 flex items-center justify-center"
              ></i>
              <span i18n>Overview</span>
            </a>
          </li>
          <li>
            <a
              [routerLink]="['./jobs']"
              [routerLinkActive]="'active-navigation-item'"
              class="flex cursor-pointer p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md hover:bg-gray-50 focus:bg-gray-50 group gap-x-3"
            >
              <i
                class="w-6 h-6 text-gray-400 shrink-0 icon-paper flex items-center justify-center"
              ></i>
              <span i18n>Jobs</span>
            </a>
          </li>
          <li>
            <a
              [routerLink]="['./candidates']"
              [routerLinkActive]="'active-navigation-item'"
              class="flex cursor-pointer p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md hover:bg-gray-50 focus:bg-gray-50 group gap-x-3"
            >
            <i class="w-6 h-6 text-gray-400 shrink-0 icon-users flex items-center justify-center"></i>
              <span i18n>Candidates</span>
            </a>
            <!-- <div>
              <button
                (click)="IsCandidatesActive = !IsCandidatesActive"
                type="button"
                class="flex cursor-pointer items-center justify-center w-full p-2 text-sm font-semibold leading-6 text-left text-gray-700 rounded-md hover:bg-gray-50 gap-x-3"
                aria-controls="sub-menu-2"
                aria-expanded="false"
              >
                <i
                  class="w-6 h-6 text-gray-400 shrink-0 icon-users flex items-center justify-center"
                ></i>
                Candidates
                <svg
                  class="w-5 h-5 ml-auto text-gray-400 shrink-0"
                  [ngClass]="
                    IsCandidatesActive ? 'rotate-90 text-gray-500' : ''
                  "
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <ul
                class="cursor-pointer px-2 mt-1"
                id="sub-menu-2"
                [hidden]="!IsCandidatesActive"
              >
                <li>
                  <a
                    routerLink="./candidates"
                    class="block py-2 pr-2 text-sm leading-6 text-gray-700 rounded-md hover:bg-gray-50 pl-9"
                    >All</a
                  >
                </li>
                <li>
                  <a
                    class="block py-2 pr-2 text-sm leading-6 text-gray-700 rounded-md hover:bg-gray-50 pl-9"
                    >Drafts</a
                  >
                </li>
                <li>
                  <a
                    class="block py-2 pr-2 text-sm leading-6 text-gray-700 rounded-md hover:bg-gray-50 pl-9"
                    >Pending</a
                  >
                </li>
              </ul>
            </div> -->
          </li>

          <li>
            <div class="border-b border-gray-300">
              <ul class="px-2 mt-1" [hidden]="!IsReportsActive" id="sub-menu-2">
                <li>
                  <!-- 44px -->
                  <a
                    class="block py-2 pr-2 text-sm leading-6 text-gray-700 rounded-md hover:bg-gray-50 pl-9"
                    >Report 1
                  </a>
                </li>
                <li>
                  <!-- 44px -->
                  <a
                    class="block py-2 pr-2 text-sm leading-6 text-gray-700 rounded-md hover:bg-gray-50 pl-9"
                    >Report 2</a
                  >
                </li>
                <li>
                  <!-- 44px -->
                  <a
                    class="block py-2 pr-2 text-sm leading-6 text-gray-700 rounded-md hover:bg-gray-50 pl-9"
                    >Report 3</a
                  >
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </li>

      <li class="mt-auto">
        <ul role="list" class="-mx-2 space-y-1">
          <li>
            <a
              class="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md hover:bg-gray-50 group gap-x-3"
            >
              <!-- <svg class="w-6 h-6 text-gray-400 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
              </svg> -->
              <i
                class="w-6 h-6 text-gray-400 flex items-center justify-center icon-cog"
              ></i>
              Settings
            </a>
          </li>
          <li>
            <a
              class="flex p-2 text-sm font-semibold leading-6 text-gray-700 rounded-md hover:bg-gray-50 group gap-x-3"
            >
              <i
                class="w-6 h-6 text-gray-400 shrink-0 icon-help-and-feedback flex items-center justify-center"
              ></i>
              <span i18n class="text-sm"> Help & Feedback</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</div>
