<ng-container *ngIf="type === 'active'">
  <button
    (click)="onButtonClick()"
    [disabled]="isLoading || disabled"
    [ngClass]="
      isLoading || disabled
        ? 'cursor-not-allowed bg-gray-200 text-gray-500'
        : 'cursor-pointer bg-blue-500 hover:bg-blue-400 text-white'
    "
    class="w-full flex justify-center items-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
  >
    <span class="px-1">{{ label }}</span>
    <span *ngIf="isIcon">✨</span>
    <div role="status" class="mx-0.5" *ngIf="isLoading">
      <svg
        aria-hidden="true"
        class="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-900"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span class="sr-only" i18n>Loading...</span>
    </div>
  </button>
</ng-container>

<ng-container *ngIf="type === 'passive'">
  <button
    (click)="onButtonClick()"
    [disabled]="isLoading || disabled"
    class="w-full flex justify-center items-center rounded-md bg-gray-50 text-gray-500 hover:bg-gray-200 px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 border-2 border-gray-200"
  >
    <span class="px-1">{{ label }}</span>
    <ng-content></ng-content>
  </button>
</ng-container>

<ng-container *ngIf="type === 'close'">
  <button
    (click)="onButtonClick()"
    [disabled]="isLoading || disabled"
    class="w-full flex justify-center items-center rounded-md bg-gray-600 text-white hover:bg-gray-200 p-2 md:px-2 md:py-1.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
  >
    <span class="hidden px-2 md:flex">{{ label }}</span>
    <img src="assets/close-white.svg" alt="" />
  </button>
</ng-container>

<ng-container *ngIf="type === 'custom-red'">
  <button
    type="button"
    (click)="onButtonClick()"
    [disabled]="isLoading || disabled"
    class="w-full px-2 sm:min-w-[154px] flex justify-center items-center gap-2 bg-osavus-bg-red text-white rounded-md font-medium text-sm py-2"
  >
    <span>{{ label }}</span>
    <span role="status" class="mx-0.5" *ngIf="isLoading">
      <svg
        aria-hidden="true"
        class="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-900"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span class="sr-only" i18n>Loading...</span>
    </span>
  </button>
</ng-container>

<ng-container *ngIf="type === 'custom-white'">
  <button
    type="button"
    (click)="onButtonClick()"
    [disabled]="isLoading || disabled"
    class="w-full bg-white text-osavus-primary-90 py-3 h-[38] flex justify-center items-center rounded-md font-medium text-sm border-2 border-gray-200"
  >
    <img src="assets/back-arrow.svg" alt="" />
    <span class="px-2">{{ label }}</span>
  </button>
</ng-container>

<ng-container *ngIf="type === 'custom-blue'">
  <button
    type="button"
    (click)="onButtonClick()"
    [disabled]="isLoading || disabled"
    [ngClass]="
      isLoading || disabled
        ? 'cursor-not-allowed bg-gray-200 text-gray-500'
        : 'cursor-pointer bg-osavus-blue-80 hover:bg-blue-500 text-white'
    "
    class="w-full bg-osavus-bg-blue text-osavus-blue-50 h-[42px] flex justify-center items-center py-2 px-3 rounded-md font-medium text-sm"
    style="line-height: 14px"
  >
    <span class="px-2">{{ label }}</span>
    <span role="status" class="mx-1" *ngIf="isLoading">
      <svg
        aria-hidden="true"
        class="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-900"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span class="sr-only" i18n>Loading...</span>
    </span>
  </button>
</ng-container>
