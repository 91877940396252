<footer class="bg-gray-50">
  <div class="px-8 py-16 mx-auto max-w-7xl">
    <div class="flex flex-col">
      <div
        class="flex flex-col justify-between w-full gap-16 md:gap-0 md:flex-row"
      >
        <div class="flex flex-col w-full gap-5 md:w-2/3">
          <a href="/#top"
            ><img class="h-8 w-44" src="assets/full-Logo.svg" alt="Osavus"
          /></a>
          <div class="content-gray">
            Providing innovative recruitment solutions with a <br />
            touch of enchantment. Stay connected for the <br />
            latest in recruitment alchemy.
          </div>
          <div class="flex space-x-6">
            <!-- <a href="#" class="text-gray-400 hover:text-gray-500">
              <span class="sr-only">GitHub</span>
              <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fill-rule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clip-rule="evenodd" />
              </svg>
            </a>-->
            <a
              href="https://twitter.com/OsavusTech"
              class="text-gray-400 hover:text-gray-500"
              target="_blank"
            >
              <span class="sr-only">Twitter</span>
              <svg
                class="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 50 50"
                aria-hidden="true"
              >
                <path
                  d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"
                ></path>
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/osavus/"
              class="text-gray-400 hover:text-gray-500"
              target="_blank"
            >
              <span class="sr-only">LinkedIn</span>
              <svg
                class="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.7984 9.02127C15.1519 8.99403 14.509 9.12466 13.9326 9.40035C13.3563 9.67603 12.8664 10.0873 12.5109 10.5938V9.00928H9.34293V19H12.5333V14.1845C12.5333 12.9137 12.8448 11.6868 14.672 11.6868C16.5557 11.6868 16.8 13.1394 16.8 14.2684V19H20V13.66C20 11.0374 19.2533 9.02127 15.7984 9.02127Z"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.6 5C5.28355 5 4.97421 5.08789 4.71109 5.25256C4.44797 5.41723 4.24289 5.65128 4.12179 5.92512C4.00069 6.19895 3.96901 6.50027 4.03074 6.79097C4.09248 7.08167 4.24487 7.3487 4.46863 7.55828C4.69239 7.76787 4.97749 7.9106 5.28786 7.96842C5.59823 8.02625 5.91993 7.99657 6.21229 7.88314C6.50466 7.76972 6.75454 7.57764 6.93035 7.33119C7.10616 7.08475 7.2 6.79501 7.2 6.49861C7.2 6.10115 7.03143 5.71998 6.73137 5.43893C6.43131 5.15789 6.02435 5 5.6 5Z"
                />
                <path d="M7.2 9.02127H4V19H7.2V9.02127Z" />
              </svg>
            </a>
            <a
              href="https://www.youtube.com/@osavus"
              class="text-gray-400 hover:text-gray-500"
              target="_blank"
            >
              <span class="sr-only">YouTube</span>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M21.796 8.84809C21.7015 8.13421 21.4274 7.45559 20.9991 6.87507C20.4683 6.34329 19.7486 6.04053 18.9953 6.03222C16.2006 5.8313 12 5.8313 12 5.8313C12 5.8313 7.80143 5.8313 5.00273 6.03222C4.24968 6.04036 3.53015 6.34316 2.9999 6.87507C2.56975 7.45503 2.29516 8.13438 2.202 8.84909C2.07717 9.91834 2.00974 10.9934 2 12.0698V13.5767C2.00968 14.6531 2.07711 15.7282 2.202 16.7974C2.29694 17.5128 2.56903 18.1935 2.99384 18.7785C3.60357 19.3168 4.38752 19.6191 5.20271 19.6304C6.80154 19.7821 12.001 19.8313 12.001 19.8313C12.001 19.8313 16.2036 19.8313 18.9983 19.6304C19.752 19.6216 20.4721 19.3186 21.0031 18.7865C21.4304 18.2053 21.7038 17.5264 21.798 16.8125C21.9228 15.7433 21.9903 14.6682 22 13.5918V12.0688C21.9886 10.9924 21.9205 9.91738 21.796 8.84809V8.84809ZM9.98707 15.4322V9.78638L15.4411 12.6183L9.98707 15.4322Z"
                />
              </svg>
            </a>
            <!-- <a href="#" class="text-gray-400 hover:text-gray-500">
              <span class="sr-only">Facebook</span>
              <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
              </svg>
            </a> -->
          </div>
        </div>
        <div class="flex justify-between w-full md:w-1/3">
          <div class="flex flex-col min-w-[176px] gap-4">
            <div class="text-sm font-bold text-osavus-primary-90">
              <a routerLink="/#top">Home</a>
            </div>
            <div class="content-gray">
              <a routerLink="/#features">Features</a>
            </div>
            <div class="content-gray">
              <a routerLink="/#faq">FAQ</a>
            </div>
            <div class="content-gray">
              <a routerLink="/blog">Blog</a>
            </div>
          </div>
          <div class="flex flex-col min-w-[176px] gap-4">
            <div class="content-gray">
              <a routerLink="/privacy-policy">Privacy Policy</a>
            </div>
            <div class="content-gray">
              <a routerLink="/cookie-policy">Cookie Policy</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="pt-8 mt-16 text-center border-t border-gray-900/10 sm:mt-20 lg:mt-24"
    >
      <p i18n class="text-xs leading-5 text-gray-500">
        Osavus Technology Ltd &centerdot; Narva mnt 5 Tallinn, Harjumaa 10117
        ESTONIA &centerdot; &copy; 2024 All rights reserved.
      </p>
    </div>
  </div>
</footer>
