<main-box>
  <div class="candidate-page">
    <div class="candidate-page-header" *ngIf="!candidateFromJob">
      <div class="candidate-page-header-back">
        <i class="icon icon-back-arrow" aria-hidden="true" (click)="onBack()"></i>
      </div>
      <div class="candidate-page-header-title">Candidates</div>
      <div class="candidate-page-header-spacer"></div>
      <!-- <div
        class="candidate-page-header-add-new-button"
        role="button"
        (click)="onAddNewCandidate()"
      >
        <i class="icon icon-plus" aria-hidden="true"></i>
        <span>Add candidate</span>
      </div> -->
    </div>
    <button *ngIf="candidateFromJob" mat-button (click)="sidenavClose()"><img alt="close_icon"
        src="assets/close-icon.svg" class="w-3 h-3 flex cursor-pointer" /></button>

    <div class="candidate-page-content">
      <div class="candidate-page-content-left-bar" *ngIf="!candidateFromJob">
        <!-- <div class="candidate-page-content-left-bar-search">
          <div class="candidate-page-content-left-bar-search-container">
            <i class="icon icon-search"></i>
            <input class="search-input" placeholder="Search" />
          </div>
        </div> -->
        <app-candidate-list class="candidate-page-content-left-bar-list" (select)="onCandidateSelect($event)"></app-candidate-list>
      </div>
      <div class="candidate-page-content-main">
        <app-candidate-meta-data [data]="candidateData"></app-candidate-meta-data>
        <div class="h-full overflow-y-auto" #candidateContentMain>
          <div class="candidate-page-content-main-ai">
            <div class="candidate-page-content-main-ai-summary" *ngIf="candidateData?.generatedSummary">
              <div class="flex gap-2 items-center">
                <div class="flex bg-white rounded-full w-4 h-4 items-center justify-center">
                  <img src="assets/magic-sparkles.svg" class="w-2 h-2" />
                </div>
                <div class="title flex">AI Summary</div>
              </div>
              <div>
                {{ candidateData?.generatedSummary }}
              </div>
            </div>
          </div>
          <mat-accordion class="accordion">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>About</mat-panel-title>
              </mat-expansion-panel-header>
              <div class="font-medium text-sm pb-4">{{ candidateData?.summary }}</div>
              <div class="accordion-item">
                <span class="accordion-label w-1/3">Email</span>
                <div class="w-2/3 flex gap-2 overflow-auto items-center">
                  <span class="accordion-value">{{ candidateData?.contact?.email }}</span>
                  <span role="button" class="flex w-5 h-5 bg-gray-100 items-center justify-center rounded-full pl-2">
                    <img 
                      src="assets/copy-outline.svg"
                      alt="copy" 
                      class="w-3 h-3" 
                      title="Copy email"
                      [cdkCopyToClipboard]="candidateData?.contact?.email || ''"
                      (cdkCopyToClipboardCopied)="onEmailCopy(tooltip)"/>
                    <span
                      class="ml-2"
                      #tooltip="matTooltip"
                      matTooltip="Copied!"
                      matTooltipPosition="right"
                      matTooltipClass="mat-tooltip-success"
                    ></span>
                  </span>
                  <a [href]="'mailto:' + candidateData?.contact?.email"
                    class="flex w-5 h-5 bg-blue-100 items-center justify-center rounded-full">
                    <img src="assets/envelope-blue.svg" class="w-3 h-3" />
                  </a>
                </div>
              </div>
              <div class="accordion-item">
                <span class="accordion-label w-1/3">Phone</span>
                <div class="w-2/3 flex gap-2 overflow-auto">
                  <span class="accordion-value">{{candidateData?.contact?.phone}}</span>
                </div>
              </div>
              <div class="accordion-item">
                <span class="accordion-label w-1/3">Linkedin</span>
                <div class="w-2/3 flex gap-2 overflow-auto">
                  <span class="accordion-value">{{ candidateData?.contact?.linkedIn}}</span>
                </div>
              </div>
              <div class="accordion-item">
                <span class="accordion-label w-1/3">Languages</span>
                <div class="w-2/3 flex gap-2 overflow-auto">
                  <span class="accordion-value pill" *ngFor="let item of languages">{{ item }}</span>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="accordion">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>Skills</span>
                  <div class="counter-badge">
                    <span class="counter-badge-value">{{candidateData?.skills?.length}}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="py-3 w-full flex mb-4" *ngIf="candidateData?.softSkills?.length">
                <span
                  class="text-osavus-gray-800 text-xs font-semibold me-3 px-3.5 py-1.5 rounded bg-gray-100 text-black-400"
                  *ngFor="let item of candidateData?.softSkills">
                  {{ item }}
                </span>
              </div>
              <div>
                <div class="accordion-item flex" *ngIf="candidateFromJob">
                  <div class="w-1/3 h-14">
                    <div *ngFor="let item of candidateData?.skills"
                      class="text-osavus-gray-800 text-xs font-semibold me-3 mb-3 px-3.5 py-1.5 rounded bg-gray-100 text-black-400 truncate" matTooltip="{{ item?.skill }}">
                      {{ item?.skill }}
                    </div>
                  </div>
                  <div class="w-2/3">
                    <div *ngFor="let item of candidateData?.skills" class="h-10">
                      <div class="flex">
                        <div 
                          class="skill-box w-1/10 h-8 mx-1"   
                          *ngFor=" let filled of getFullArray(item?.level);let idx = index" 
                          [ngClass]="{
                            progress: idx < (item?.level ?? 0), 
                            max: idx === (item?.level ?? 0) - 1
                          }">
                          <span class="text-white content-center mr-auto ml-auto text-xs" *ngIf="idx === (item?.level ?? 0) - 1">{{ idx + 1 }}</span>
                        </div>
                      </div>
                      <div class="flex">
                        <div 
                          class="job-skill-box w-1/10 h-8 mx-1"   
                          *ngFor="let filled of getFullArray(item?.jobSkillLevel ?? 0); let idx = index" 
                          [ngClass]="{
                            jobprogress: idx < (item?.jobSkillLevel ?? 0), 
                            jobmax: idx === (item?.jobSkillLevel ?? 0) - 1
                          }">
                          <span class="text-white content-center mr-auto ml-auto text-xs" *ngIf="idx === (item?.jobSkillLevel ?? 0) - 1">{{ idx + 1 }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item flex" *ngIf="!candidateFromJob">
                  <div class="w-1/3">
                    <ng-container *ngFor="let item of candidateData?.skills">
                      <div *ngIf="item.skillType === 'Hard'" 
                           class="text-osavus-gray-800 text-xs font-semibold me-3 mb-5 px-3.5 py-1.5 rounded bg-gray-100 text-black-400 truncate" 
                           matTooltip="{{ item?.skill }}">
                        {{ item?.skill }}
                      </div>
                    </ng-container>
                  </div>
                  <div class="w-2/3">
                    <ng-container *ngFor="let item of candidateData?.skills">
                      <div *ngIf="item.skillType === 'Hard'" class="flex">
                        <div *ngFor="let filled of getFullArray(item?.level); let idx = index"
                             class="candidate-box w-1/10 h-8 mx-1"
                             [ngClass]="{
                               'bg-green-500': filled,
                               'bg-gray-200': !filled,
                               progress: idx < (item?.level ?? 0),
                               max: idx === (item?.level ?? 0) - 1
                             }">
                          <span class="text-white content-center mr-auto ml-auto text-xs" *ngIf="idx === (item?.level ?? 0) - 1">{{ idx + 1 }}</span>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>                

              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="accordion">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div>Work History</div>
                  <div class="counter-badge">
                    <span class="counter-badge-value">{{ candidateData?.jobs?.length }}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <!-- <div class="candidate-page-content-main-ai">
                <div class="candidate-page-content-main-ai-summary">
                  <div class="title">AI Summary</div>
                  <div class="text-sm">{{ candidateData?.summary }}</div>
                </div>
              </div> -->
              <!-- for loop here -->
              <div class="accordion-item" *ngFor="let item of candidateData?.jobs">
                <div class="w-1/3 mr-6">
                  <span class="accordion-value">{{ item?.title }}</span>
                  <span class="text-sm font-medium text-gray-400 flex w-full">{{ item?.company }}</span>
                </div>
                <div class="w-2/3">
                  <div class="flex pb-2">
                    <div class="work-history" >{{ item.experience }}</div>
                    <hr class="w-1/5 m-3 border-gray-200" />
                    <div class="text-gray-400 font-semibold w-14 text-sm">
                      {{ item.endDate ? (item.endDate | date: "shortDate") : "Present" }}
                    </div>
                    <hr class="w-3/4 m-3 border-gray-200" />
                    <div class="text-gray-400 font-semibold w-14 text-sm">
                      {{ item.startDate | date: "shortDate" }}
                    </div>
                  </div>
                  <span class="font-medium text-sm">{{ item.description }}</span>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="accordion">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div>Education</div>
                  <div class="counter-badge">
                    <span class="counter-badge-value">{{ candidateData?.educations?.length }}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="accordion-item flex-wrap" *ngFor="let item of candidateData?.educations">
                <div class="accordion-value w-full">
                  {{ item.institution }}
                </div>
                <div class="w-full text-gray-400 text-sm">{{ item.degrees[0] }}</div>
                <div class="accordion-value w-full">{{ item.graduationDate | date: "dd/MM/yyyy" }}</div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="accordion">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div>Certificates and Trainings</div>
                  <div class="counter-badge">
                    <span class="counter-badge-value">{{ candidateData?.certAndTrainings?.length }}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="accordion-data" *ngFor="let item of candidateData?.certAndTrainings">
                <span class="font-semibold text-sm">{{ item.issuer }}</span>
                <br />
                <span class="text-sm">{{ item.description }}</span>
              </div>
              <div class="accordion-item" *ngIf="!candidateData?.certAndTrainings?.length">
                <span class="accordion-label">No data</span>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="accordion">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div>Projects</div>
                  <div class="counter-badge">
                    <span class="counter-badge-value">{{projects.length}}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="accordion-data" *ngFor="let item of projects">
                <span class="font-semibold text-sm">{{ item.title }}</span>
                <br />
                <span class="text-sm">{{ item.content }}</span>
              </div>
              <div class="accordion-item" *ngIf="!projects.length">
                <span class="accordion-label">No data</span>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="accordion">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div>Awards and Honors</div>
                  <div class="counter-badge">
                    <span class="counter-badge-value">{{ awardsAndHonors.length }}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="accordion-data" *ngFor="let item of awardsAndHonors">
                <span class="font-semibold text-sm">{{ item.title }}</span>
                <br />
                <span class="text-sm">{{ item.content }}</span>
              </div>
              <div class="accordion-item" *ngIf="!awardsAndHonors.length">
                <span class="accordion-label">No data</span>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="accordion">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div>Volunteer experience</div>
                  <div class="counter-badge">
                    <span class="counter-badge-value">{{candidateData?.volunteerExperiences?.length}}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="accordion-data" *ngFor="let item of candidateData?.volunteerExperiences">
                <span class="font-semibold text-sm">{{ item.organization }}</span>
                <br />
                <span class="text-sm">{{ item.role }}</span>
              </div>
              <div class="accordion-item" *ngIf="!candidateData?.volunteerExperiences?.length">
                <span class="accordion-label">No data</span>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="accordion">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div>References</div>
                  <div class="counter-badge">
                    <span class="counter-badge-value">{{ references.length }}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="accordion-data" *ngFor="let item of references">
                <span class="font-semibold text-sm">{{ item.title }}</span>
                <br />
                <span class="text-sm">{{ item.content }}</span>
              </div>
              <div class="accordion-item" *ngIf="!references.length">
                <span class="accordion-label">No data</span>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="accordion">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div>Affiliations</div>
                  <div class="counter-badge">
                    <span class="counter-badge-value">{{ affiliations.length }}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="accordion-data" *ngFor="let item of affiliations">
                <span class="font-semibold text-sm">{{ item.title }}</span>
                <br />
                <span class="text-sm">{{ item.content }}</span>
              </div>
              <div class="accordion-item" *ngIf="!affiliations.length">
                <span class="accordion-label">No data</span>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="accordion">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div>Publications</div>
                  <div class="counter-badge">
                    <span class="counter-badge-value">{{candidateData?.publications?.length}}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="accordion-item" *ngFor="let item of candidateData?.publications">
                <span class="text-sm">{{ item }}</span>
              </div>
              <div class="accordion-item" *ngIf="!candidateData?.publications?.length">
                <span class="accordion-label">No data</span>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="accordion">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div>Hobbies</div>
                  <div class="counter-badge">
                    <span class="counter-badge-value">{{ hobbies.length }}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="accordion-data" *ngFor="let item of hobbies">
                <span class="font-semibold text-sm">{{ item.title }}</span>
                <br />
                <span class="text-sm">{{ item.content }}</span>
              </div>
              <div class="accordion-item" *ngIf="!hobbies.length">
                <span class="accordion-label">No data</span>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
      <div class="candidate-page-content-rigth-bar" *ngIf="!candidateFromJob"></div>
    </div>
  </div>
</main-box>