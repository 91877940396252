<div class="pt-2 mx-auto max-w-7xl">
    <div>
      <div class="flex flex-col justify-center w-full gap-4 text-center">
        <div i18n class="text-5xl font-extrabold leading-tight text-osavus-primary-90">
          Osavus Blog
        </div>
        <div class="text-2xl font-normal text-osavus-primary-90">
          Dive into the future of hiring with our latest insights and <br> breakthroughs. Discover how we're transforming <br> recruitment for everyone.
        </div>
      </div>
      <div class="mt-8">
        <app-community [onlyForm]="true"></app-community>
      </div>
    </div>

    <div class="px-8">
      <ng-container *ngIf="loading">
        <div class="my-20">
          <div class="relative flex flex-col w-full bg-white border rounded-lg shadow-lg cursor-pointer border-osavus-light">
            <div class="flex w-full md:h-[368px]">
              <ngx-skeleton-loader class="object-cover w-full h-full rounded-t-lg" [theme]="{'border-radius': '5px', height: '350px'}"></ngx-skeleton-loader>
            </div>
            <div class="flex flex-col w-full gap-4 px-10 py-8">
              <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
              <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '0', height: '50px' }"></ngx-skeleton-loader>
              <ngx-skeleton-loader count="4"></ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!loading && mainBlog">
        <div class="my-20">
          <osavus-blog  [blog]="mainBlog" (navigate)="redirectToJobPage($event)"></osavus-blog>
        </div>
      </ng-container>

      <div class="flex flex-col gap-16">
        <div class="hidden w-full gap-4 md:flex">
          <ng-container *ngFor="let cat of categories">
            <blog-category [selected]="selectedCategory" [category]="cat" (select)="onSelect($event)"></blog-category>
          </ng-container>
        </div>

        <div class="flex flex-wrap justify-between">
          <ng-container  *ngIf="loading">
            <div class="blog-list"  *ngFor="let item of [1, 2, 3, 4]">
              <div class="relative flex flex-col w-full bg-white border rounded-lg shadow-lg cursor-pointer border-osavus-light">
                <div class="flex w-full md:h-[368px]">
                  <ngx-skeleton-loader class="object-cover w-full h-full rounded-t-lg" [theme]="{'border-radius': '5px', height: '350px'}"></ngx-skeleton-loader>
                </div>
                <div class="flex flex-col w-full gap-4 px-10 py-8">
                  <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                  <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '0', height: '50px' }"></ngx-skeleton-loader>
                  <ngx-skeleton-loader count="4"></ngx-skeleton-loader>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!loading">
            <osavus-blog *ngFor="let blog of blogList | slice: 1 | filter:'category':selectedCategory" class="blog-list" [blog]="blog" (navigate)="redirectToJobPage($event)"></osavus-blog>
          </ng-container>
        </div>
      </div>
    </div>

    <app-community></app-community>
    <app-footer-hero></app-footer-hero>
</div>

