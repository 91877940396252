<div (click)="navigate(blog.slug)" class="relative flex flex-col w-full bg-white border rounded-lg shadow-lg cursor-pointer border-osavus-light">
  <div *ngIf="blog.category" class="absolute px-4 py-2 text-sm rounded right-3 top-3 bg-osavus-bg-light-red text-osavus-bg-red">{{blog.category}}</div>
  <div class="flex w-full h-[268px] md:h-[368px]">
    <img [src]="blog.cover" class="object-cover w-full h-full rounded-t-lg" alt="">
  </div>
  <div class="flex flex-col w-full gap-4 px-10 py-8">
    <div class="text-base font-medium text-osavus-bg-red">{{blog.publishDate.toUpperCase()}}</div>
    <div class="block text-3xl font-medium truncate text-osavus-primary-90 whitespace-normal">{{blog.title}}</div>
    <div class="block text-lg font-normal text-gray-600 truncate whitespace-normal">{{blog.headLine}}</div>
    <div class="flex items-center gap-2 text-sm font-medium text-osavus-bg-red">
      <span i18n>Read blog</span>
      <img class="mx-1" src="assets/blog-arrow.svg" alt=""></div>
  </div>
</div>
