<app-label *ngIf="!!label" class="text-sm" [title]="label"></app-label>
<div class="relative w-full">
  <div
    class="absolute top-0 left-0 flex items-center justify-center w-10 h-11"
    *ngIf="selected?.image"
  >
    <img
      *ngIf="selected?.image"
      [src]="selected.image"
      class="w-4 h-4"
      alt=""
    />
  </div>
  <select
    [id]="id"
    [name]="name"
    [formControlName]="formControlName"
    [ngClass]="selected?.image ? 'pl-8' : 'pl-3'"
    class="block w-full h-11 rounded-lg border-0 py-1.5 pr-1 ring-1 ring-inset ring-gray-200 bg-gray-50 placeholder:text-gray-400 placeholder:font-normal sm:leading-6 text-osavus-content-primary font-semibold text-sm"
  >
    <option
      value=""
      disabled
      selected
      class="text-osavus-content-primary font-semibold text-base"
    >
      Select your option
    </option>
    <ng-container *ngFor="let option of options">
      <option
        [ngValue]="option.value"
        class="text-osavus-content-primary bg-gray-50 font-semibold md:text-base sm:text-sm"
      >
        {{ option.label }}
      </option>
    </ng-container>
  </select>
</div>
