<mat-selection-list>
  <mat-list-option togglePosition="before" color="primary" class="py-2">
    <div class="list">
      <div (click)="$event.stopPropagation()" class="cursor-pointer flex gap-2 text-sm items-left font-semibold items-center w-40">
        <span class="rounded-full p-2 bg-gray-100">{{ initials }}</span>
        <span
          (click)="showDetails()"
          class="full-name cursor-pointer text-xs text-osavus-blue-50 hover:text-osavus-primary-70"
          >{{ item?.fullName }}</span
        >
      </div>

      <div class="list-data w-1/12">
        <span class="list-data-item">{{ item?.email }}</span>
        <img
          class="pl-1"
          src="assets/copy-outline.svg"
          [cdkCopyToClipboard]="item?.email ?? ''"
        />
      </div>

      <div class="list-data">
        <span class="list-data-item">{{ item?.activeJobTitle }}</span>
      </div>

      <div class="list-data">
        <span class="list-data-item">{{ item?.location }}</span>
      </div>

      <div class="list-data">
        <span class="list-data-item">Design</span>
      </div>

      <div class="list-data-special">
        <div class="flex gap-2 text-xs items-left font-semibold items-center">
          <span class="rounded-full pt-2 bg-blue-500 text-white w-8 h-8 text-center">{{
            item?.jobMatchScore
          }}</span>
        </div>
      </div>

      <div class="w-56 pl-6">
        <div class="w-full">
          <span class="list-data-item" style="font-size: 10px"
            >Profile completeness</span
          >
          <div class="w-full">
            <div *ngFor="let item of listSkills" class="flex">
              <div *ngFor="let filled of getFullArray(item.level); let idx = index"
                    class="skill-box w-1/10 h-8" [ngClass]="{
                    'bg-green-500': filled,
                    'bg-gray-200': !filled,
                    progress: idx < item.level,
                    max: idx === item.level - 1
                  }" >
                </div>
            </div>
          </div>
        </div>
      </div>

      <div class="list-data justify-center">
        <span
          [ngClass]="{
            'bg-lime-200 text-green-800':
              Status.ACTIVE === item?.activityStatus,
            'bg-gray-200': Status.INACTIVE === item?.activityStatus,
            'bg-amber-200 text-yellow-700':
              Status.NEEDS_UPDATE === item?.activityStatus,
            'bg-red-500 text-red-900': Status.ERROR === item?.activityStatus,
            'bg-gray-100 text-slate-400':
              Status.ARCHIVED === item?.activityStatus
          }"
          class="text-osavus-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-osavus-gray-800"
          >{{ item?.activityStatus }}</span
        >
      </div>

      <div class="list-data text-center">
        <span class="list-data-item">{{ item?.createdAt | date: "MMM d y " }}</span>
      </div>

      <div class="list-data-special">
        <div
          *ngIf="item?.originalFileDownloadLink"
          role="button"
          (click)="openPdf()"
          class="flex items-center justify-center rounded-full w-5 h-5 p-1 bg-black"
        >
          <img src="assets/pdf.svg" />
        </div>
      </div>

      <div>
        <span class="flex w-full justify-center overflow-visible">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu"
          >
            <mat-icon class="dots">more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <div class="flex">
                <i
                  class="w-6 h-6 pr-4 text-gray-400 shrink-0 flex items-center justify-center"
                ></i>
                <span class="font-semibold text-sm py-1 pl-1">Share</span>
              </div>
            </button>
            <button mat-menu-item>
              <div class="flex">
                <i
                  class="w-6 h-6 pr-4 text-gray-400 shrink-0 flex items-center justify-center"
                ></i>
                <span class="font-semibold text-sm py-1 pl-1">Edit</span>
              </div>
            </button>
            <button mat-menu-item>
              <div class="flex">
                <i
                  class="w-6 h-6 text-gray-400 shrink-0 icon-home1 flex items-center justify-center"
                ></i>
                <span class="font-semibold text-sm py-1 pl-1"
                  >View orginal CV</span
                >
              </div>
            </button>
            <button mat-menu-item>
              <div class="flex">
                <!-- <i class="w-6 h-6 pr-4 text-gray-400 shrink-0 icon-home1 flex items-center justify-center"></i> -->
                <svg
                  class="w-6 h-6 pt-1 text-gray-400 flex items-center justify-cente"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m4.5 12.75 6 6 9-13.5"
                  />
                </svg>
                <span class="font-semibold text-sm py-1 pl-1">Hover</span>
              </div>
            </button>
            <div class="border-t border-gray-300"></div>
            <button mat-menu-item>
              <div class="flex">
                <!-- <i class="w-6 h-6 pr-4 text-gray-400 shrink-0 icon-trash flex items-center justify-center"></i> -->
                <svg
                  class="w-6 h-6 pt-1 text-red-700 flex items-center justify-cente"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
                <span class="text-red-700 font-semibold text-sm py-1 pl-1"
                  >Delete</span
                >
              </div>
            </button>
          </mat-menu>
        </span>
      </div>
    </div>
  </mat-list-option>
</mat-selection-list>
