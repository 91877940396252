import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { IDropdownItem } from 'src/app/core-ui/models';
import { JobsService } from '../../services/jobs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { EmitType } from '@syncfusion/ej2-base';

@Component({
  selector: 'app-create-job-modal',
  templateUrl: './create-job-modal.component.html',
  styleUrl: './create-job-modal.component.scss'
})
export class CreateJobModalComponent {
  createJobForm: FormGroup;
  isGenerating: boolean = false;
  isGenerateToasting: boolean = true;
  toast_type: string = '';
  toast_message: string = '';
  createOption: string;
  disableUpload: boolean = true;

  @ViewChild('templateupload')
  public uploadObj!: UploaderComponent;

  private _uploadButton: HTMLButtonElement | undefined;

  constructor(
    private dialogRef: MatDialogRef<CreateJobModalComponent>,
    private formBuilder: FormBuilder,
    private jobsService: JobsService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private elementRef: ElementRef,
  ){    
  this.createJobForm = this.formBuilder.group({
    content: ['', [Validators.required, Validators.nullValidator]],
    generatorTone: [1, [Validators.required, Validators.nullValidator]],
    language: ['English', [Validators.required, Validators.nullValidator]],
    includeCompanyDetails: [false,[Validators.required, Validators.nullValidator],],
    fileSource: ['', [Validators.required]],
  });
  this.createOption = this.route.snapshot.params['option'];
}

ngAfterViewInit(): void {
  this._uploadButton =
    this.elementRef.nativeElement.querySelector('.e-css.e-btn');
}

onSubmit(): void {
  this.isGenerateToasting = true;
  this.disableUpload = true;
  this.uploadObj.upload();
}

generateWithFile(): void {
  const formData = new FormData();
  formData.append('file', this.createJobForm.get('fileSource')?.value);
  formData.append('includeCompanyDetails', this.createJobForm.get('includeCompanyDetails')?.value,);

  this.isGenerating = true;
  this.closeModal();

  this.jobsService.generateWithFile(formData).subscribe({
    next: (result) => {
      this.getJobById(result.body.id);
    },
    error: (e) => {
      this.startErorrToast();
    },
    complete: () => {},
  });
}

getJobById(jobId: string): void {
  this.jobsService.getJobById(jobId).subscribe({
    next: (result) => {
      this.redirectToJobPage(result.body.id);
    },
    error: (e) => {},
    complete: () => {},
  });
}

redirectToJobPage(jobId: string): void {
  this.router.navigate(['hiring-portal/jobs/job', jobId]);
}

startErorrToast(): void {
  this.toast_message = $localize`Something went wrong, try again later`;
  this.isGenerating = false;
  this.toast_type = 'error';
  this.isGenerateToasting = true;
}

startSuccessToast(message: string): void {
  this.toast_message = message;
  this.isGenerating = false;
  this.toast_type = 'success';
  this.isGenerateToasting = true;
}

// ejs

  public path: Object = {
    saveUrl: `${environment.link}/job/upload`,
  };

  public changeHandler: EmitType<Object> = () => {
    this.uploadObj.clearAll();
  };

  public changedHandler: EmitType<Object> = () => {
    this.uploadObj.clearAll();
  };

  public openUploadDialog(): void {
    this._uploadButton?.click();
  }

  public onUploading(args: any) {
    this.disableUpload = true;
    this.isGenerating = true;
    const token = this.authService.getToken();
    args.currentRequest.setRequestHeader('Authorization', `Bearer ${token}`);
  }

  public onCompleteUpload(): void {
    this.disableUpload = true;
    this.isGenerating = false;
  }

  onFileSelected(args: any): void {
    this.disableUpload = args.filesData.length === 0;
  }

  public closeModal(): void {
    this.dialogRef.close();
  }
  
}
