<div class="text-start">
  <form [formGroup]="jobSkillForm" ngNativeValidate class="w-full space-y-6">
    <div class="w-full">
      <app-input
        id="skill"
        name="skill"
        label="Skill title"
        i18n-label
        formControlName="skill"
        placeholder="Write skill here ..."
        i18n-placeholder
        ngDefaultControl
      >
      </app-input>
    </div>

    <div class="w-full">
      <app-textarea
        id="description"
        name="description"
        autocomplete="description"
        label="Skill description:"
        i18n-label
        i18n-placeholder
        placeholder="Describe skill here ..."
        formControlName="description"
        [rows]="6"
        ngDefaultControl
      >
      </app-textarea>
    </div>

    <div class="w-full">
      <app-slider
        [disabled]="false"
        id="slider_tone"
        i18n-label
        label="Skill level"
        [value]="jobSkillForm.value.level"
        [min]="0"
        [max]="10"
        [levels]="skillLevels"
        (changed)="levelChanged($event)"
      >
      </app-slider>
    </div>

    <div class="mt-4">
      <app-button
        i18n-label
        [label]="submitLabel"
        [isLoading]="isSubmitting"
        (onClick)="onSubmit()"
      ></app-button>
    </div>
  </form>
</div>
