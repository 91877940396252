import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import {
  IJobCandidate,
  JobCandidateStatus,
  JobsService,
} from '../../services/jobs.service';
import { firstValueFrom } from 'rxjs';
import { CandidatesService } from 'src/app/modules/hiring-portal/components/candidates/candidates.service';

@Component({
  selector: 'app-track-candidates',
  templateUrl: './track-candidates.component.html',
  styleUrls: ['./track-candidates.component.scss'],
})
export class TrackCandidatesComponent implements OnInit, OnDestroy {
  @Input() jobId: string;

  private readonly _kanbanStatusMap: Map<string, JobCandidateStatus>;
  private readonly _changedCandidatesMap: Map<string, IJobCandidate>;
  private readonly _candidatesMap: Map<
    JobCandidateStatus,
    Array<IJobCandidate>
  >;

  private _updateCandidatesStatusInterval: ReturnType<typeof setInterval>;

  constructor(
    private _jobsService: JobsService,
    private _candidatesService: CandidatesService,
  ) {
    this._changedCandidatesMap = new Map<string, IJobCandidate>();
    this._kanbanStatusMap = new Map<string, JobCandidateStatus>();
    this._candidatesMap = new Map<JobCandidateStatus, Array<IJobCandidate>>();

    this.jobId = '';

    this._updateCandidatesStatusInterval = setInterval(() => {}, 0);

    this._initKanbanStatusMap();
    this._initCandidateMap();
  }

  ngOnDestroy(): void {
    this._updateCandidatesStatusBatch().then();
    this._clearUpdateCandidateStatusInterval();
  }

  ngOnInit() {
    this._getJobCandidates();
  }

  // Private block
  private _initKanbanStatusMap(): void {
    this._kanbanStatusMap.set('goodMatch', JobCandidateStatus.GOOD_MATCH);
    this._kanbanStatusMap.set('forReview', JobCandidateStatus.FOR_REVIEW);
    this._kanbanStatusMap.set('hired', JobCandidateStatus.HIRED);
    this._kanbanStatusMap.set('preScreening', JobCandidateStatus.PRE_SCREENING);
    this._kanbanStatusMap.set('unqualified', JobCandidateStatus.UNQUALIFIED);
  }

  private _initCandidateMap(): void {
    this._candidatesMap.set(JobCandidateStatus.APPLIED, []);
    this._candidatesMap.set(JobCandidateStatus.GOOD_MATCH, []);
    this._candidatesMap.set(JobCandidateStatus.FOR_REVIEW, []);
    this._candidatesMap.set(JobCandidateStatus.HIRED, []);
    this._candidatesMap.set(JobCandidateStatus.PRE_SCREENING, []);
    this._candidatesMap.set(JobCandidateStatus.UNQUALIFIED, []);
  }

  private async _getJobCandidates() {
    // console.log(this.jobId);
    const candidatesObservable = this._jobsService.getCandidates(this.jobId);

    const response = await firstValueFrom(candidatesObservable);

    // console.log(response);

    this._candidatesMap.set(
      JobCandidateStatus.APPLIED,
      response.filter(
        (item) => item.externalStatus === JobCandidateStatus.APPLIED,
      ),
    );
    this._candidatesMap.set(
      JobCandidateStatus.GOOD_MATCH,
      response.filter(
        (item) => item.externalStatus === JobCandidateStatus.GOOD_MATCH,
      ),
    );
    this._candidatesMap.set(
      JobCandidateStatus.FOR_REVIEW,
      response.filter(
        (item) => item.externalStatus === JobCandidateStatus.FOR_REVIEW,
      ),
    );
    this._candidatesMap.set(
      JobCandidateStatus.HIRED,
      response.filter(
        (item) => item.externalStatus === JobCandidateStatus.HIRED,
      ),
    );
    this._candidatesMap.set(
      JobCandidateStatus.PRE_SCREENING,
      response.filter(
        (item) => item.externalStatus === JobCandidateStatus.PRE_SCREENING,
      ),
    );
    this._candidatesMap.set(
      JobCandidateStatus.UNQUALIFIED,
      response.filter(
        (item) => item.externalStatus === JobCandidateStatus.UNQUALIFIED,
      ),
    );
  }

  private async _updateCandidatesStatusBatch() {
    const requests: Promise<any>[] = [];

    [...this._changedCandidatesMap.keys()].forEach((key) => {
      const candidate = this._changedCandidatesMap.get(key);
      if (candidate) {
        requests.push(
          this._candidatesService.updateCandidateStatus({
            externalStatus: candidate.externalStatus,
            id: candidate.id,
            jobId: candidate.jobId,
            orgCandidateId: candidate.orgCandidateId,
          }),
        );
      }
    });

    await Promise.all(requests);
    this._changedCandidatesMap.clear();
  }

  private _setUpdateCandidateStatusInterval() {
    this._clearUpdateCandidateStatusInterval();
    this._updateCandidatesStatusInterval = setInterval(() => {
      this._updateCandidatesStatusBatch().then();
      this._clearUpdateCandidateStatusInterval();
    }, 4000);
  }

  private _clearUpdateCandidateStatusInterval() {
    if (this._updateCandidatesStatusInterval) {
      clearInterval(this._updateCandidatesStatusInterval);
    }
  }

  // getters block
  public get appliedCadidates(): IJobCandidate[] {
    return this._candidatesMap.get(JobCandidateStatus.APPLIED) || [];
  }

  public get forReviewCadidates(): IJobCandidate[] {
    return this._candidatesMap.get(JobCandidateStatus.FOR_REVIEW) || [];
  }

  public get goodMatchCadidates(): IJobCandidate[] {
    return this._candidatesMap.get(JobCandidateStatus.GOOD_MATCH) || [];
  }

  public get hiredCadidates(): IJobCandidate[] {
    return this._candidatesMap.get(JobCandidateStatus.HIRED) || [];
  }

  public get prescreeningCadidates(): IJobCandidate[] {
    return this._candidatesMap.get(JobCandidateStatus.PRE_SCREENING) || [];
  }

  public get unqualifiedCadidates(): IJobCandidate[] {
    return this._candidatesMap.get(JobCandidateStatus.UNQUALIFIED) || [];
  }

  public drop(event: CdkDragDrop<IJobCandidate[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      const newStatus = this._kanbanStatusMap.get(
        event.container.id,
      ) as JobCandidateStatus;
      const items = event.container.data as IJobCandidate[];
      const item = items[0];

      if (this._changedCandidatesMap.has(item.id)) {
        const prevCandidate = this._changedCandidatesMap.get(item.id);
        if (prevCandidate) {
          this._changedCandidatesMap.set(item.id, {
            ...prevCandidate,
            externalStatus: newStatus,
          });
        }
      } else {
        this._changedCandidatesMap.set(item.id, {
          ...item,
          externalStatus: newStatus,
        });
      }
      this._setUpdateCandidateStatusInterval();
    }
  }

  public dropGrid(event: CdkDragDrop<IJobCandidate[]>): void {
    const type = event.item.data.status as JobCandidateStatus;

    switch (type) {
      case JobCandidateStatus.APPLIED: {
        moveItemInArray(
          this.appliedCadidates,
          event.previousIndex,
          event.currentIndex,
        );
        break;
      }
      case JobCandidateStatus.FOR_REVIEW: {
        moveItemInArray(
          this.forReviewCadidates,
          event.previousIndex,
          event.currentIndex,
        );
        break;
      }
      case JobCandidateStatus.GOOD_MATCH: {
        moveItemInArray(
          this.goodMatchCadidates,
          event.previousIndex,
          event.currentIndex,
        );
        break;
      }
      case JobCandidateStatus.HIRED: {
        moveItemInArray(
          this.hiredCadidates,
          event.previousIndex,
          event.currentIndex,
        );
        break;
      }
      case JobCandidateStatus.PRE_SCREENING: {
        moveItemInArray(
          this.prescreeningCadidates,
          event.previousIndex,
          event.currentIndex,
        );
        break;
      }
      case JobCandidateStatus.UNQUALIFIED: {
        moveItemInArray(
          this.unqualifiedCadidates,
          event.previousIndex,
          event.currentIndex,
        );
        break;
      }
      default:
        break;
    }
  }

  public openPdf(link: string) {
    window.open(link, '_blank');
  }

  public openGithubProfile(link: string) {
    window.open(link, '_blank');
  }

  public openLinkedInProfile(link: string) {
    window.open(link, '_blank');
  }

  public getJobMatchScore(score: number): number {
    return Math.round(score / 10.0);
  }
}
