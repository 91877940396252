import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  IGetJobsPayload,
  IJobData,
  JobExternalStatus,
  JobsService,
} from '../../services/jobs.service';
import { ITableColumn } from 'src/app/modules/models/table-column';
import { JOB_COLUMNS } from './models/job-columns';
import { PageEvent } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { CreateJobModalComponent } from '../create-job-modal/create-job-modal.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { Subject, Subscription, debounceTime } from 'rxjs';

@Component({
  selector: 'app-jobs-list',
  templateUrl: './jobs-list.component.html',
  styleUrls: ['./jobs-list.component.scss'],
})
export class JobsListComponent implements OnInit, OnDestroy {
  jobList: IJobData[] = [];
  isLoading: boolean = true;
  selection = new SelectionModel<string>(true, []);
  @ViewChild(ConfirmationModalComponent)
  confirmationModal!: ConfirmationModalComponent;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  private _totalRecords: number;
  private _currentPaginationState: PageEvent;
  private _currentSortState: Sort;
  private selectedJobId: number | null = null;
  confirmationMessage: string | undefined;

  private readonly BULK_ACTIONS: { label: string; value: string }[] = [
    { label: 'Bulk delete', value: 'bulkDelete' },
    { label: 'Bulk change status', value: 'bulkChangeStatus' },
  ];
  private readonly ROW_ACTIONS: { label: string; value: string }[] = [
    { label: 'Delete', value: 'delete' },
    {
      label: 'Change status',
      value: 'changeStatus',
    },
  ];

  private readonly COLUMN_DEFINITION: string[] = [
    'select-all',
    'jobTitle',
    'applied',
    'jobLink',
    'externalStatus',
    'createdBy',
    'createdAt',
    'actions',
  ];

  private _loadDataSubject: Subject<void>;
  private _loadDataSubscription: Subscription | null;

  constructor(
    private jobsService: JobsService,
    private router: Router,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
  ) {
    this._totalRecords = 0;

    this._currentPaginationState = {
      pageIndex: 1,
      pageSize: 10,
      length: 0,
    };
    this._currentSortState = {
      active: '-createdAt',
      direction: 'asc',
    };

    this._loadDataSubject = new Subject<void>();
    this._loadDataSubscription = null;
  }
  ngOnDestroy(): void {
    this._loadDataSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.callJobList();

    this._loadDataSubscription = this._loadDataSubject
      .asObservable()
      .pipe(debounceTime(5000))
      .subscribe(() => {
        this.jobsService
          .getJobs({ page: 1, pageSize: 1, sorts: '-createdAt' })
          .subscribe({
            next: (result) => {
              const items: IJobData[] = result.body;

              // pagination fix
              try {
                const totalCountHeader = result.headers.get('X-Totalcount');
                this._totalRecords = totalCountHeader
                  ? parseInt(totalCountHeader)
                  : this.jobList.length;
              } catch (e) {
                this._totalRecords = this.jobList.length;
              }

              const loadingItemExists = this.jobList.some(
                (item) => item.hasAllData !== true,
              );
              if (items && items[0] && items[0].status !== 'StructuredReady') {
                items[0].hasAllData = false;
                if (
                  loadingItemExists &&
                  items[0].status !== 'StructuredReady'
                ) {
                  this._loadDataSubject.next();
                  return;
                }
                if (this._isPageFull()) {
                  this.jobList = [
                    ...items,
                    ...this.jobList.slice(0, this.jobList.length - 1),
                  ];
                } else {
                  this.jobList = [...items, ...this.jobList];
                }
              }
              if (items && items[0] && items[0].status === 'StructuredReady') {
                const elementToAdd = items[0];
                const found = this.jobList.find(
                  (element) => element.id === elementToAdd.id,
                );
                if (!found && this._currentPaginationState.pageIndex === 1) {
                  if (this._isPageFull()) {
                    this.jobList = [
                      { ...elementToAdd, hasAllData: true },
                      ...this.jobList.slice(0, this.jobList.length - 1),
                    ];
                  } else {
                    this.jobList = [
                      { ...elementToAdd, hasAllData: true },
                      ...this.jobList,
                    ];
                  }
                }
                if (
                  found &&
                  !found.hasAllData &&
                  this._currentPaginationState.pageIndex === 1
                ) {
                  this.jobList = [
                    { ...elementToAdd, hasAllData: true },
                    ...this.jobList.slice(1),
                  ];
                }
              }
              this._loadDataSubject.next();
            },
          });
      });
    this._loadDataSubject.next();
  }

  callJobList() {
    const { pageIndex, pageSize } = this._currentPaginationState;
    const sorts = this.createSorts(this._currentSortState);
    this.getJobList({ page: pageIndex, pageSize, sorts });
  }

  redirectToJobPage(jobId: string): void {
    this.router.navigate(['hiring-portal/jobs/job', jobId]);
  }

  getJobList(payload: IGetJobsPayload): void {
    this.jobsService.getJobs(payload).subscribe({
      next: (result) => {
        const list: IJobData[] = result.body;
        this.jobList = list.map((item) => ({ ...item, hasAllData: true }));
        try {
          const totalCountHeader = result.headers.get('X-Totalcount');
          this._totalRecords = totalCountHeader
            ? parseInt(totalCountHeader)
            : this.jobList.length;
        } catch (e) {
          this._totalRecords = this.jobList.length;
        }
        this.isLoading = false;
      },
      error: (e) => {
        this.isLoading = false;
      },
      complete: () => {},
    });
  }

  openCreateJobAdModal() {
    const isMobile = this.breakpointObserver.isMatched('(max-width: 599px)');
    const dialogHeight = isMobile ? '90%' : 'auto';
    const dialogwidth = isMobile ? '90%' : '700px';
    const dialogRef = this.dialog.open(CreateJobModalComponent, {
      height: dialogHeight,
      width: dialogwidth,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      dialogRef.componentRef?.destroy();
    });
  }

  public get columns(): ITableColumn[] {
    return JOB_COLUMNS;
  }

  public get JobStatus() {
    return JobExternalStatus;
  }

  public get displayedColumns(): string[] {
    return this.COLUMN_DEFINITION;
  }

  public onSort(event: Sort): void {
    this._currentSortState = event;
    const sorts = this.createSorts(event);
    const { pageSize, pageIndex } = this._currentPaginationState;

    this.getJobList({ page: pageIndex, pageSize, sorts });
  }

  public onPageChange(event: PageEvent): void {
    const { pageSize, pageIndex } = event;
    this._currentPaginationState = {
      pageIndex: pageIndex + 1,
      pageSize,
      length: 0,
    };
    const sorts = this.createSorts(this._currentSortState);

    this.getJobList({
      page: this._currentPaginationState.pageIndex,
      pageSize,
      sorts,
    });
  }

  public get bulkActions(): { label: string; value: string }[] {
    return this.BULK_ACTIONS;
  }

  public get rowActions(): { label: string; value: string }[] {
    return this.ROW_ACTIONS;
  }

  public openLink(url: string): void {
    window.open(url, '_blank');
  }

  handleAction(value: string, job: any) {
    if (value === 'delete') {
      this.deleteJob(job.id);
    } else if (value === 'changeStatus') {
      this.changeJobStatus(job.id);
    }
  }

  deleteJob(jobId: number) {
    this.selectedJobId = jobId;
    this.confirmationMessage = 'Are you sure you want to delete this job?';
    this.confirmationModal.openModal();
  }

  onBulkAction(action: { label: string; value: string }) {
    if (action.value === 'bulkDelete') {
      this.confirmationMessage = 'Are you sure you want to delete these jobs?';
      this.confirmationModal.openModal();
    }
  }

  handleDeleteResponse() {
    this.callJobList();
    this.selection.clear();
    this.selectedJobId = null;
  }

  onModalConfirm(result: boolean) {
    const selectedIds = this.selection.selected;
    if (result && this.selectedJobId) {
      this.jobsService.deleteJob(this.selectedJobId).subscribe({
        next: () => this.handleDeleteResponse(),
        error: (e) => console.error('Delete job failed', e),
      });
    } else if (result && selectedIds.length) {
      this.jobsService.batchDeleteJobs(selectedIds).subscribe({
        next: () => this.handleDeleteResponse(),
        error: (e) => console.error('Batch delete jobs failed', e),
      });
    }
  }

  changeJobStatus(jobId: number) {}

  public get allSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.jobList.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.allSelected
      ? this.selection.clear()
      : this.jobList.forEach((row) => this.selection.select(row.id));
  }

  chooseJobOption(option: string) {
    this.router.navigate(['hiring-portal/jobs/create', option]);
  }

  public get totalRecords(): number {
    return this._totalRecords;
  }

  public createSorts(event: Sort): string | undefined {
    if (event.direction) {
      const activeSort = event.active;
      const sorts =
        event.direction === 'asc' ? `${activeSort}` : `-${activeSort}`;
      return sorts;
    }
    return undefined;
  }

  private _isPageFull(): boolean {
    const { pageSize } = this._currentPaginationState;
    return this.jobList.length === pageSize;
  }

  getInitials(fullName: string): string {
    const names = fullName.split(' ');
    const initials = names.map((name) => name.charAt(0)).join('');
    return initials.toUpperCase();
  }
}
