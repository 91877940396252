import { Injectable } from '@angular/core';
import * as marked from 'marked';

@Injectable({
  providedIn: 'root',
})
export class MarkdownParserService {
  constructor() {}

  parse(markdown: string): string | Promise<string> {
    return marked.parse(markdown);
  }
}
